import Vue, { VueConstructor, PluginObject } from 'vue';
import WbFilterPill from './filter-pill.vue';
import WbFilterToggle from './filter-toggle.vue';
import WbFilterDropdown from './filter-dropdown.vue';

const filter: VueConstructor<Vue> &
  PluginObject<void> = WbFilterDropdown as any;
filter.install = function (vue) {
  vue.component('WbFilterPill', WbFilterPill);
  vue.component('WbFilterToggle', WbFilterToggle);
  vue.component('WbFilterDropdown', WbFilterDropdown);
};

export { WbFilterDropdown, WbFilterPill, WbFilterToggle };
export default filter;
