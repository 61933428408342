import { computed, ref } from '@nuxtjs/composition-api';

const needsRefresh = ref(false);
const offlineReady = ref(process.env.NODE_ENV === 'development');

export async function registerServiceWorker(
  options: {
    autoReload?: boolean;
    onNeedsRefresh?: Function;
    onOfflineReady?: Function;
  } = {}
) {
  if (process.env.NODE_ENV === 'development') return Promise.resolve();

  const workbox = await window.$workbox;
  if (!workbox) throw new Error("Workbox couldn't be loaded.");

  workbox.addEventListener('installed', (event) => {
    if (event.isUpdate) {
      needsRefresh.value = true;
      options.onNeedsRefresh?.();
      options.autoReload && forceReload();
    } else {
      offlineReady.value = true;
      options.onOfflineReady?.();
    }
  });
}

export function useServiceWorker() {
  const ignoreUpdate = () => {
    if (!needsRefresh.value)
      throw new Error(
        'Unable to ignore update, the service worker is up to date.'
      );

    needsRefresh.value = false;
    offlineReady.value = false;
  };

  return {
    needsRefresh: computed(() => needsRefresh.value),
    offlineReady: computed(() => offlineReady.value),
    ignoreUpdate,
    forceReload,
  };
}

function forceReload() {
  if (process.env.NODE_ENV === 'production') {
    // const form = document.createElement('form');
    // form.method = 'POST';
    // form.action = location.href;
    // document.body.appendChild(form);
    // form.submit();
    window.location.reload(true);
  } else {
    window.location.reload(true);
  }
}
