import {
  computed,
  getCurrentScope,
  Ref,
  ref,
  unref,
  watch,
  watchEffect,
} from '@nuxtjs/composition-api';
import { tryOnScopeDispose, useClamp } from '@vueuse/core';
import _useSWRV, { mutate } from 'swrv';
import { fetcherFn, IConfig, IKey, IResponse } from 'swrv/dist/types';
import { useLogger } from './use-logger';

interface ISwrvConfig<TError = Error> extends IConfig {
  /**
   * Watch for errors, logging them with the application logger.
   * @note Logging only occurs in the context of a component scope.
   * @default false
   */
  watchErrors?: boolean;
  /**
   * Pushes error messages to the UI via Toast notification.
   * @default true
   */
  pushToastOnError?: boolean;
  /**
   * The strategy used to determine when to display the errors message.
   * - `'all'` display a message on every occurrence of an error.
   * - `'first'` display a message on the first occurrence of an error.
   * - `'last'` display a message on the last occurrence of an error.
   * - `'first-last'` display a message on the first and last occurrence of an error.
   * @default 'first'
   */
  errorDisplayStrategy?: 'all' | 'first' | 'last' | 'first-last';
  /** Reducer function which transforms the text used in the error messages. */
  reduceErrorMessage?: (error: TError) => string;
}

const defaults: ISwrvConfig<any> = {
  shouldRetryOnError: true,
  watchErrors: false,
  errorRetryCount: 5,
  errorDisplayStrategy: 'first',
  pushToastOnError: true,
};

enum RequestState {
  IDLE = 'IDLE',
  VALIDATING = 'VALIDATING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  STALE_IF_ERROR = 'STALE_IF_ERROR',
}

function useRequestState<TData = any, TError = any>(
  data: Ref<TData>,
  error: Ref<TError>,
  isValidating: Ref<boolean>
) {
  const state = ref<RequestState>(RequestState.IDLE);

  const unwatch = watchEffect(() => {
    if (data.value && isValidating.value) {
      state.value = RequestState.VALIDATING;
      return;
    }
    if (data.value && error.value) {
      state.value = RequestState.STALE_IF_ERROR;
      return;
    }
    if (data.value === undefined && !error.value) {
      state.value = RequestState.PENDING;
      return;
    }
    if (data.value && !error.value) {
      state.value = RequestState.SUCCESS;
      return;
    }
    if (data.value === undefined && error) {
      state.value = RequestState.ERROR;
    }
  });

  return {
    state: computed(() => unref(state)),
    unwatch,
  };
}

export { mutate, RequestState };
export function useSWRV<TData = any, TError = Error>(
  key: IKey,
  fn?: fetcherFn<TData> | null,
  userConfig?: ISwrvConfig<TError>
): IResponse<TData, TError> & { state: Ref<RequestState> } {
  const config: ISwrvConfig<TError> = { ...defaults, ...(userConfig || {}) };
  const response = _useSWRV<TData, TError>(key, fn as fetcherFn<TData>, config);

  const { state, unwatch: unWatchState } = useRequestState(
    response.data,
    response.error,
    response.isValidating
  );

  tryOnScopeDispose(unWatchState);

  if (config.watchErrors && !!getCurrentScope()) {
    const retryCount = config.errorRetryCount || 1;
    const retryIteration = useClamp(1, 1, config.errorRetryCount || 1);
    const unWatchRetryIteration = watch(
      () => unref(state) === RequestState.SUCCESS,
      () => (retryIteration.value = 1)
    );

    const logger = useLogger();
    const unWatchErrors = watch(
      () => !!unref(response.error),
      () => {
        const error = unref(response.error);
        const retryLimitReached = unref(retryIteration) >= (retryCount ?? 1);

        const displayToast =
          config.errorDisplayStrategy === 'all' ||
          (config.errorDisplayStrategy === 'first' &&
            unref(retryIteration) === 1) ||
          (config.errorDisplayStrategy === 'last' && retryLimitReached) ||
          (config.errorDisplayStrategy === 'first-last' &&
            (unref(retryIteration) === 1 || retryLimitReached));

        retryIteration.value++;

        if (!displayToast) return;

        const useToast = config.pushToastOnError ?? true;
        if (error instanceof Error) {
          error.message = config.reduceErrorMessage?.(error) || error.message;
          logger.error(error, undefined, { ui: useToast });
        } else if (error) {
          const err = new Error(
            config.reduceErrorMessage?.(error) || (error as any)
          );
          logger.error(err, undefined, { ui: useToast });
        }
      }
    );

    tryOnScopeDispose(() => {
      unWatchErrors();
      unWatchRetryIteration();
    });
  }

  return {
    ...response,
    state,
  };
}
