import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { registerAPI } from '~/app/core/api';
import { createApiClient } from '~/app/core/api/client';
import { primaryApiFactory } from '~/app/core/api/factory';

export default defineNuxtPlugin(({ $auth, $http, $config }, inject) => {
  if (!$config.api.primary.headers?.version?.name)
    throw new Error(
      'Nuxt config "api.primary.headers.version.name" does not exist.'
    );

  if (!$config.api.primary.headers?.version?.value)
    throw new Error(
      'Nuxt config "api.primary.headers.version.value" does not exist.'
    );

  const getToken = () => {
    if (!$auth.isAuthenticated.value) return Promise.resolve(undefined);
    return $auth.getTokenAsync();
  };

  const { version, buildNumber } = $config.app;
  const { headers } = $config.api.primary;
  const client = createApiClient($http, { getToken });
  client.setHeaders({
    [headers!.version!.name]: headers!.version!.value,
    'Content-Type': 'application/json',
    'x-app-client-version': version,
    'x-app-client-build': !buildNumber,
  });

  const api = primaryApiFactory(client, $config);
  registerAPI(api);

  inject('api', api);
});
