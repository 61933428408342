import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';
import { ApiClient } from '../client';
import { IApiFactory } from '../IApiFactory';
import { ITenantDiscoveryApiFactory } from '../ITenantDiscoveryApiFactory';
import { createAlertsApi } from './partials/alerts';
import { createBodyboxesApi } from './partials/bodyboxes';
import { createCasesApi } from './partials/cases';
import { createDictionariesApi } from './partials/dictionaries';
import { createOutboxApi } from './partials/outbox';
import { createReservationsApi } from './partials/reservations';
import { createUserApi } from './partials/user';
import { createTenantApi } from './partials/tenant';
import { createTenantDiscoveryApi } from './partials/tenant-discovery';
import { createReportingApi } from './partials/reporting';

export const primaryApiFactory = (
  api: ApiClient,
  config: NuxtRuntimeConfig
): IApiFactory => {
  return {
    client: api,
    alerts: createAlertsApi(api, config),
    bodyboxes: createBodyboxesApi(api, config),
    cases: createCasesApi(api, config),
    dictionaries: createDictionariesApi(api, config),
    outbox: createOutboxApi(api, config),
    reservations: createReservationsApi(api, config),
    reporting: createReportingApi(api, config),
    tenant: createTenantApi(api, config),
    user: createUserApi(api, config),
  };
};

export const tenantDiscoveryApiFactory = (
  api: ApiClient,
  config: NuxtRuntimeConfig
): ITenantDiscoveryApiFactory => {
  return {
    discover: createTenantDiscoveryApi(api, config),
  };
};
