import { notification, Progress } from 'ant-design-vue';
import { TickTimer } from '~/app/utils/tick-timer';

const NOTIFICATION_KEY = 'inactivity-notification';

export function useInactivityCountdown() {
  const render = (tick: number, durationMs: number, tickMeasureMs: number) => {
    const tickTimer = new TickTimer(durationMs, tickMeasureMs);
    const remainingSeconds = tickTimer.getTimeRemaining(tick).toFixed(0);
    const percent = (tick / tickTimer.ticks) * 100;

    notification.warning({
      key: NOTIFICATION_KEY,
      message: 'It looks like you are away',
      description: `You will be logged out in ${remainingSeconds} seconds.`,
      duration: durationMs,
      icon: <Progress
        // @ts-ignore
        type="circle"
        percent={percent}
        width={25}
        showInfo={false}
        strokeWidth={10}
        stroke-color={{
          '10%': '#6342ff',
          '80%': '#ff4d4f',
        }}
      />,
    });
  };

  const close = () => notification.close(NOTIFICATION_KEY);

  return { render, close };
}
