import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';

export class CreateCaseTypeRequest extends AddDictionaryItemRequest {
  public readonly critical: boolean;

  constructor(args: CreateCaseTypeRequest) {
    super(args);
    this.critical = args.critical;
  }
}

export class EditCaseTypeRequest extends EditDictionaryItemRequest {
  public readonly critical: boolean;

  constructor(args: EditCaseTypeRequest) {
    super(args);
    this.critical = args.critical;
  }
}
