




































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { genId } from '~/app/utils/id';

export default defineComponent({
  name: 'ChecklistItem',
  props: {
    value: {
      type: [String, Boolean, Number, Object] as PropType<any>,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    description: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputId: {
      type: String,
      required: false,
      default: () => `cl_item_input_${genId()}`,
    },
    descriptionId: {
      type: String,
      required: false,
      default: () => `cl_item_description_${genId()}`,
    },
  },
});
