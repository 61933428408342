











import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '@vueuse/core';

export default defineComponent({
  name: 'ViewportObserver',
  props: {
    intersect: {
      type: String as PropType<'top' | 'bottom'>,
      required: false,
      default: 'top',
    },
    /** An offset in pixels. */
    offset: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  setup(_, { emit }) {
    const stickySentinel = ref<HTMLElement>();
    const isWithinViewport = ref(false);

    useIntersectionObserver(
      stickySentinel,
      ([{ isIntersecting: withinViewport }]) => {
        isWithinViewport.value = withinViewport;

        emit('change', withinViewport);
        emit(withinViewport ? 'in' : 'out');
      },
      { threshold: [1] }
    );

    return { stickySentinel, isWithinViewport };
  },
});
