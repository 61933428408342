

























// @ts-ignore
import enUSLocale from 'ant-design-vue/lib/locale-provider/en_US';
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { ConsoleLogger } from '~/app/core/logging';
import { useServiceWorker } from '~/app/composables/use-service-worker';
import TheAppUpdateAlert from '~/app/modules/layout/components/molecules/the-update-alert.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    TheAppUpdateAlert,
  },
  setup() {
    const { needsRefresh } = useServiceWorker();
    const isUpdateAlertVisible = ref(needsRefresh.value);
    watch(needsRefresh, (refresh) => (isUpdateAlertVisible.value = refresh));

    const locale = enUSLocale;

    return {
      locale,
      isUpdateAlertVisible,
    };
  },
  errorCaptured: (err, vm, info) => {
    try {
      vm.$log.error(
        err,
        {
          componentName: vm.$options.name!,
          info,
        },
        { ui: true }
      );
    } finally {
      const hasConsoleLogger = vm.$log.getLogger(ConsoleLogger);
      !hasConsoleLogger && console.error(err);
    }

    return false;
  },
});
