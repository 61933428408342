import { AppModule } from '~/app/core/module-system/module';

export class OutboxModule extends AppModule {
  public readonly name = OutboxModule.name;

  public activate() {
    super.activate();
    // call activation steps here
  }
}

export const ModuleName = OutboxModule.name;
