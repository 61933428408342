import { Context } from '@nuxt/types';
import { Module } from 'vuex';
import { RootState } from '~/store';

type VuexModule<TState = {}> = Module<TState, RootState>;

export type AppModuleOptions<TState> = {
  /**
   * `register` - actives the module when registered with the bootstrapper.
   * `route-enter` - actives the module when the user navigates to this or a child route.
   * @default 'register'
   */
  activationMethod?: 'register' | 'route-enter';
  /**
   * Establishes a dependency between this module an a page route.
   * If @see activationMethod is set to `route-enter` the module will activate when the user navigates to this or a child route. Deactivation will occur when the user navigates to a page that does not fall within this route.
   */
  route?: string;
  store?: {
    /**
     * If a route is configured, destroy the state and unregister the vuex module when the "AppModule" is no longer active.
     * @default false
     */
    tearDownOnPageLeave?: boolean;
    /** Vuex module. */
    module: VuexModule<TState>;
    /** Module name. @default constructor name */
    moduleName?: string;
  };
};

export interface IAppModule {
  readonly name: string;
  readonly isActive: boolean;

  /**
   * Activates the module if it has not been activated already.
   * @param force Forces activation even if the module has already been activated.
   */
  activate(force?: boolean): void;

  /** Deactivates the module. */
  deactivate(): void;
}

/**
 * An app module represents an isolated business unit within the application.
 */
export abstract class AppModule<TState = undefined> implements IAppModule {
  private _isActive = false;

  protected readonly context: Context;

  public abstract name: string;
  public static enableDebugLogging = false;
  public readonly options?: Readonly<AppModuleOptions<TState>>;

  constructor(context: Context, options?: AppModuleOptions<TState>) {
    if (options?.store && !context.store)
      throw new Error(
        '[AppModule] vuex store instance is required to activate module.'
      );

    this.context = context;
    this.options = Object.freeze(options);

    if (options?.activationMethod === 'register') {
      this.activate();
    }
  }

  public get isActive() {
    return this._isActive;
  }

  public activate(force: boolean = false) {
    if (this.isActive && !force) return;

    if (this.context.store) {
      const doesStoreModuleExist = this.context.store.hasModule(this.name);

      if (!doesStoreModuleExist && this.options?.store?.module) {
        this.registerStore(
          this.options.store.module,
          this.options.store.moduleName
        );
      }
    }

    this._isActive = true;

    if (AppModule.enableDebugLogging)
      console.debug(`[AppModule] Activated module "${this.name}".`);
  }

  public deactivate() {
    if (this.context.store && this.options?.store?.tearDownOnPageLeave) {
      this.context.store.unregisterModule(this.name);
    }

    this._isActive = false;

    if (AppModule.enableDebugLogging)
      console.debug(`[AppModule] Deactivated module "${this.name}".`);
  }

  private registerStore(module: VuexModule<TState>, moduleName?: string) {
    this.context.store?.registerModule(moduleName || this.name, module);
  }
}
