








import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FormItemError',
  props: {
    errors: {
      type: Array as PropType<Array<string | Error>>,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const { errors } = toRefs(props);

    const hasErrors = computed(() => errors.value.length > 0);

    const error = computed(() => {
      if (!hasErrors.value) return null;

      const err = errors.value[0];

      if (err instanceof Error) {
        return err.message;
      }

      if (typeof err === 'string') {
        return err;
      }

      return null;
    });

    return { hasErrors, error };
  },
});
