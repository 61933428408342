import { module as StoreModule, moduleName } from './store';
import { AppModule } from '~/app/core/module-system/module';
import { registerModule } from '~/app/core/module-system/use-module';

export class SettingsModule extends AppModule {
  public readonly name = SettingsModule.name;

  public activate() {
    super.activate();
    // call activation steps here
  }
}

export const ModuleName = SettingsModule.name;
export { moduleName as StoreModuleName };

export const registerSettingsModule = () =>
  registerModule(SettingsModule, {
    route: 'settings',
    store: {
      moduleName,
      module: StoreModule,
    },
  });
