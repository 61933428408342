import Vue, { VueConstructor, PluginObject } from 'vue';
import WbDateTimePicker from './date-time-picker.vue';
import WbDateTimePickerCalendar from './date-time-picker-calendar.vue';

const picker: VueConstructor<Vue> &
  PluginObject<void> = WbDateTimePicker as any;
picker.install = function (vue) {
  vue.component('WbDateTimePicker', WbDateTimePicker);
  vue.component('WbDateTimePickerCalendar', WbDateTimePickerCalendar);
};

export { WbDateTimePicker, WbDateTimePickerCalendar };
export default picker;
