

























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'FilterToggle',
  inheritAttrs: false,
  model: {
    prop: 'selected',
    event: 'update:active',
  },
  props: {
    selected: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object, Array, Date] as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const model = useVModel(props, 'selected');
    const active = computed(() => model.value.includes(props.value));

    function toggle() {
      if (active.value) {
        model.value.splice(model.value.indexOf(props.value), 1);
      } else {
        model.value.push(props.value);
      }
    }

    return { active, model, toggle };
  },
});
