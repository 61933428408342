import { Type } from 'class-transformer';
import { IIdentifiable } from '../interfaces';
import { BodyboxWeightConfiguration } from './bodybox-weight-configuration';
import { BodyboxStorageConfiguration } from './bodybox-storage-configuration';

export class Bodybox implements IIdentifiable<string> {
  public readonly id!: string;
  public readonly name!: string;
  public readonly capacity!: number;
  public order!: number;
  public readonly expirationStatusWarningThresholdInDays!: number;
  public readonly expirationStatusCriticalThresholdInDays!: number;

  @Type(() => BodyboxStorageConfiguration)
  public readonly storageConfiguration!: BodyboxStorageConfiguration;

  @Type(() => BodyboxWeightConfiguration)
  public readonly weightConfiguration?: BodyboxWeightConfiguration;

  constructor(args?: Bodybox);
  constructor(args: Bodybox) {
    if (!args) return;
    this.id = args.id;
    this.name = args.name;
    this.capacity = args.capacity;
    this.storageConfiguration = args.storageConfiguration;
    this.weightConfiguration = args.weightConfiguration;
    this.order = args.order;
    this.expirationStatusWarningThresholdInDays =
      args.expirationStatusWarningThresholdInDays;
    this.expirationStatusCriticalThresholdInDays =
      args.expirationStatusCriticalThresholdInDays;
  }
}
