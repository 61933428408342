






import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { useVModel } from '@vueuse/core';
import { ModalEvent } from './modal-event';
import { useTripSwitch } from '~/app/composables/use-trip-switch';

export { ModalEvent };

export default defineComponent({
  name: 'TheCaseCheckInModalLazy',
  components: {
    TheCaseCheckInModal: () =>
      import(
        /* webpackChunkName: "case-checkin-modal" */
        './case-check-in-modal.vue'
      ),
  },
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: {
    /** Emitted when the visibility state is changed. */
    [ModalEvent.updateVisible]: (isVisible: any) =>
      typeof isVisible === 'boolean',
  },
  setup(props, { emit }) {
    const isVisible = useVModel(props, 'visible', emit);
    const loadModal = ref(false);

    useTripSwitch(isVisible, () => (loadModal.value = true));

    return { isVisible, loadModal, ModalEvent };
  },
});
