export class User {
  public readonly id!: string;

  public readonly firstName?: string;

  public readonly lastName?: string;

  public readonly emailAddress!: string;

  public readonly accessScopes!: string[];

  constructor(args?: Omit<User, 'displayName'>);
  constructor(args: Omit<User, 'displayName'>) {
    if (!args) return;
    this.id = args.id;
    this.firstName = args?.firstName;
    this.lastName = args?.lastName;
    this.emailAddress = args.emailAddress;
    this.accessScopes = args.accessScopes;
  }

  public get displayName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }
}
