import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4bfd5884 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _48b4177d = () => interopDefault(import('../pages/bodybox/index.vue' /* webpackChunkName: "pages/bodybox/index" */))
const _2ce55c72 = () => interopDefault(import('../pages/organization.vue' /* webpackChunkName: "pages/organization" */))
const _e6797516 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _0a3296e1 = () => interopDefault(import('../pages/organization/dictionaries.vue' /* webpackChunkName: "pages/organization/dictionaries" */))
const _072ff9ee = () => interopDefault(import('../pages/organization/users/index.vue' /* webpackChunkName: "pages/organization/users/index" */))
const _075f63c6 = () => interopDefault(import('../pages/organization/users/invite.vue' /* webpackChunkName: "pages/organization/users/invite" */))
const _dd35d2b4 = () => interopDefault(import('../pages/organization/users/invites.vue' /* webpackChunkName: "pages/organization/users/invites" */))
const _44af9fd6 = () => interopDefault(import('../pages/organization/users/_id.vue' /* webpackChunkName: "pages/organization/users/_id" */))
const _0492673c = () => interopDefault(import('../pages/outbox.vue' /* webpackChunkName: "pages/outbox" */))
const _7c1cdd3c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2b7e0b66 = () => interopDefault(import('../pages/reservations.vue' /* webpackChunkName: "pages/reservations" */))
const _47955ee2 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _48a37054 = () => interopDefault(import('../pages/case/active.vue' /* webpackChunkName: "pages/case/active" */))
const _99130268 = () => interopDefault(import('../pages/case/create.vue' /* webpackChunkName: "pages/case/create" */))
const _6fac3f55 = () => interopDefault(import('../pages/test/config.ts' /* webpackChunkName: "pages/test/config" */))
const _56a0dfa1 = () => interopDefault(import('../pages/test/test1.vue' /* webpackChunkName: "pages/test/test1" */))
const _f72855b6 = () => interopDefault(import('../pages/bodybox/_id.vue' /* webpackChunkName: "pages/bodybox/_id" */))
const _34b0f91a = () => interopDefault(import('../pages/case/_id.vue' /* webpackChunkName: "pages/case/_id" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4bfd5884,
    name: "account"
  }, {
    path: "/bodybox",
    component: _48b4177d,
    name: "bodybox"
  }, {
    path: "/organization",
    component: _2ce55c72,
    children: [{
      path: "",
      component: _e6797516,
      name: "organization"
    }, {
      path: "dictionaries",
      component: _0a3296e1,
      name: "organization-dictionaries"
    }, {
      path: "users",
      component: _072ff9ee,
      name: "organization-users"
    }, {
      path: "users/invite",
      component: _075f63c6,
      name: "organization-users-invite"
    }, {
      path: "users/invites",
      component: _dd35d2b4,
      name: "organization-users-invites"
    }, {
      path: "users/:id",
      component: _44af9fd6,
      name: "organization-users-id"
    }]
  }, {
    path: "/outbox",
    component: _0492673c,
    name: "outbox"
  }, {
    path: "/register",
    component: _7c1cdd3c,
    name: "register"
  }, {
    path: "/reservations",
    component: _2b7e0b66,
    name: "reservations"
  }, {
    path: "/settings",
    component: _47955ee2,
    name: "settings"
  }, {
    path: "/case/active",
    component: _48a37054,
    name: "case-active"
  }, {
    path: "/case/create",
    component: _99130268,
    name: "case-create"
  }, {
    path: "/test/config",
    component: _6fac3f55,
    name: "test-config"
  }, {
    path: "/test/test1",
    component: _56a0dfa1,
    name: "test-test1"
  }, {
    path: "/bodybox/:id",
    component: _f72855b6,
    name: "bodybox-id"
  }, {
    path: "/case/:id?",
    component: _34b0f91a,
    name: "case-id"
  }, {
    path: "/",
    component: _77002223,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
