import { createModule } from '../index';
import { state, IAccessState } from './state';
import { mutations, Mutation } from './mutations';
import { actions, Action } from './actions';

export type { IAccessState };
export { Action, Mutation };
export const moduleName = 'access';

export default createModule(moduleName, {
  state,
  mutations,
  actions,
});
