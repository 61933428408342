




















































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
  ref,
} from '@nuxtjs/composition-api';
import { useVModel } from '@vueuse/core';
import configureMeasurements from 'convert-units';
// @ts-expect-error - Cant import from convert-units/definitions/mass for some reason, need to go straight to the source
import massMeasures from 'convert-units/lib/esm/definitions/mass';
import type { MassUnits } from 'convert-units/definitions/mass';
import { genId } from '~/app/utils/id';

const convert = configureMeasurements({ mass: massMeasures });
const massUnits = convert().possibilities('mass');

function isEmpty(value: string | number | undefined) {
  return value === undefined || value === null || value === '';
}

function toUnitString(value: number, unit: MassUnits) {
  return isEmpty(value) ? undefined : `${value} ${unit}${value > 1 ? 's' : ''}`;
}

export default defineComponent({
  name: 'MassInput',
  model: {
    prop: 'mass',
    event: 'update:mass',
  },
  props: {
    mass: {
      type: Number as PropType<number | undefined>,
      required: false,
      default: undefined,
    },
    unit: {
      type: String as PropType<MassUnits>,
      required: true,
      validator: (value: string) => massUnits.includes(value),
    },
    convertUnit: {
      type: String as PropType<MassUnits>,
      required: false,
      default: undefined,
      validator: (value: string) => massUnits.includes(value),
    },
    fixedDecimal: {
      type: Number,
      required: false,
      default: 3,
    },
    size: {
      type: String as PropType<'small' | 'sm' | 'large' | 'lg' | undefined>,
      required: false,
      default: undefined,
      validator: (value?: string) =>
        ['small', 'sm', 'large', 'lg', undefined].includes(value),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:mass', 'input', 'change', 'focus', 'blur'],
  setup(props, { emit }) {
    const model = useVModel(props, 'mass', emit);

    const inputId = genId();

    function convertMeasure(value: number, from: MassUnits, to: MassUnits) {
      return +convert(value).from(from).to(to).toFixed(props.fixedDecimal);
    }

    const conversion: ComputedRef<number | undefined> = computed(() =>
      typeof model.value === 'number'
        ? convertMeasure(model.value, props.unit, props.convertUnit)
        : undefined
    );

    const inverse = ref(false);
    const measure = reactive({
      value: computed(() => (inverse.value ? conversion.value : model.value)),
      unit: computed(() => (inverse.value ? props.convertUnit : props.unit)),
      convertUnit: computed(() =>
        inverse.value ? props.unit : props.convertUnit
      ),
    });

    function stripNonNumeric(value?: string | number) {
      if (typeof value === 'number') return value;
      return value ? +value.replace(/[^\d.]*$/g, '').trim() : undefined;
    }

    function trimTrailingZeros(value?: string | number) {
      if (!['string', 'number'].includes(typeof value)) return undefined;

      // Remove trailing zeros after the decimal point
      let str = value!.toString();
      str = str.replace(/(\.\d*?[1-9])0*$/, '$1');

      // Convert back to number if there is no fractional part left
      return parseFloat(str);
    }

    function onChange(value?: number) {
      model.value = trimTrailingZeros(value);
      emit('input', model.value);
      emit('change', model.value);
    }

    const antdSize = computed(() => {
      if (props.size === 'small' || props.size === 'sm')
        return { name: 'small', value: 22 };
      if (props.size === 'large' || props.size === 'lg')
        return { name: 'large', value: 38 };
      return { name: undefined, value: 32 };
    });

    return {
      model,
      inputId,
      onChange,
      toUnitString,
      antdSize,
      measure,
      inverse,
      conversion,
      stripNonNumeric,
    };
  },
});
