


















import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'NotFoundErrorTemplate',
  props: {
    statusCode: {
      type: [Number, String] as PropType<number | string | undefined>,
      required: false,
      default: undefined,
    },
    message: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
});
