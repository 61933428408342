






































































































































































































import {
  defineComponent,
  ref,
  watch,
  computed,
  Ref,
  PropType,
  reactive,
} from '@nuxtjs/composition-api';
import consola from 'consola';
import { DateOnly } from '@/app/models/dates/date-only';
import { equals, isInvalidDate } from './utils';

const console = consola.withTag('🖋️');
const DEBUG = process.env.NODE_ENV === 'development';

/**
 * TODO: Support keyboard UP/DOWN keys incrementing/decrementing the segments value
 */

/**
 * TODO: Disabled dates
 * If date entered is disabled show it as red text and prevent a value from being emitted
 */

/**
 * TODO: Min date/Max date
 * aria-valuemax - indicates the maximal possible value of the time range that can be picked.
 * aria-valuemin - indicates the minimal possible value of the time range that can be picked.
 * aria-valuenow - indicates the currently picked value. It can be an hour or a minute depending on the time view.
 * aria-valuetext - human readable string representing aria-valuenow i.e "10 - October" for month
 *
 * For the picker input,
 * - if the date is outside of the accepted range, show red text/focus bg and prevent a value from being emitted. Possible error message/icon too
 * - aria-invalid="true"
 * - aria-describedby="error-message"
 */

/**
 * TODO: Support calendar popover with shortcuts
 * - Shortcuts - Now, Today, Yesterday, etc..
 * - Calendar should close and focus the next time part when the user selects a date
 * - Calender does not need to support keyboard navigation, its for mouse users only
 */

/**
 * TODO: Support input and change events.
 * - Input event should be emitted when the user types into a field that produces a valid date or time.
 * - Change events should be emitted when the user blurs from a field that produces a valid date or time.
 */

/**
 * TODO: Standardize year min/max values
 */

export type InputType =
  | 'month'
  | 'day'
  | 'year'
  | 'hour'
  | 'minute'
  | 'ampm'
  | 'timeZone';

type HTMLDateInputSegment =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLDivElement
  | null;

export const props = {
  value: {
    type: [Date, DateOnly] as PropType<Date | DateOnly | undefined>,
    required: false,
    default: undefined,
  },
  includeTime: {
    type: Boolean,
    required: false,
    default: true,
  },
  is24Hour: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String as PropType<'sm' | 'small' | 'md' | 'lg' | 'large'>,
    required: false,
    default: 'lg',
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: true,
  },
  /**
   * Force the picker to emit either a Date or DateOnly.
   * If `null` is provided, the picker will emit either a Date or DateOnly based on the inclusion of time.
   */
  type: {
    type: String as PropType<'date' | 'dateTime' | null>,
    required: false,
    default: 'dateTime',
    validator: (value: string | null) =>
      ['date', 'datetime', null].includes(value?.toLowerCase() || null),
  },
};

interface SetResult {
  date: Date | DateOnly | undefined;
  success: boolean;
}

export default defineComponent({
  name: 'DateTimePicker',
  props,
  emits: ['input', 'change'],
  setup(props, { emit, slots }) {
    let hasProducedValue = false;

    const dirty = ref(false);
    const touched = reactive({
      month: false,
      day: false,
      year: false,
      hour: false,
      minute: false,
      ampm: false,
    });

    const month = ref('');
    const day = ref('');
    const year = ref('');
    const hour = ref('');
    const minute = ref('');
    const ampm = ref<'am' | 'pm'>('am');
    const timeZone = ref('--');

    // #region Input Refs

    const monthInput = ref<HTMLInputElement | null>(null);
    const dayInput = ref<HTMLInputElement | null>(null);
    const yearInput = ref<HTMLInputElement | null>(null);
    const hourInput = ref<HTMLInputElement | null>(null);
    const minuteInput = ref<HTMLInputElement | null>(null);
    const ampmInput = ref<HTMLSelectElement | null>(null);
    const timeZoneInput = ref<HTMLDivElement | null>(null);
    const clearBtn = ref<HTMLButtonElement | null>(null);
    const inputs = computed(() => [
      monthInput.value,
      dayInput.value,
      yearInput.value,
      hourInput.value,
      minuteInput.value,
      ampmInput.value,
      timeZoneInput.value,
      clearBtn.value,
    ]);

    // #endregion

    // #region Input Handlers

    function handleMonthInput(e: Event) {
      touched.month = true;
      handleInput(e, month, 2, setMonth, monthInput.value, dayInput.value);
    }

    function handleDayInput(e: Event) {
      touched.day = true;
      handleInput(e, day, 2, setDay, dayInput.value, yearInput.value);
    }

    function handleYearInput(e: Event) {
      touched.year = true;
      const nextInput = props.includeTime ? hourInput.value : null;
      handleInput(e, year, 4, setYear, yearInput.value, nextInput);
    }

    function handleHourInput(e: Event) {
      touched.hour = true;
      handleInput(e, hour, 2, setHour, hourInput.value, minuteInput.value);
    }

    function handleMinuteInput(e: Event) {
      touched.minute = true;
      const nextInput = props.is24Hour ? null : ampmInput.value;
      handleInput(e, minute, 2, setMinute, minuteInput.value, nextInput);
    }

    function handleInput(
      event: Event,
      valueRef: Ref<string>,
      maxLength: number,
      setterFn: () => SetResult,
      currentInput: HTMLInputElement | HTMLSelectElement | null,
      nextInput: HTMLInputElement | HTMLSelectElement | null
    ) {
      dirty.value = true;

      const target = event.target as HTMLInputElement;
      valueRef.value = target.value.replace(/\D/g, '').slice(0, maxLength);
      if (valueRef.value.length === maxLength) {
        const { date, success } = setterFn();

        // reset the value if the date is invalid
        if (isInvalidDate(date)) {
          valueRef.value = '';
          return;
        }

        if (success) {
          setTimeZone(date);
          focusNextInput(currentInput, nextInput);
        }
      }
    }

    function focusNextInput(
      currentInput: HTMLDateInputSegment,
      nextInput: HTMLDateInputSegment
    ) {
      if (
        currentInput instanceof HTMLInputElement &&
        currentInput &&
        nextInput
      ) {
        // If the current input has reached its max allowed length, move to the next input
        const currentValue = currentInput.value;
        const hasMaxLength = currentValue.length === currentInput.maxLength;
        if (hasMaxLength) focusInput(nextInput);
        return;
      }

      focusInput(nextInput);
    }

    // #endregion

    // #region Input ARIA Attributes

    function getValueText(value: string, input: InputType) {
      const number = parseInt(value, 10);

      if (input === 'month') {
        const invalid = isNaN(number) || number < 1 || number > 12;
        if (invalid) return value;

        const currentLocale = navigator.language || 'en-US'; // fallback to en-US if not available
        const name = new Intl.DateTimeFormat(currentLocale, {
          month: 'long',
        }).format(new Date(2000, number - 1, 1));

        return `${number} - ${name}`;
      }

      return value;
    }

    // #endregion

    // #region Click/Focus/Blur Handlers

    function focusInput(input?: HTMLElement | null) {
      input?.focus();
      // Highlight/preselect all text in the next input
      input instanceof HTMLInputElement && input.select();
    }

    function handleFocus(event: FocusEvent, input: InputType) {
      if (event.target instanceof HTMLInputElement) event.target.select();
      emit('focus', event, input);
    }

    function handleClick(event: MouseEvent) {
      if (props.disabled) return;
      if (inputs.value.includes(event.target as HTMLInputElement)) return;

      if (!props.includeTime && !isEmpty.value) focusInput(yearInput.value);
      else focusInput(monthInput.value);
    }

    function handleBlur(event: FocusEvent, part: InputType | 'clear') {
      let date: Date | DateOnly | undefined;
      if (part === 'month') date = setMonth().date;
      else if (part === 'day') date = setDay().date;
      else if (part === 'year') date = setYear().date;
      else if (part === 'hour') date = setHour().date;
      else if (part === 'minute') date = setMinute().date;
      else if (part === 'ampm') date = setAmPm();
      else if (part === 'timeZone') date = computeDate();
      else if (part === 'clear') date = computeDate();

      // If the next target is one of the inputs, ignore the blur event
      const next = event.relatedTarget as HTMLInputElement;
      if (next && inputs.value.includes(next)) return;

      // Clear any date sections that are not complete
      if (!hasFullDate.value) resetDateOnly();
      if (props.includeTime && !hasFullTime.value) resetTime();

      // If changes have been made and there is a valid value (date/optional time), emit the date
      if (dirty.value && hasFullDate.value) emitDate(date, true);

      emit('blur', date, event);
      DEBUG && console.log('🏃‍♂️‍➡️ BLUR', date);
    }

    // #endregion

    // #region Date Part Setters

    function set(date?: Date | DateOnly): void {
      setDateTimeParts(date);
      dirty.value = true;
    }

    function setMonth(): SetResult {
      touched.month = true;

      const monthNum = parseInt(month.value);
      if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) month.value = '';
      else month.value = monthNum.toString().padStart(2, '0');

      const date = computeDate();
      // isValidDate.value && emitDate(date);

      return { date, success: month.value !== '' };
    }

    function setDay(): SetResult {
      touched.day = true;

      const dayNum = parseInt(day.value);
      const maxDays = new Date(
        parseInt(year.value),
        parseInt(month.value),
        0
      ).getDate();
      if (isNaN(dayNum) || dayNum < 1 || dayNum > maxDays) day.value = '';
      else day.value = dayNum.toString().padStart(2, '0');

      const date = computeDate();
      // isValidDate.value && emitDate(date);

      return { date, success: day.value !== '' };
    }

    function setYear(): SetResult {
      touched.year = true;

      const yearNum = parseInt(year.value);
      if (isNaN(yearNum) || yearNum < 1900 || yearNum > 2099) year.value = '';

      const date = computeDate();
      // isValidDate.value && emitDate(date);

      return { date, success: year.value !== '' };
    }

    function setHour(): SetResult {
      touched.hour = true;

      const hourNum = parseInt(hour.value);
      const maxHour = props.is24Hour ? 23 : 12;
      if (isNaN(hourNum) || hourNum < 0 || hourNum > maxHour) hour.value = '';
      else hour.value = hourNum.toString().padStart(2, '0');

      const date = computeDate();
      // isValidDate.value && isValidTime.value && emitDate(date);

      return { date, success: hour.value !== '' };
    }

    function setMinute(): SetResult {
      touched.minute = true;

      const minuteNum = parseInt(minute.value);
      if (isNaN(minuteNum) || minuteNum < 0 || minuteNum > 59)
        minute.value = '';
      else minute.value = minuteNum.toString().padStart(2, '0');

      const date = computeDate();
      // isValidDate.value && isValidTime.value && emitDate(date);

      return { date, success: minute.value !== '' };
    }

    function setTimeZone(date?: Date | DateOnly) {
      date = date || computeDate();
      if (date instanceof Date) {
        const currentLocale = navigator.language || 'en-US'; // fallback to en-US if not available
        const shortTimeZone = new Intl.DateTimeFormat(currentLocale, {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          timeZoneName: 'short',
        })
          .formatToParts(/* date */)
          .find((part) => part.type === 'timeZoneName')?.value;

        timeZone.value = shortTimeZone || '--';
      }
    }

    watch(ampm, () => (dirty.value = true));
    function setAmPm(focusNext = false) {
      touched.ampm = true;

      const date = computeDate();
      // isValidDate.value && isValidTime.value && emitDate(date);

      if (focusNext) focusNextInput(ampmInput.value, timeZoneInput.value);

      return date;
    }

    function clear() {
      dirty.value = true;
      resetDateOnly();
      resetTime();
      emitDate(undefined, true);
      focusInput(monthInput.value);
    }

    // #endregion

    // #region Date Validation/Computation

    const dateTouched = computed(
      () => touched.month && touched.day && touched.year
    );
    const timeTouched = computed(
      () => touched.hour && touched.minute && touched.ampm
    );
    const allTouched = computed(() => dateTouched.value && timeTouched.value);

    const hasFullDate = computed(
      () => !!month.value && !!day.value && !!year.value
    );
    const hasFullTime = computed(
      () => !!hour.value && !!minute.value && !!ampm.value
    );
    const isEmpty = computed(
      () =>
        !month.value &&
        !day.value &&
        !year.value &&
        !hour.value &&
        !minute.value
    );
    // const hasFullValue = computed(
    //   () => hasFullDate.value && (props.includeTime ? hasFullTime.value : true)
    // );

    // const isValidDate = computed(
    //   () => hasFullDate.value && (dirty.value ? dateTouched.value : true)
    // );
    // const isValidTime = computed(
    //   () => hasFullTime.value && (dirty.value ? timeTouched.value : true)
    // );

    function resetDateOnly() {
      month.value = '';
      day.value = '';
      year.value = '';
    }

    function resetTime() {
      hour.value = '';
      minute.value = '';
      ampm.value = 'am';
      timeZone.value = '--';
    }

    function computeDate(): Date | DateOnly | undefined {
      const toOutputType = (date: Date | DateOnly | undefined) => {
        if (!date) return undefined;
        if (props.type?.toLowerCase() === 'date')
          return date instanceof DateOnly ? date : new DateOnly(date);
        if (props.type?.toLowerCase() === 'datetime')
          return date instanceof DateOnly ? date.toDate() : date;
        return date;
      };

      if (hasFullDate.value) {
        if (props.includeTime && hasFullTime.value) {
          const y = parseInt(year.value);
          const m = parseInt(month.value);
          const d = parseInt(day.value);
          const hr = props.is24Hour
            ? parseInt(hour.value)
            : (parseInt(hour.value) % 12) + (ampm.value === 'pm' ? 12 : 0);
          const min = parseInt(minute.value);
          const date = new Date(y, m - 1, d, hr, min);
          return toOutputType(date);
        } else {
          const dateOnly = DateOnly.from(
            parseInt(year.value),
            parseInt(month.value),
            parseInt(day.value)
          );
          return toOutputType(dateOnly);
        }
      } else {
        return undefined;
      }
    }

    function emitDate(date?: Date | DateOnly, ignoreTouches = false) {
      // No change, ignore emit
      if (!dirty.value) {
        DEBUG && console.log('🟠 no changes have been made, ignore event');
        return;
      }

      // The current value and the date being emitted are the same, ignore emit
      // If the value has already been produced, ignore the equality check
      if ((initialized || hasProducedValue) && equals(props.value, date)) {
        DEBUG && console.log('🟠 value and input are the same, ignore event');
        return;
      }

      // If value is a Date but "Invalid Date", ignore emit
      if (isInvalidDate(date)) {
        DEBUG && console.log('🟠 invalid date, ignore event');
        return;
      }

      // If all fields have not been touched, ignore emit
      if (!ignoreTouches && !allTouched.value) {
        DEBUG &&
          console.log('🟠 all fields have not been touched, ignore event');
        return;
      }

      DEBUG && console.warn('🟢 EMIT', date, props.type);

      emit('input', date);
      emit('change', date);

      dirty.value = false;
      hasProducedValue = true;
    }

    function setDateTimeParts(date?: Date | DateOnly) {
      if (date instanceof Date) {
        month.value = (date.getMonth() + 1).toString().padStart(2, '0');
        day.value = date.getDate().toString().padStart(2, '0');
        year.value = date.getFullYear().toString();
        if (props.includeTime) {
          if (props.is24Hour) {
            hour.value = date.getHours().toString().padStart(2, '0');
          } else {
            const hours = date.getHours();
            hour.value = (hours % 12 || 12).toString().padStart(2, '0');
            ampm.value = hours >= 12 ? 'pm' : 'am';
          }
          minute.value = date.getMinutes().toString().padStart(2, '0');
          setTimeZone();
        }
      } else if (date instanceof DateOnly) {
        month.value = (date.getMonth() + 1).toString().padStart(2, '0');
        day.value = date.getDate().toString().padStart(2, '0');
        year.value = date.getFullYear().toString();
      } else {
        resetDateOnly();
        resetTime();
      }
    }

    function touchDateParts(touch = true) {
      touched.month = touched.day = touched.year = touch;
    }

    function touchTimeParts(touch = true) {
      touched.hour = touched.minute = touched.ampm = touch;
    }

    watch(
      () => props.value,
      (newValue) => {
        if (!newValue) {
          // value cleared, reset the touched parts
          touchDateParts(false);
          touchTimeParts(false);
        }

        setDateTimeParts(newValue);

        dirty.value = true;
        if (hasFullDate.value) touchDateParts();
        if (hasFullTime.value) touchTimeParts();
      }
    );

    // Set the initial date parts
    setDateTimeParts(props.value);

    /** Indicates if the component has been initialized with a value */
    const initialized = !!props.value;

    // #endregion

    const hasPrefix = computed(() => !!slots.prefix?.());
    const hasSuffix = computed(() => !!slots.suffix?.());

    return {
      month,
      day,
      year,
      hour,
      minute,
      ampm,
      timeZone,
      // Input Refs
      monthInput,
      dayInput,
      yearInput,
      hourInput,
      minuteInput,
      ampmInput,
      timeZoneInput,
      clearBtn,
      // ARIA
      getValueText,
      // Validation
      hasFullTime,
      // Event Handlers
      handleFocus,
      handleClick,
      handleMonthInput,
      handleDayInput,
      handleYearInput,
      handleHourInput,
      handleMinuteInput,
      handleBlur,
      set,
      setAmPm,
      clear,
      // Slots
      hasPrefix,
      hasSuffix,
    };
  },
});
