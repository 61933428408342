import { OriginLocationType } from '../case';
import { FilterableDictionaryItem } from './dictionary-item';

export class OriginLocationDictionaryItem extends FilterableDictionaryItem {
  public readonly type!: OriginLocationType;

  public get filterValue() {
    return this.type;
  }

  constructor(args?: Omit<OriginLocationDictionaryItem, 'filterValue'>) {
    super(args);
    this.type = args?.type ?? OriginLocationType.Unknown;
  }
}
