import { ILogger, LoggerArg } from './ILogger';
import { ToastLogger, ToastOptions } from './toast/logger';

type ILoggerImpl<TLogger extends ILogger> = new (...args: any[]) => TLogger;

type UIToastConfig = {
  /** If a `boolean` value is provided, toggle logging to the UI via a toast notification.
   * If an `object` is provided, it will be used to configure and push a toast notification.
   */
  ui: boolean | ToastOptions;
};

export class LogProvider implements ILogger {
  private readonly loggers: Set<ILogger>;

  constructor() {
    this.loggers = new Set<ILogger>([new ToastLogger()]);
  }

  public addLogger(logger: ILogger): void {
    this.loggers.add(logger);
  }

  public removeLogger(logger: ILogger): void {
    this.loggers.delete(logger);
  }

  public getLogger<TLogger extends ILogger>(
    type: ILoggerImpl<TLogger>
  ): TLogger | undefined {
    return [...this.loggers].find((logger) => logger instanceof type) as
      | TLogger
      | undefined;
  }

  public log(message: string, data?: LoggerArg, toast?: UIToastConfig): void {
    for (const logger of this.loggers) {
      try {
        if (logger instanceof ToastLogger && toast?.ui) {
          const toastOptions =
            typeof toast.ui === 'boolean' ? undefined : toast.ui;

          logger.log(message, data, toastOptions);
        } else {
          logger.log(message, data);
        }
      } catch (error) {}
    }
  }

  public info(message: string, data?: LoggerArg, toast?: UIToastConfig): void {
    for (const logger of this.loggers) {
      try {
        if (logger instanceof ToastLogger && toast?.ui) {
          const toastOptions =
            typeof toast.ui === 'boolean' ? undefined : toast.ui;

          logger.info(message, data, toastOptions);
        } else {
          logger.info(message, data);
        }
      } catch (error) {}
    }
  }

  public warn(message: string, data?: LoggerArg, toast?: UIToastConfig): void {
    for (const logger of this.loggers) {
      try {
        if (logger instanceof ToastLogger && toast?.ui) {
          const toastOptions =
            typeof toast.ui === 'boolean' ? undefined : toast.ui;

          logger.warn(message, data, toastOptions);
        } else {
          logger.warn(message, data);
        }
      } catch (error) {}
    }
  }

  public debug(message: string, data?: LoggerArg, toast?: UIToastConfig): void {
    for (const logger of this.loggers) {
      try {
        if (logger instanceof ToastLogger && toast?.ui) {
          const toastOptions =
            typeof toast.ui === 'boolean' ? undefined : toast.ui;

          logger.debug(message, data, toastOptions);
        } else {
          logger.debug(message, data);
        }
      } catch (error) {}
    }
  }

  public error(
    message: string | Error,
    data?: LoggerArg,
    toast?: UIToastConfig
  ): void {
    for (const logger of this.loggers) {
      try {
        if (logger instanceof ToastLogger) {
          const useToast = typeof toast?.ui === 'boolean' ? toast.ui : true;
          if (!useToast) continue;

          const toastOptions: ToastOptions | undefined =
            typeof toast?.ui === 'boolean' ? undefined : toast?.ui;

          logger.error(message, data, toastOptions);
        } else {
          logger.error(message, data);
        }
      } catch (error) {}
    }
  }

  public track(event: string, data?: LoggerArg): void {
    for (const logger of this.loggers) {
      try {
        logger.track(event, data);
      } catch (error) {}
    }
  }
}
