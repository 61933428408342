
import { defineComponent, PropType } from '@nuxtjs/composition-api';

/**
 * @example
 * <wb-data
    v-slot="{ listItems, hasItems }"
    :listItems="someArray"
    :hasItems="someArray.length > 0"
  >
    <ul v-if="hasItems">
      <li v-for="(item, index) in listItems" :key="index">{{ item }}</li>
    </ul>
    <div v-else>The list is empty.</div>
  </wb-data>
 */
export default defineComponent({
  name: 'DataComponent',
  props: {
    data: {
      type: [Object, Array, Number, String, Boolean] as PropType<any>,
      required: false,
      default: undefined,
    },
  },
  setup(props, { slots, attrs }) {
    const clearAndUpper = (text: string) => {
      return text.replace(/-/, '').toUpperCase();
    };

    const toCamelCase = (text: string) => {
      return text.replace(/-\w/g, clearAndUpper);
    };

    const convertObjToCamelCase = (obj: object) => {
      return Object.entries(obj).reduce((newObj, [key, value]) => {
        newObj = { ...newObj, [toCamelCase(key)]: value };
        return newObj;
      }, {} as object);
    };

    return () =>
      slots?.default?.({
        ...convertObjToCamelCase(attrs),
        ...convertObjToCamelCase(props),
        data: props.data,
      });
  },
});
