import { computed, ref } from '@nuxtjs/composition-api';
import { AppModule } from '~/app/core/module-system/module';
import { registerModule } from '~/app/core/module-system/use-module';

export interface IBreadCrumbRoute {
  name: string;
  path?: string;
}

class BreadCrumbBuilder {
  private breadCrumbParts = ref<IBreadCrumbRoute[]>([]);

  public get parts() {
    return computed(() => Array.from(this.breadCrumbParts.value));
  }

  public setBreadCrumbs(crumbs: IBreadCrumbRoute | IBreadCrumbRoute[]) {
    if (Array.isArray(crumbs)) this.breadCrumbParts.value = crumbs;
    else this.breadCrumbParts.value = [crumbs];
  }

  public push(breadCrumb: IBreadCrumbRoute) {
    this.breadCrumbParts.value.push(breadCrumb);
  }
}

export class LayoutModule extends AppModule {
  public readonly name = LayoutModule.name;
  private breadCrumbBuilder = new BreadCrumbBuilder();

  public get breadCrumbs() {
    return this.breadCrumbBuilder;
  }

  public activate() {
    super.activate();
  }
}

export const ModuleName = LayoutModule.name;

export const registerLayoutModule = () => registerModule(LayoutModule);
