import { RegistrationRoute } from './routes';
import { AppModule } from '~/app/core/module-system/module';
import { registerModule } from '~/app/core/module-system/use-module';
import { AuthTaskQueueDeferralController } from '~/app/core/auth/events/task-queue';
import { RegisterUserRequest } from '~/app/core/api/requests/user/register-user-request';
import {
  moduleName as AccessModuleName,
  Action as AccessAction,
} from '~/store/access';

export class UserRegistrationModule extends AppModule {
  public readonly name = UserRegistrationModule.name;

  private authLoginFlowDeferralController?: AuthTaskQueueDeferralController;

  public activate() {
    super.activate();
  }

  public setLoginDeferralController(
    controller: AuthTaskQueueDeferralController
  ) {
    this.authLoginFlowDeferralController = controller;
  }

  public async registerUserAsync(
    emailAddress: string,
    firstName: string,
    lastName: string
  ) {
    const request = new RegisterUserRequest({
      emailAddress,
      firstName,
      lastName,
    });

    const user = await this.context.$api.user.registration.registerAsync(
      request
    );

    await this.context.store.dispatch(
      `${AccessModuleName}/${AccessAction.setUser}`,
      user
    );

    // Process the login events queue to notify subscribers that login is complete
    this.authLoginFlowDeferralController?.processQueue();
  }
}

export const ModuleName = UserRegistrationModule.name;

export const registerUserRegistrationModule = () =>
  registerModule(UserRegistrationModule, {
    route: RegistrationRoute.root,
    activationMethod: 'route-enter',
  });
