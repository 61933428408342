import Vue, { VueConstructor, PluginObject } from 'vue';
import WbChecklist from './checklist.vue';
import WbChecklistItem from './checklist-item.vue';

const checklist: VueConstructor<Vue> & PluginObject<void> = WbChecklist as any;
checklist.install = function (vue) {
  vue.component('WbChecklist', WbChecklist);
  vue.component('WbChecklistItem', WbChecklistItem);
};

export { WbChecklist, WbChecklistItem };
export default checklist;
