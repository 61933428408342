import { module as StoreModule, moduleName, Action } from './store';
import { AppModule } from '~/app/core/module-system/module';
import { registerModule } from '~/app/core/module-system/use-module';
import { useSuccessMessage } from '~/app/components/ui/modal';
import { Case } from '~/app/models';

export class CaseDetailsModule extends AppModule {
  public readonly name = CaseDetailsModule.name;
  private unsubscribeStoreActions?: Function;

  public activate() {
    super.activate();

    this.unsubscribeStoreActions = this.context.store?.subscribeAction({
      after: (action, _state) => {
        const checkInAction = `${moduleName}/${Action.checkIn}`;
        const checkOutAction = `${moduleName}/${Action.checkout}`;

        let decedentName: string | undefined;
        if (action.payload instanceof Case) {
          decedentName = action.payload.decedent?.displayName;
        }

        const lang = this.context.$language;
        const messagePrefix = () =>
          lang.token(
            'case',
            `The {singular} ${decedentName ? 'for ' + decedentName : ''}` as any
          );

        if (action.type === checkInAction) {
          const message = useSuccessMessage();
          message({
            duration: 5,
            content: `${messagePrefix()} was successfully checked in. It can now be viewed from the ${
              lang.get('bodybox').singular
            }.`,
          });
        }

        if (action.type === checkOutAction) {
          const message = useSuccessMessage();
          message({
            duration: 5,
            content: `${messagePrefix()} was successfully checked out. It can now be viewed from the ${
              lang.get('outbox').singular
            }.`,
          });
        }
      },
    });
  }

  public deactivate() {
    super.deactivate();
    this.unsubscribeStoreActions?.();
  }
}

export const ModuleName = CaseDetailsModule.name;
export { moduleName as StoreModuleName };

export const registerCaseDetailsModule = () =>
  registerModule(CaseDetailsModule, {
    route: 'case',
    store: {
      moduleName,
      module: StoreModule,
    },
  });
