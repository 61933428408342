import { AccessScopeMasterList, User } from '~/app/models';

export interface IAccessState {
  user: Readonly<User> | null;
  accessScopeMasterList: Readonly<AccessScopeMasterList> | null;
}

const state = (): IAccessState => ({
  user: null,
  accessScopeMasterList: null,
});

export { state };
export default state;
