import { Type } from 'class-transformer';
import { CaseService } from './case-service';
import { CaseServiceCollection } from './case-services';

export enum DonationProcurementType {
  Unknown = 0,
  Organ = 1,
  Tissue = 2,
  Eye = 3,
}

export class DonationProcurement extends CaseService {
  public readonly type!: DonationProcurementType;

  constructor(args?: Partial<DonationProcurement>) {
    super(args);
    this.type = args?.type ?? DonationProcurementType.Unknown;
  }
}

export class DonationProcurementCollection extends CaseServiceCollection<DonationProcurement> {
  @Type(() => DonationProcurement)
  public readonly instances!: DonationProcurement[];

  constructor(args?: Partial<DonationProcurementCollection>) {
    super(args);
    this.instances = args?.instances
      ? args.instances.map((i) => new DonationProcurement(i))
      : [];
  }
}
