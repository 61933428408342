import { format, isValid, parse } from 'date-fns';
import { DateOnlyFormatToken, DateTimeFormatToken } from './format-tokens';

export class FormatDate extends Date {
  static readonly formatToken = DateOnlyFormatToken;

  constructor(
    date?: string | number | Date,
    month?: number,
    day?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ) {
    if (!date) super();
    else if (typeof date === 'number' && typeof month === 'number')
      super(date, month, day, hours, minutes, seconds, ms);
    else if (typeof date === 'number') super(date);
    else if (typeof date === 'string') {
      super(FormatDate.parseDateLoose(date) ?? 'Invalid Date');
    } else super(date);
  }

  public toString() {
    const formatString = format(this, FormatDate.formatToken) as string;
    return formatString;
  }

  public toISOString() {
    return this.toString();
  }

  public toJSON(_key?: any) {
    return this.toString();
  }

  /**
   * Parse a string into a Date object so long as it can be parsed as either a date (`DateOnlyFormatToken`) or a date/time (`DateTimeFormatToken`).
   * @param str The string to parse.
   * @returns The parsed Date object, or null if the string could not be parsed.
   */
  private static parseDateLoose(str: string): Date | null {
    return FormatDate.parseDateTime(str) || FormatDate.parseDateOnly(str);
  }

  /**
   * Parse a string into a Date object using the `DateOnlyFormatToken` format token.
   * @param str The string to parse.
   * @returns The parsed Date object, or null if the string could not be parsed.
   */
  private static parseDateOnly(str: string): Date | null {
    const date = parse(str, DateOnlyFormatToken, new Date());
    return isValid(date) ? date : null;
  }

  /**
   * Parse a string into a Date object using the `DateTimeFormatToken` format token.
   * @param str The string to parse.
   * @returns The parsed Date object, or null if the string could not be parsed.
   */
  private static parseDateTime(str: string): Date | null {
    const date = parse(str, DateTimeFormatToken, new Date());
    return isValid(date) ? date : null;
  }
}
