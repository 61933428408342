import { extend } from 'vee-validate';

export type ValidationRule = {
  validate: (value: any) => boolean;
  message: string | ((value: any, fieldName: string) => string);
};

// see: https://github.com/logaretm/vee-validate/issues/2736
extend('custom', {
  params: ['validators'],
  validate: (value, args) => {
    if (!('validators' in args)) {
      console.error('[vee-validate: "type" rule] missing type argument.');
      return false;
    }

    const validators: ValidationRule[] = args.validators;

    const failedValidator = validators.find((validator) => {
      return validator.validate(value) === false;
    });
    return failedValidator === undefined;
  },
  message: (field, args) => {
    const value = args._value_;
    const validators: ValidationRule[] = args.validators;

    const failedValidator = validators.find((validator) => {
      return validator.validate(value) === false;
    });

    let outputMessage = '';

    if (failedValidator) {
      outputMessage =
        typeof failedValidator.message === 'function'
          ? failedValidator.message(value, field)
          : failedValidator.message;
    }

    return outputMessage;
  },
});
