









import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { BodyboxCapacity } from '~/app/models';

export default defineComponent({
  name: 'BodyboxCapacityBadge',
  props: {
    capacity: {
      type: Object as PropType<BodyboxCapacity>,
      required: true,
    },
  },
  setup(props) {
    const size = computed(() => props.capacity.capacity);
    const taken = computed(() => props.capacity.totalCases);
    const available = computed(() => size.value - taken.value);

    const percentAvailable = computed(
      () => (available.value / size.value) * 100
    );

    const badgeType = computed(() => {
      if (percentAvailable.value < 25) return 'danger';
      if (percentAvailable.value < 50) return 'warning';
      return 'success';
    });

    return { size, available, badgeType, percentAvailable };
  },
});
