/* eslint-disable no-redeclare */
import { format, parse, isValid, parseISO } from 'date-fns';
import { DateTimeInfo } from './date-time-info';
import { DateOnly } from './date-only';

/**
 * Parse an ISO 8601 string into a Date object.
 * @param str The string to parse.
 * @returns The parsed Date object, or null if the string could not be parsed.
 */
export function parseISODateTime(str: string): Date | null {
  const date = parseISO(str);
  return isValid(date) ? date : null;
}

/**
 * Parse an ISO 8601 (date only) string into a Date object.
 * @param str The string to parse.
 * @returns The parsed Date object, or null if the string could not be parsed.
 */
export function parseISODateOnly(str: string): Date | null {
  const date = parse(str, 'yyyy-MM-dd', new Date());
  return isValid(date) ? date : null;
}

/**
 * Parse an ISO 8601 string into a Date object so long as it can be parsed as either an ISO 8601 date only or a date/time.
 * @param str The string to parse.
 * @returns The parsed Date object, or null if the string could not be parsed.
 */
export function parseISODateLoose(str: string): Date | null {
  return parseISODateTime(str) || parseISODateOnly(str);
}

/**
 * Parses a date/time value into a primitive DateOnly or Date object.
 * @param value The date like value to parse. If value is a string it will be parsed as an ISO 8601 date/time.
 * @returns The parsed DateOnly or Date object, or null if the value could not be parsed.
 */
type DateLike = Date | DateTimeInfo | string;
export function parseDate(
  value?: DateLike,
  options: { truncateZeroTime: boolean } = { truncateZeroTime: false }
): Date | DateOnly | null {
  const isZeroTime = (date: Date) =>
    options.truncateZeroTime &&
    [date.getHours(), date.getMinutes(), date.getSeconds()].every(
      (p) => p === 0
    );

  if (value instanceof DateTimeInfo) {
    if (value.time && isZeroTime(value.time)) return new DateOnly(value.time!);
    return value.time || value.date;
  }

  if (value instanceof DateOnly) return value;

  if (typeof value === 'string') {
    const date = parseISODateLoose(value);
    if (date && isZeroTime(date)) return new DateOnly(date!);
    return date;
  }

  return value ?? null;
}

type DateType = 'numerical' | 'pretty';
interface FormatDateOptions {
  type?: DateType;
}

interface FormatDateTimeOptions extends FormatDateOptions {
  time?: boolean;
}

export function formatDate(date: Date, options?: FormatDateTimeOptions): string;
export function formatDate(date: DateOnly, options?: FormatDateOptions): string;
export function formatDate(
  date: Date | DateOnly,
  options?: FormatDateTimeOptions | FormatDateOptions
): string {
  const { type, time } = {
    type: 'numerical' as DateType,
    time: false,
    ...(options || {}),
  };

  const patterns = {
    numerical: {
      date: 'MM/dd/yyyy',
      time: 'h:mm aa',
    },
    pretty: {
      date: 'MMMM do, yyyy',
      time: 'h:mm aa',
    },
  } as const;

  const value = date instanceof DateOnly ? date.toDate() : date;
  const pattern = patterns[type];
  const formatPattern = time ? `${pattern.date} ${pattern.time}` : pattern.date;

  return format(value, formatPattern);
}
