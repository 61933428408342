




















































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRef,
} from '@nuxtjs/composition-api';
import Packery from 'packery';
import { useColorPickerColor } from '~/app/components/ui/color-picker';
import {
  useExpirationFormatter,
  ExpirationStatus,
} from '~/app/composables/use-expiration-formatter';
import { useStoreModule } from '~/app/composables/use-store-module';
import {
  BodyboxCaseSummary,
  CaseSummary,
  DisplayTag,
  ReservationCaseSummary,
  Tenant,
} from '~/app/models';
import { formatDate } from '~/app/models/dates/utils';
import { ITenantState, moduleName as TenantModuleName } from '~/store/tenant';

export default defineComponent({
  name: 'CaseCardDetails',
  props: {
    summary: {
      type: Object as PropType<
        CaseSummary | ReservationCaseSummary | BodyboxCaseSummary
      >,
      required: true,
      validator: (obj: any) =>
        obj instanceof CaseSummary ||
        obj instanceof ReservationCaseSummary ||
        obj instanceof BodyboxCaseSummary,
    },
  },
  setup(props) {
    const summary = toRef(props, 'summary');
    const dateOfDeath = computed(() => summary.value.decedentExpirationDate);
    const { status, ...expiration } = useExpirationFormatter(dateOfDeath);

    const expirationTagType = computed(() => {
      if (!dateOfDeath.value) return 'default';
      if (status.value === ExpirationStatus.good) return 'success';
      if (status.value === ExpirationStatus.warning) return 'warning';
      if (status.value === ExpirationStatus.danger) return 'danger';
    });

    const tagsList = ref<HTMLUListElement>();
    onMounted(async () => {
      await nextTick();

      if (!tagsList.value) return;

      const packery = new Packery(tagsList.value, {
        itemSelector: '.tag',
      });

      packery.bindResize();
    });

    const getTagColor = (tag: DisplayTag) => {
      if (!tag.displayOptions) return useColorPickerColor('default', 500);
      return useColorPickerColor(
        tag.displayOptions.color,
        tag.displayOptions.colorLevel
      );
    };

    const isBodyboxSummary = (
      summary: ReservationCaseSummary | BodyboxCaseSummary
    ): summary is BodyboxCaseSummary => {
      return summary instanceof BodyboxCaseSummary;
    };

    const tenantStore = useStoreModule<ITenantState>(TenantModuleName);
    const tenant = tenantStore.state.tenant as Tenant;
    const caseConfiguration = tenant.configuration.cases;
    const labels = {
      dateOfBirth: caseConfiguration.decedent.form.dateOfBirth.label,
      alias: caseConfiguration.decedent.form.alias.label,
      mrn:
        caseConfiguration.decedent.form.medicalRecordNumber.label ===
        'Medical Record Number'
          ? 'MRN'
          : caseConfiguration.decedent.form.medicalRecordNumber.label,
    };

    return {
      expirationDuration: computed(() =>
        dateOfDeath.value ? expiration.formattedDuration.value : null
      ),
      expirationDate: computed(() =>
        dateOfDeath.value
          ? expiration.formattedDate.value
          : 'Expiration Date Unknown'
      ),
      expirationTagType,
      ExpirationStatus,
      tagsList,
      getTagColor,
      isBodyboxSummary,
      labels,
      formatDate,
    };
  },
});
