import Vue, { VueConstructor, PluginObject } from 'vue';
import WbProgress from './progress.vue';

const progress: VueConstructor<Vue> & PluginObject<void> = WbProgress as any;
progress.install = function (vue) {
  vue.component('WbProgress', progress);
};

export { WbProgress };
export * from './types';
export default progress;
