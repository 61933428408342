import { createModule } from '../index';
import { state, IDictionariesState } from './state';
import { actions, Action } from './actions';
import { mutations, Mutation } from './mutations';

export { Action, Mutation };
export type { IDictionariesState };
export const moduleName = 'dictionaries';

export default createModule(moduleName, {
  state,
  mutations,
  actions,
});
