export class BodyboxWeightAlert {
  public readonly id!: string;
  public readonly title!: string;
  public readonly description?: string;
  public readonly minThreshold?: number;
  public readonly minThresholdInclusive!: boolean;
  public readonly maxThreshold?: number;
  public readonly maxThresholdInclusive!: boolean;

  constructor(
    args: Omit<
      BodyboxWeightAlert,
      | 'isWithinThreshold'
      | 'isAboveMinThreshold'
      | 'isBelowMaxThreshold'
      | 'isEqualToThreshold'
    >
  ) {
    if (!args) return;
    this.id = args.id;
    this.title = args.title;
    this.description = args.description;
    this.minThreshold = args.minThreshold;
    this.minThresholdInclusive = args.minThresholdInclusive;
    this.maxThreshold = args.maxThreshold;
    this.maxThresholdInclusive = args.maxThresholdInclusive;
  }

  public isWithinThreshold(weight: number): boolean {
    const isWithin =
      this.isAboveMinThreshold(weight) && this.isBelowMaxThreshold(weight);
    return isWithin && !this.isEqualToThreshold(weight);
  }

  public isAboveMinThreshold(weight: number): boolean {
    return (
      (!this.minThreshold && typeof this.minThreshold !== 'number') ||
      (this.minThresholdInclusive
        ? weight >= this.minThreshold
        : weight > this.minThreshold)
    );
  }

  public isBelowMaxThreshold(weight: number): boolean {
    return (
      (!this.maxThreshold && typeof this.maxThreshold !== 'number') ||
      (this.maxThresholdInclusive
        ? weight <= this.maxThreshold
        : weight < this.maxThreshold)
    );
  }

  public isEqualToThreshold(weight: number): boolean {
    return (
      typeof this.minThreshold === 'number' &&
      typeof this.maxThreshold === 'number' &&
      weight === this.minThreshold &&
      weight === this.maxThreshold
    );
  }
}
