import Vue from 'vue';

export class EventBus<TEventName extends string> {
  private readonly bus: Vue;

  constructor(emitter: Vue) {
    this.bus = emitter;
  }

  public on(event: TEventName, callback: Function) {
    this.bus.$on(event, callback);
  }

  public once(event: TEventName, callback: Function) {
    this.bus.$once(event, callback);
  }

  public off(event?: TEventName, callback?: Function) {
    this.bus.$off(event, callback);
  }
}
