import Vue, { VueConstructor, PluginObject } from 'vue';
import WbGrid from './grid.vue';
import WbGridColumn from './grid-column.vue';
export { useGrid, createComponentFormatter } from './use-grid';
export type { TColumn } from './use-grid';

const grid: VueConstructor<Vue> & PluginObject<void> = WbGrid as any;
grid.install = function (vue) {
  vue.component('WbGrid', WbGrid);
  vue.component('WbGridColumn', WbGridColumn);
};

export { WbGrid, WbGridColumn };
export default WbGrid;
