import { Transform } from 'class-transformer';
import { FormatDateTime, IsFormatDateTime } from '../dates';
import { RequirementStatus } from './requirement-status';

export enum AutopsyType {
  unknown,
  House = 'house',
  Vendor = 'vendor',
}

export interface IAutopsyRecord {
  readonly id?: string;
  type: AutopsyType;
  dateScheduled?: Date;
  dateCompleted?: Date;
}

export class HouseAutopsy implements Partial<IAutopsyRecord> {
  public readonly id?: string;

  public type!: AutopsyType.House;

  @IsFormatDateTime
  public dateScheduled?: FormatDateTime;

  @IsFormatDateTime
  public dateCompleted?: FormatDateTime;

  constructor(args?: HouseAutopsy | Partial<IAutopsyRecord>) {
    this.id = args?.id;
    this.type = AutopsyType.House;
    this.dateScheduled = args?.dateScheduled
      ? new FormatDateTime(args?.dateScheduled)
      : undefined;
    this.dateCompleted = args?.dateCompleted
      ? new FormatDateTime(args?.dateCompleted)
      : undefined;
  }
}

export class VendorAutopsy implements Partial<IAutopsyRecord> {
  public readonly id?: string;

  public type!: AutopsyType.Vendor;

  public vendorId?: string;

  @IsFormatDateTime
  public dateScheduled?: FormatDateTime;

  @IsFormatDateTime
  public dateCompleted?: FormatDateTime;

  constructor(args?: VendorAutopsy | Partial<IAutopsyRecord>) {
    this.id = args?.id;
    this.type = AutopsyType.Vendor;
    this.vendorId = args && 'vendorId' in args ? args?.vendorId : undefined;
    this.dateScheduled = args?.dateScheduled
      ? new FormatDateTime(args?.dateScheduled)
      : undefined;
    this.dateCompleted = args?.dateCompleted
      ? new FormatDateTime(args?.dateCompleted)
      : undefined;
  }
}

export class AutopsyCollection {
  public requirementStatus?: RequirementStatus;

  @Transform(({ value }) =>
    value?.map((autopsy: any) => new HouseAutopsy(autopsy))
  )
  public inHouse: HouseAutopsy[];

  @Transform(({ value }) =>
    value?.map((autopsy: any) => new VendorAutopsy(autopsy))
  )
  public vendor: VendorAutopsy[];

  constructor(args?: Partial<AutopsyCollection>) {
    this.requirementStatus = args?.requirementStatus;
    this.inHouse = args?.inHouse || [];
    this.vendor = args?.vendor || [];
  }
}
