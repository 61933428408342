import { IApplicationInsights } from '@microsoft/applicationinsights-web';
import { ILogger, LoggerArg } from '../ILogger';

export class AppInsightsLogger implements ILogger {
  private readonly appInsights: IApplicationInsights;

  constructor(appInsights: IApplicationInsights) {
    if (!appInsights) throw new Error('appInsights instance is required.');
    this.appInsights = appInsights;
  }

  public log(message: string, data?: LoggerArg): void {
    this.logEvent('info', message, data);
  }

  public info(message: string, data?: LoggerArg): void {
    this.logEvent('info', message, data);
  }

  public warn(message: string, data?: LoggerArg): void {
    this.logEvent('warn', message, data);
  }

  public debug(message: string, data?: LoggerArg): void {
    this.logEvent('debug', message, data);
  }

  public error(message: string | Error, data?: LoggerArg): void {
    const error = message instanceof Error ? message : new Error(message);
    this.appInsights.trackException({ exception: error }, data);
  }

  public track(event: string, data?: LoggerArg): void {
    this.appInsights.trackEvent({ name: event }, data);
  }

  private logEvent(
    type: 'info' | 'warn' | 'debug',
    message: string,
    data?: LoggerArg
  ): void {
    this.appInsights.trackEvent({ name: type }, { ...(data || {}), message });
  }
}
