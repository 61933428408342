import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { AuthEvent } from '~/app/core/auth/events';
import { InvitationContext } from '~/app/core/auth/types/service';
import { useModule } from '~/app/core/module-system';
import { UserRegistrationModule } from '~/app/modules/registration';
import { RegistrationRoute } from '~mod:registration/routes';

export default defineNuxtPlugin(({ $auth, $log, route, redirect }) => {
  $auth.addMiddleware('afterLogin', ({ events }) => {
    const registrationRoute = `/${RegistrationRoute.root}`;
    const isUnregistered = !$auth.getUserClaim('user_registered');
    if (isUnregistered) {
      // set highest priority so that other login event queues will not overwrite this one
      events.setPriority(Number.MAX_SAFE_INTEGER);
      const deferralController = events.defer();

      const module = useModule(UserRegistrationModule);
      module.setLoginDeferralController(deferralController);

      if (route.path !== registrationRoute) return redirect(registrationRoute);
    } else if (route.path === registrationRoute) return redirect('/');
  });

  $auth.events.on(AuthEvent.InvitationAccepted, (ctx: InvitationContext) => {
    $log.track('InviteAccepted', ctx);
  });
});
