import { Age } from '../common';
import { DateTimeInfo, IsDateTimeInfo } from '../dates';

export class DecedentInfo {
  public alias?: string;

  public firstName?: string;

  public middleName?: string;

  public lastName?: string;

  public readonly age?: Age;

  @IsDateTimeInfo()
  public dateOfBirth?: DateTimeInfo;

  @IsDateTimeInfo()
  public dateOfDeath?: DateTimeInfo;

  public genderId?: string;

  public medicalRecordNumber?: string;

  public alternateMedicalRecordNumber?: string;

  public socialSecurityNumber?: string;

  public weight?: number;

  public raceId?: string;

  public ethnicityId?: string;

  public maritalStatusId?: string;

  public countyId?: string;

  constructor(args?: Partial<DecedentInfo>) {
    this.alias = args?.alias;
    this.firstName = args?.firstName;
    this.middleName = args?.middleName;
    this.lastName = args?.lastName;
    this.dateOfBirth = args?.dateOfBirth
      ? new DateTimeInfo(args.dateOfBirth)
      : undefined;
    this.dateOfDeath = args?.dateOfDeath
      ? new DateTimeInfo(args.dateOfDeath)
      : undefined;
    this.genderId = args?.genderId;
    this.medicalRecordNumber = args?.medicalRecordNumber;
    this.alternateMedicalRecordNumber = args?.alternateMedicalRecordNumber;
    this.socialSecurityNumber = args?.socialSecurityNumber;
    this.weight = args?.weight;
    this.raceId = args?.raceId;
    this.ethnicityId = args?.ethnicityId;
    this.maritalStatusId = args?.maritalStatusId;
    this.countyId = args?.countyId;
  }

  public get displayName() {
    return (this.alias || `${this.firstName} ${this.lastName}`).trim();
  }
}
