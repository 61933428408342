import { Type } from 'class-transformer';
import { IIdentifiable } from '../interfaces/identifiable';

export class DocumentRecord implements Partial<IIdentifiable<string>> {
  public id?: string;

  public type?: string;

  public name?: string;

  @Type(() => Date)
  public dateTimeUploaded?: Date;

  public srcUrl?: string;

  constructor(args?: DocumentRecord) {
    this.id = args?.id;
    this.type = args?.type;
    this.name = args?.name;
    this.dateTimeUploaded = args?.dateTimeUploaded;
    this.srcUrl = args?.srcUrl;
  }
}

export class DocumentCollection {
  @Type(() => DocumentRecord)
  public records: DocumentRecord[];

  constructor(args?: DocumentCollection) {
    this.records = args?.records || [];
  }
}
