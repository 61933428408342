






import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Toolbar',
  props: {
    isStuck: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
});
