export const SYMBOL_FORM_OBSERVER = Symbol('Form.Observer');
export const SYMBOL_FORM_OBSERVER_RULES = Symbol('Form.Observer.Rules');
export const SYMBOL_FORM_VALIDATION_MODE = Symbol('Form.ValidationMode');
export const SYMBOL_FORM_DIRECTION = Symbol('Form.Direction');
export const SYMBOL_FORM_VALIDATOR = Symbol('Form.Validator');
export const SYMBOL_FORM_FORM_ITEM_ADD = Symbol('Form.FormItem.Add');
export const SYMBOL_FORM_FORM_ITEM_REMOVE = Symbol('Form.FormItem.Remove');
export const SYMBOL_FORM_PARTIAL_ADD = Symbol('Form.Partial.AddForm');
export const SYMBOL_FORM_PARTIAL_REMOVE = Symbol('Form.Partial.RemoveForm');
export const SYMBOL_FORM_PARTIAL_SELF_MANAGED_ADD = Symbol(
  'Form.Partial.SelfManaged.AddForm'
);
export const SYMBOL_FORM_PARTIAL_SELF_MANAGED_REMOVE = Symbol(
  'Form.Partial.SelfManaged.RemoveForm'
);
