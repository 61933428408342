export class RegisterUserRequest {
  public readonly emailAddress: string;
  public readonly firstName: string;
  public readonly lastName: string;

  constructor(args: RegisterUserRequest) {
    if (!args.emailAddress) throw new Error('emailAddress is required.');
    if (!args.firstName) throw new Error('firstName is required.');
    if (!args.lastName) throw new Error('lastName is required.');

    this.emailAddress = args.emailAddress;
    this.firstName = args.firstName;
    this.lastName = args.lastName;
  }
}
