






import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'WbMenuButtonItem',
  props: {
    as: {
      type: String as PropType<string>,
      required: false,
      default: 'button',
    },
  },
});
