import { Type } from 'class-transformer';
import { isSameDay, startOfDay } from 'date-fns';
import { FormatDateTime, IsFormatDateTime } from '../dates';
import { CaseServiceType } from './services';

export abstract class TransportEvent {
  @IsFormatDateTime
  public readonly dateTime!: FormatDateTime;

  public readonly createdBy!: string;

  constructor(args: TransportEvent) {
    if (!args) return;
    this.dateTime = new FormatDateTime(args.dateTime);
    this.createdBy = args.createdBy;
  }
}

export class CheckInEvent extends TransportEvent {
  public readonly transportedBy!: string;

  public readonly receivedBy!: string;

  public readonly bodybox?: string;

  constructor(args: CheckInEvent) {
    super(args);

    if (!args) return;
    this.transportedBy = args.transportedBy;
    this.receivedBy = args.receivedBy;
    this.bodybox = args.bodybox;
  }
}

export class CheckOutEvent extends TransportEvent {
  public readonly releasedBy!: string;

  public readonly releasedTo!: string;

  public readonly bodybox?: string;

  public readonly receivingOrganization?: string;

  public readonly receivingOrganizationType?: string;

  constructor(args: CheckOutEvent) {
    super(args);

    if (!args) return;
    this.releasedBy = args.releasedBy;
    this.releasedTo = args.releasedTo;
    this.bodybox = args.bodybox;
    this.receivingOrganization = args.receivingOrganization;
    this.receivingOrganizationType = args.receivingOrganizationType;
  }
}

export abstract class TransferEvent extends TransportEvent {
  public readonly receivedBy!: string;

  public readonly transportedBy!: string;

  constructor(args: TransferEvent) {
    super(args);

    if (!args) return;
    this.receivedBy = args.receivedBy;
    this.transportedBy = args.transportedBy;
  }
}

export abstract class InternalTransferEvent extends TransferEvent {
  public readonly originBodybox!: string;

  public readonly destinationBodybox!: string;

  constructor(args: InternalTransferEvent) {
    super(args);

    if (!args) return;
    this.originBodybox = args.originBodybox;
    this.destinationBodybox = args.destinationBodybox;
  }
}

export class AutopsyHistoryEvent extends TransportEvent {
  @IsFormatDateTime
  public readonly dateTimeScheduled?: FormatDateTime;

  @IsFormatDateTime
  public readonly dateTimeCompleted!: FormatDateTime;

  public readonly type!: string;

  public readonly vendor!: string;

  public readonly bodybox?: string;

  constructor(args: AutopsyHistoryEvent) {
    super(args);

    if (!args) return;
    this.dateTimeCompleted = args.dateTimeCompleted;
    this.type = args.type;
    this.vendor = args.vendor;
    this.bodybox = args.bodybox;
  }
}

export class OrganBankNotifiedHistoryEvent extends TransportEvent {
  @IsFormatDateTime
  public readonly dateTimeNotified!: FormatDateTime;

  public readonly description!: string;

  public readonly organBank!: string;

  public readonly bodybox?: string;

  constructor(args: OrganBankNotifiedHistoryEvent) {
    super(args);

    if (!args) return;
    this.dateTimeNotified = args.dateTimeNotified;
    this.description = args.description;
    this.organBank = args.organBank;
    this.bodybox = args.bodybox;
  }
}

export class OrganDonationCompletedHistoryEvent extends TransportEvent {
  @IsFormatDateTime
  public readonly dateTimeCompleted!: FormatDateTime;

  public readonly description!: string;

  public readonly organBank!: string;

  public readonly bodybox?: string;

  constructor(args: OrganDonationCompletedHistoryEvent) {
    super(args);

    if (!args) return;
    this.dateTimeCompleted = args.dateTimeCompleted;
    this.description = args.description;
    this.organBank = args.organBank;
    this.bodybox = args.bodybox;
  }
}

export class FuneralHomeHistoryEvent extends TransportEvent {
  @IsFormatDateTime
  public readonly dateTimeNotified!: FormatDateTime;

  public readonly funeralHome!: string;

  public readonly bodybox?: string;

  public readonly organizationNotifiedBy?: string;

  constructor(args: FuneralHomeHistoryEvent) {
    super(args);

    if (!args) return;
    this.dateTimeNotified = args.dateTimeNotified;
    this.funeralHome = args.funeralHome;
    this.bodybox = args.bodybox;
    this.organizationNotifiedBy = args.organizationNotifiedBy;
  }
}

export class MedicalExaminerHistoryEvent extends TransportEvent {
  @IsFormatDateTime
  public readonly dateTimeNotified!: FormatDateTime;

  public readonly medicalExaminer!: string;

  public readonly bodybox?: string;

  constructor(args: MedicalExaminerHistoryEvent) {
    super(args);

    if (!args) return;
    this.dateTimeNotified = args.dateTimeNotified;
    this.medicalExaminer = args.medicalExaminer;
    this.bodybox = args.bodybox;
  }
}

export abstract class CaseServiceHistoryEvent extends TransportEvent {
  public readonly type!: CaseServiceType;

  public readonly conductingOrganization?: string;

  public readonly details?: string;

  public readonly bodybox?: string;

  constructor(args: CaseServiceHistoryEvent) {
    super(args);
    if (!args) return;
    this.type = args.type;
    this.conductingOrganization = args.conductingOrganization;
    this.details = args.details;
    this.bodybox = args.bodybox;
  }
}

export class CaseServiceNotifiedHistoryEvent extends CaseServiceHistoryEvent {
  @IsFormatDateTime
  public readonly dateTimeNotified!: FormatDateTime;

  constructor(args: CaseServiceNotifiedHistoryEvent) {
    super(args);
    if (!args) return;
    this.dateTimeNotified = args.dateTimeNotified;
  }
}

export class CaseServiceCompletedHistoryEvent extends CaseServiceHistoryEvent {
  @IsFormatDateTime
  public readonly dateTimeCompleted!: FormatDateTime;

  constructor(args: CaseServiceCompletedHistoryEvent) {
    super(args);
    if (!args) return;
    this.dateTimeCompleted = args.dateTimeCompleted;
  }
}

export class TransportHistory {
  @Type(() => CheckInEvent)
  public readonly checkInEvents: Readonly<CheckInEvent[]>;

  @Type(() => CheckOutEvent)
  public readonly checkOutEvents: Readonly<CheckOutEvent[]>;

  @Type(() => InternalTransferEvent)
  public readonly internalTransferEvents: Readonly<InternalTransferEvent[]>;

  @Type(() => AutopsyHistoryEvent)
  public readonly autopsyHistoryEvents: Readonly<AutopsyHistoryEvent[]>;

  @Type(() => OrganBankNotifiedHistoryEvent)
  public readonly organDonationHistoryEvents: Readonly<
    OrganBankNotifiedHistoryEvent[]
  >;

  @Type(() => OrganDonationCompletedHistoryEvent)
  public readonly organDonationCompletedHistoryEvents: Readonly<
    OrganDonationCompletedHistoryEvent[]
  >;

  @Type(() => FuneralHomeHistoryEvent)
  public readonly funeralHomeHistoryEvents: Readonly<FuneralHomeHistoryEvent[]>;

  @Type(() => MedicalExaminerHistoryEvent)
  public readonly medicalExaminerHistoryEvents: Readonly<
    MedicalExaminerHistoryEvent[]
  >;

  @Type(() => CaseServiceNotifiedHistoryEvent)
  public readonly caseServiceNotifiedHistoryEvents: Readonly<
    CaseServiceNotifiedHistoryEvent[]
  >;

  @Type(() => CaseServiceCompletedHistoryEvent)
  public readonly caseServiceCompletedHistoryEvents: Readonly<
    CaseServiceCompletedHistoryEvent[]
  >;

  constructor(args?: Partial<TransportHistory>) {
    this.checkInEvents = args?.checkInEvents || [];
    this.checkOutEvents = args?.checkOutEvents || [];
    this.internalTransferEvents = args?.internalTransferEvents || [];
    this.autopsyHistoryEvents = args?.autopsyHistoryEvents || [];
    this.organDonationHistoryEvents = args?.organDonationHistoryEvents || [];
    this.organDonationCompletedHistoryEvents =
      args?.organDonationCompletedHistoryEvents || [];
    this.funeralHomeHistoryEvents = args?.funeralHomeHistoryEvents || [];
    this.medicalExaminerHistoryEvents =
      args?.medicalExaminerHistoryEvents || [];
    this.caseServiceNotifiedHistoryEvents =
      args?.caseServiceNotifiedHistoryEvents || [];
    this.caseServiceCompletedHistoryEvents =
      args?.caseServiceCompletedHistoryEvents || [];
  }

  /** A collection of all transport only events (check-ins/check outs) ordered by date **ascending**. */
  public get transportEvents() {
    const ordered: TransportEvent[] = [
      ...this.checkOutEvents,
      ...this.checkInEvents,
      ...this.internalTransferEvents,
    ].sort((a, b) => (a.dateTime < b.dateTime ? -1 : 1));

    return ordered;
  }

  /** A collection of all history ordered by date **ascending**. */
  public get orderedEvents() {
    const ordered: TransportEvent[] = [
      ...this.checkOutEvents,
      ...this.checkInEvents,
      ...this.internalTransferEvents,
      ...this.autopsyHistoryEvents,
      ...this.organDonationHistoryEvents,
      ...this.organDonationCompletedHistoryEvents,
      ...this.funeralHomeHistoryEvents,
      ...this.medicalExaminerHistoryEvents,
      ...this.caseServiceNotifiedHistoryEvents,
      ...this.caseServiceCompletedHistoryEvents,
    ].sort((a, b) => (getSortDate(a) < getSortDate(b) ? -1 : 1));

    // Check-in events will be sorted after events that don't capture a time
    // Events that fall on the same date as a check-in but have no time should be sorted after the check-in

    const checkInEvents = ordered.filter(
      (event) => event instanceof CheckInEvent
    );

    for (const checkInEvent of checkInEvents) {
      const historyEvents = ordered.filter(
        (event) =>
          isEventWithNoTimePart(event) &&
          isSameDay(checkInEvent.dateTime, event.dateTime)
      );

      const checkInEventIndex = ordered.indexOf(checkInEvent);

      for (const event of historyEvents) {
        const eventIndex = ordered.indexOf(event);
        ordered.splice(eventIndex, 1);
        ordered.splice(checkInEventIndex, 0, event);
      }
    }

    return ordered;
  }
}

function getSortDate(event: TransportEvent): FormatDateTime {
  if (
    event instanceof AutopsyHistoryEvent ||
    event instanceof OrganDonationCompletedHistoryEvent ||
    event instanceof CaseServiceCompletedHistoryEvent
  )
    return event.dateTimeCompleted;
  if (
    event instanceof OrganBankNotifiedHistoryEvent ||
    event instanceof CaseServiceNotifiedHistoryEvent
  )
    return event.dateTimeNotified;
  if (event instanceof FuneralHomeHistoryEvent) return event.dateTimeNotified;
  if (event instanceof MedicalExaminerHistoryEvent)
    return event.dateTimeNotified;
  return event.dateTime;
}

function isEventWithNoTimePart(event: TransportEvent) {
  const isHistoryEvent =
    event instanceof AutopsyHistoryEvent ||
    event instanceof OrganBankNotifiedHistoryEvent ||
    event instanceof FuneralHomeHistoryEvent;

  const date = getSortDate(event);
  const startOfDayDate = startOfDay(date);
  const hasNoTimePart = +date === +startOfDayDate;

  return isHistoryEvent && hasNoTimePart;
}
