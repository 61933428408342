import moment from 'moment';
import { extend } from 'vee-validate';
import { isAfter, isBefore, isSameDay, parse } from 'date-fns';
import { FormatDate } from '~/app/models';
import { DateOnly } from '~/app/models/dates/date-only';

function isDate(date: any): date is Date {
  return date instanceof Date && !isNaN(date.getTime());
}

function isFutureDate(date: Date | DateOnly, inclusive?: boolean) {
  const d = date instanceof Date ? date : date.toDate();
  return inclusive
    ? isAfter(d, new Date()) || isSameDay(d, new Date())
    : isAfter(d, new Date());
}

function isPastDate(date: Date | DateOnly, inclusive?: boolean) {
  const d = date instanceof Date ? date : date.toDate();
  return inclusive
    ? isBefore(d, new Date()) || isSameDay(d, new Date())
    : isBefore(d, new Date());
}

extend('date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    if (typeof value !== 'string') {
      return isDate(value);
    }

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    try {
      const date = parse(value, format, new Date());

      // right now theres no strict parse so any number for a date will be accepted, validate length as a temp fix
      return !isNaN(date.getTime()) && value.length === format.length;
    } catch (error) {
      return false;
    }
  },
  message: (_field, args) => {
    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;
    return `Invalid date format (${format}).`;
  },
});

extend('is_future_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isFutureDate(value.toDate());
    }

    if (isDate(value) || value instanceof DateOnly) {
      return isFutureDate(value);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isFutureDate(date);
    }

    return false;
  },
  message: (field) => `${field} must be a in the future.`,
});

extend('is_future_or_same_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isFutureDate(value.toDate(), true);
    }

    if (isDate(value) || value instanceof DateOnly) {
      return isFutureDate(value, true);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isFutureDate(date, true);
    }

    return false;
  },
  message: (field) => `${field} must be a in the future.`,
});

extend('is_past_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isPastDate(value.toDate());
    }

    if (isDate(value) || value instanceof DateOnly) {
      return isPastDate(value);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isPastDate(date);
    }

    return false;
  },
  message: (field) => `${field} must be in the past.`,
});

extend('is_past_or_same_date', {
  params: ['format'],
  validate(value, args) {
    if (!value) return true;

    const format =
      'format' in args && args.format ? args.format : FormatDate.formatToken;

    if (moment.isMoment(value)) {
      return isPastDate(value.toDate(), true);
    }

    if (isDate(value) || value instanceof DateOnly) {
      return isPastDate(value, true);
    }

    if (typeof value === 'string') {
      if (!format) console.warn('Invalid or no date format provided');
      const date = parse(value, format, new Date());
      return isPastDate(date, true);
    }

    return false;
  },
  message: (field) => `${field} must be in the past.`,
});
