import type { TenantConfiguration } from './configuration';

export class Tenant {
  public readonly id: string;
  public readonly name: string;
  public readonly configuration: TenantConfiguration;
  public readonly activated: boolean;

  constructor(args?: Tenant);
  constructor(args: Tenant) {
    this.id = args?.id;
    this.name = args?.name;
    this.configuration = args?.configuration;
    this.activated = args?.activated;
  }
}
