/* eslint-disable camelcase */
export const COLOR_RED = {
  100: '#fee2e2',
  200: '#fecaca',
  300: '#fca5a5',
  400: '#f87171',
  500: '#ef4444',
  600: '#dc2626',
  700: '#b91c1c',
  800: '#991b1b',
  900: '#7f1d1d',
};

export const COLOR_PINK = {
  100: '#fce7f3',
  200: '#fbcfe8',
  300: '#f9a8d4',
  400: '#f472b6',
  500: '#ec4899',
  600: '#db2777',
  700: '#be185d',
  800: '#9d174d',
  900: '#831843',
};

export const COLOR_ORANGE = {
  100: '#ffebd4',
  200: '#ffd3a8',
  300: '#ffb371',
  400: '#ff8938',
  500: '#fe6711',
  600: '#ef4c07',
  700: '#c63608',
  800: '#9d2c0f',
  900: '#7e2710',
};

export const COLOR_YELLOW = {
  100: '#fff8c2',
  200: '#fff089',
  300: '#ffdf45',
  400: '#fccb13',
  500: '#ecb206',
  600: '#cc8902',
  700: '#a26106',
  800: '#864c0d',
  900: '#723e11',
};

export const COLOR_GREEN = {
  100: '#e8faf2',
  200: '#c6f3dd',
  300: '#a4ebc9',
  400: '#60dca1',
  500: '#1ccd78',
  600: '#19b96c',
  700: '#159a5a',
  800: '#117b48',
  900: '#0e643b',
};

export const COLOR_BLUE = {
  100: '#dbeafe',
  200: '#bfdbfe',
  300: '#93c5fd',
  400: '#60a5fa',
  500: '#3b82f6',
  600: '#2563eb',
  700: '#1d4ed8',
  800: '#1e40af',
  900: '#1e3a8a',
};

export const COLOR_PURPLE = {
  100: '#ede9fe',
  200: '#ddd6fe',
  300: '#c4b5fd',
  400: '#a78bfa',
  500: '#8b5cf6',
  600: '#7c3aed',
  700: '#6d28d9',
  800: '#5b21b6',
  900: '#4c1d95',
};

export const COLOR_BLACK = {
  100: '#f3f4f6',
  200: '#e5e7eb',
  300: '#d1d5db',
  400: '#9ca3af',
  500: '#6b7280',
  600: '#4b5563',
  700: '#374151',
  800: '#1f2937',
  900: '#111827',
};

const getColorCodes = <TColors extends { [level: number]: string }>(
  colors: TColors,
  levels: Array<keyof TColors>
) => {
  return Object.entries(colors)
    .filter(([level]) => levels.includes(parseInt(level, 10) as keyof TColors))
    .sort(
      ([aLevel], [bLevel]) => levels.indexOf(+aLevel) - levels.indexOf(+bLevel)
    )
    .map(([, color]) => color);
};

export const DEFAULT_SWATCHES = [
  getColorCodes(COLOR_RED, [300, 400, 500, 600]),
  getColorCodes(COLOR_PINK, [300, 400, 500, 600]),
  getColorCodes(COLOR_ORANGE, [300, 400, 500, 600]),
  getColorCodes(COLOR_YELLOW, [300, 400, 500, 600]),
  getColorCodes(COLOR_GREEN, [300, 400, 500, 600]),
  getColorCodes(COLOR_BLUE, [300, 400, 500, 600]),
  getColorCodes(COLOR_PURPLE, [300, 400, 500, 600]),
  getColorCodes(COLOR_BLACK, [300, 400, 500, 600]),
] as const;

// export const DEFAULT_SWATCHES = [
//   ['#991b1b', '#dc2626', '#ef4444', '#f87171'],
//   ['#9d174d', '#db2777', '#ec4899', '#f472b6'],
//   ['#9d2c0f', '#ef4c07', '#fe6711', '#ffb371'],
//   ['#92400e', '#b45309', '#ecb206', '#ffdc2e'],
//   ['#117b48', '#19b96c', '#1ccd78', '#60dca1'],
//   ['#1e40af', '#2563eb', '#3b82f6', '#60a5fa'],
//   ['#5b21b6', '#7c3aed', '#8b5cf6', '#a78bfa'],
//   ['#1f2937', '#4b5563', '#6b7280', '#9ca3af'],
// ] as const;

export const DEfAULT_COLOR = '#606884' as const;
