import {
  Address,
  OrganizationDictionaryItem,
  AssociateOrganizationType,
} from '~/app/models';

export class AddOrganizationDictionaryItemRequest {
  public readonly type: AssociateOrganizationType;
  public readonly name: string;
  public readonly address?: Readonly<Address>;
  public readonly contactPhoneNumber?: string;
  public readonly contactFaxNumber?: string;
  public readonly contactEmailAddress?: string;

  constructor(args: OrganizationDictionaryItem) {
    if (!args.type) {
      throw new Error('AssociateOrganizationType is required');
    }

    if (!args.name) {
      throw new Error('name is required');
    }

    this.type = args.type;
    this.name = args.name;
    this.address = args?.address ? new Address(args.address) : undefined;
    this.contactPhoneNumber = args?.contactPhoneNumber;
    this.contactFaxNumber = args?.contactFaxNumber;
    this.contactEmailAddress = args?.contactEmailAddress;
  }
}

export class EditOrganizationDictionaryItemRequest {
  public readonly id: string;
  public readonly type: AssociateOrganizationType;
  public readonly name: string;
  public readonly address?: Readonly<Address>;
  public readonly contactPhoneNumber?: string;
  public readonly contactFaxNumber?: string;
  public readonly contactEmailAddress?: string;

  constructor(args: EditOrganizationDictionaryItemRequest) {
    if (!args.id) {
      throw new Error('id is required');
    }

    if (!args.type) {
      throw new Error('AssociateOrganizationType is required');
    }

    if (!args.name) {
      throw new Error('name is required');
    }

    this.id = args.id;
    this.type = args.type;
    this.name = args.name;
    this.address = args?.address ? new Address(args.address) : undefined;
    this.contactPhoneNumber = args.contactPhoneNumber;
    this.contactFaxNumber = args.contactFaxNumber;
    this.contactEmailAddress = args.contactEmailAddress;
  }
}
