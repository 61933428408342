



























































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import { useAuthProvider } from '~/app/core/auth';
import {
  useAbility,
  AppAbilitySubject,
  BodyboxAbilityAction,
} from '~/app/core/casl';
import { DictionaryAbilityAction } from '~/app/core/casl/constants';
import { useStoreModule } from '~/app/composables/use-store-module';
import { moduleName as accessStoreName, IAccessState } from '~/store/access';
import { WbMenuButton } from '~/app/components/ui/menu-button';
import { moduleName as tenantStoreName, ITenantState } from '~/store/tenant';
import { SettingsRoute } from '~mod:settings/routes';

export default defineComponent({
  name: 'TheWelcomeMenu',
  setup(_props, _context) {
    const store = useStoreModule<IAccessState>(accessStoreName);
    const userName = computed(() => store.state.user?.firstName ?? 'User');

    const tenantModule = useStoreModule<ITenantState>(tenantStoreName);
    const tenantName = computed(() => tenantModule.state.tenant?.name);

    const auth = useAuthProvider();
    const logout = () => auth.logoutAsync();

    const ability = useAbility();

    // prettier-ignore
    const canViewOrganization = computed(() =>
      // bodybox
      ability.value.can(BodyboxAbilityAction.create, AppAbilitySubject.bodybox) ||
      ability.value.can(BodyboxAbilityAction.read, AppAbilitySubject.bodybox) ||
      ability.value.can(BodyboxAbilityAction.update, AppAbilitySubject.bodybox) ||
      ability.value.can(BodyboxAbilityAction.delete, AppAbilitySubject.bodybox) ||
      // dictionaries
      ability.value.can(DictionaryAbilityAction.create, AppAbilitySubject.dictionary) ||
      ability.value.can(DictionaryAbilityAction.read, AppAbilitySubject.dictionary) ||
      ability.value.can(DictionaryAbilityAction.update, AppAbilitySubject.dictionary) ||
      ability.value.can(DictionaryAbilityAction.delete, AppAbilitySubject.dictionary) ||
      // users
      ability.value.can(DictionaryAbilityAction.create, AppAbilitySubject.user) ||
      ability.value.can(DictionaryAbilityAction.read, AppAbilitySubject.user) ||
      ability.value.can(DictionaryAbilityAction.update, AppAbilitySubject.user) ||
      ability.value.can(DictionaryAbilityAction.delete, AppAbilitySubject.user)
    );

    const menu = ref<InstanceType<typeof WbMenuButton>>();
    const onLinkClick = () => {
      (menu.value?.hide as Function)();
    };

    return {
      menu,
      userName,
      tenantName,
      onLinkClick,
      logout,
      canViewOrganization,
      SettingsRoute,
    };
  },
});
