import { wrapProperty } from '@nuxtjs/composition-api';

export { Can } from './components/can';
export { createAbilitiesPlugin } from './plugin';
export { injectAbility, provideAbility, ABILITY_TOKEN } from './inject-ability';
export {
  AppAbilitySubject,
  BodyboxAbilityAction,
  CaseAbilityAction,
  DictionaryAbilityAction,
  UserAbilityAction,
} from './constants';

export type { CanProps } from './components/can';
export type { AbilityPluginOptions } from './plugin';

export const useAbility = wrapProperty('$ability');
