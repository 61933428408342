import { FormatDateTime, IsFormatDateTime } from '../dates';

export class FuneralHome {
  public funeralHomeId?: string;

  @IsFormatDateTime
  public dateNotified?: FormatDateTime;

  public authorizedBy?: string;

  public organizationNotifiedBy?: string;

  constructor(args?: FuneralHome) {
    this.funeralHomeId = args?.funeralHomeId;
    this.dateNotified = args?.dateNotified
      ? new FormatDateTime(args?.dateNotified)
      : undefined;
    this.authorizedBy = args?.authorizedBy;
    this.organizationNotifiedBy = args?.organizationNotifiedBy;
  }
}
