import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';
import { OriginLocationType } from '~/app/models';

export class AddOriginLocationRequest extends AddDictionaryItemRequest {
  public readonly type: OriginLocationType;
  public readonly externalId?: string;

  constructor(args: AddOriginLocationRequest) {
    super(args);
    this.type = args.type;
    this.externalId = args.externalId;
  }
}

export class EditOriginLocationRequest extends EditDictionaryItemRequest {
  public readonly type: OriginLocationType;
  public readonly externalId?: string;

  constructor(args: EditOriginLocationRequest) {
    super(args);
    this.type = args.type;
    this.externalId = args.externalId;
  }
}
