







import {
  computed,
  defineComponent,
  PropType,
  provide,
  Ref,
  ref,
  toRefs,
  watch,
} from '@nuxtjs/composition-api';
import { templateRef } from '@vueuse/core';
import { SearchConfig } from 'gridjs/dist/src/view/plugin/search/search';
import { SortConfig } from 'gridjs/dist/src/view/plugin/sort/sort';
import { PaginationConfig } from 'ant-design-vue/types/list/list';
import { TColumn, useGrid } from './use-grid';
import { SYMBOL_GRID_INSTANCE_ID, SYMBOL_GRID_ADD_COLUMN } from './symbols';
import { genId } from '~/app/utils/id';

export default defineComponent({
  name: 'Grid',
  props: {
    data: {
      type: Array as PropType<any[]>,
      required: true,
    },
    columns: {
      type: Array as PropType<TColumn[]>,
      required: false,
      default: () => [],
    },
    /** To set the height of the table. */
    height: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    /** To calculate the width of each column automatically */
    autoWidth: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: true,
    },
    /** Fixes the header to the top of the table */
    resizable: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    /** Fixes the header to the top of the table */
    fixedHeader: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    /** To enable or disable the global search plugin */
    search: {
      type: [Boolean, Object] as PropType<boolean | SearchConfig | undefined>,
      required: false,
      default: undefined,
    },
    /** To enable or disable the sort plugin */
    sort: {
      type: [Boolean, Object] as PropType<boolean | SortConfig | undefined>,
      required: false,
      default: undefined,
    },
    /** To enable or disable the pagination plugin */
    pagination: {
      type: [Boolean, Object] as PropType<
        boolean | PaginationConfig | undefined
      >,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const { data, columns: columnsProp } = toRefs(props);

    let isReady = false;
    let deferUpdate = false;

    const componentColumns: Ref<TColumn[]> = ref([]);
    const columns = computed(() => {
      return [...columnsProp.value, ...componentColumns.value];
    });

    const instanceId = genId();
    provide(SYMBOL_GRID_INSTANCE_ID, instanceId);
    provide(SYMBOL_GRID_ADD_COLUMN, (config: TColumn) => {
      const index = componentColumns.value.findIndex(
        (column) => column.id === config.id || column.name === config.name
      );

      if (index >= 0) {
        componentColumns.value.splice(index, 1, config);
      } else {
        componentColumns.value.push(config);
      }

      if (isReady) {
        grid.forceRender();
      } else {
        deferUpdate = true;
      }
    });

    const {
      height,
      autoWidth,
      resizable,
      fixedHeader,
      search,
      sort,
      pagination,
    } = toRefs(props);

    const updateGridConfig = () => {
      grid.updateConfig({
        height: height.value,
        autoWidth: autoWidth.value,
        resizable: resizable.value,
        fixedHeader: fixedHeader.value,
        search:
          typeof search.value === 'boolean'
            ? { enabled: search.value }
            : (search.value as any),
        sort:
          typeof sort.value === 'boolean'
            ? { enabled: sort.value }
            : (sort.value as any),
        pagination:
          typeof pagination.value === 'boolean'
            ? { enabled: pagination.value }
            : (pagination.value as any),
      });

      if (isReady) {
        grid.forceRender();
      } else {
        deferUpdate = true;
      }
    };

    const gridRef = templateRef('gridRef');
    const { grid } = useGrid(gridRef, {
      instanceId,
      rows: data,
      columns,
      options: {
        height: height.value,
        autoWidth: autoWidth.value,
        resizable: resizable.value,
        fixedHeader: fixedHeader.value,
        search:
          typeof search.value === 'boolean'
            ? { enabled: search.value }
            : search.value,
        sort:
          typeof sort.value === 'boolean'
            ? { enabled: sort.value }
            : (sort.value as any),
        pagination:
          typeof pagination.value === 'boolean'
            ? { enabled: pagination.value }
            : (pagination.value as any),
        className: {
          container: 'wb-table-container',
          table: 'wb-table w-full',
          th: 'wb-th',
          tr: 'wb-tr',
          td: 'wb-td bordered',
          footer: 'wb-footer',
        },
      },
    });

    watch(
      [height, autoWidth, resizable, fixedHeader, search, sort, pagination],
      () => {
        updateGridConfig();
      },
      { deep: true }
    );

    const onReady = () => {
      grid.off('ready', onReady);

      isReady = true;

      if (deferUpdate) {
        grid.forceRender();
      }
    };

    grid.on('ready', onReady);

    return { grid };
  },
});
