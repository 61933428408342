


























import { defineComponent, reactive, useRoute } from '@nuxtjs/composition-api';
import { useAPI } from '~/app/core/api';
import { Case } from '~/app/models';
import { useStore as useCaseStore } from '~mod:case-details/composables/use-store';
import {
  TheCaseCheckInModal,
  ModalEvent as CheckInModalEvent,
} from '~mod:shared/components/organisms/case-check-in-modal';
import {
  TheCaseTransferModal,
  ModalEvent as TransferModalEvent,
} from '~mod:shared/components/organisms/case-transfer-modal';
import {
  TheCaseCheckoutModal,
  ModalEvent as CheckOutModalEvent,
} from '~mod:shared/components/organisms/case-checkout-modal';

// TODO: These are currently unused, implement these
export enum TransportProviderEvent {
  /** A case was selected to check-in. */
  checkInStart = 'checkIn:start',
  /** A case has been checked in. */
  checkInComplete = 'checkIn:complete',
  /** A case was selected to transfer. */
  transferStart = 'transfer:start',
  /** A case has been transferred. */
  transferComplete = 'transfer:complete',
  /** A case was selected to check-out. */
  checkOutStart = 'checkOut:start',
  /** A case has been checked out. */
  checkOutComplete = 'checkOut:complete',
}

export enum TransportType {
  CheckIn = 'checkIn',
  Transfer = 'transfer',
  CheckOut = 'checkOut',
}

export interface TransportWorkflowBehaviors {
  identityVerified: boolean;
  onComplete?: () => void;
}

interface TransportWorkflow {
  active: boolean;
  onComplete?: (caseId: string) => any;
}

// TODO: This should be the central authority for all transport workflows

export default defineComponent({
  name: 'TheTransportModalProvider',
  components: {
    TheCaseCheckInModal,
    TheCaseCheckoutModal,
    TheCaseTransferModal,
  },
  emits: {
    [TransportProviderEvent.checkInStart]: (caseId: string) =>
      typeof caseId === 'string',
    [TransportProviderEvent.checkInComplete]: (caseId: string) =>
      typeof caseId === 'string',
    [TransportProviderEvent.checkOutStart]: (caseId: string) =>
      typeof caseId === 'string',
    [TransportProviderEvent.checkOutComplete]: (caseId: string) =>
      typeof caseId === 'string',
    [TransportProviderEvent.transferStart]: (caseId: string) =>
      typeof caseId === 'string',
    [TransportProviderEvent.transferComplete]: (caseId: string) =>
      typeof caseId === 'string',
  },
  setup(_, { emit }) {
    const api = useAPI();
    const { state, commit, Mutation } = useCaseStore();

    let currentSessionCase: Case | null = null;

    const route = useRoute();
    function isOnCaseDetailsPage() {
      if (!route.value.name) return false;
      return ['case-create', 'case-id'].includes(route.value.name);
    }

    async function setActiveCaseAsync(caseId: string) {
      if (isOnCaseDetailsPage()) {
        // Store the current case as it could be another case.
        currentSessionCase = state.case;
      }

      const details = await api.cases.getAsync(caseId);
      commit(Mutation.setCase, details);
    }

    const workflows = reactive({
      [TransportType.CheckIn]: {
        active: false,
        completed: undefined,
      } as TransportWorkflow,
      [TransportType.CheckOut]: {
        active: false,
        completed: undefined,
      } as TransportWorkflow,
      [TransportType.Transfer]: {
        active: false,
        completed: undefined,
      } as TransportWorkflow,
    });

    async function startWorkflow(
      type: TransportType,
      caseId: string,
      behaviors?: TransportWorkflowBehaviors
    ) {
      const workflow = workflows[type];
      workflow.active = true;
      workflow.onComplete = behaviors?.onComplete;
      emit(`${type}:start` as any, caseId);

      behaviors?.identityVerified && commit(Mutation.verifyIdentity);
      await setActiveCaseAsync(caseId);
    }

    function completeWorkflow(type: TransportType, caseId: string) {
      const workflow = workflows[type];
      workflow.active = false;
      workflow.onComplete?.(caseId);
      emit(`${type}:complete` as any, caseId);
    }

    function resetOnClose(visible: boolean) {
      if (visible) return;

      // If we have a stored case from the case details page, restore it
      if (currentSessionCase) {
        commit(Mutation.setCase, currentSessionCase);
        currentSessionCase = null;
      }

      // If on the case details page we just want to reset the identity verification while leaving the case intact
      isOnCaseDetailsPage()
        ? commit(Mutation.verifyIdentity, false)
        : commit(Mutation.reset);
    }

    return {
      workflows,
      startWorkflow,
      completeWorkflow,
      resetOnClose,
      TransportType,

      CheckInModalEvent,
      CheckOutModalEvent,
      TransferModalEvent,
    };
  },
});
