import { DictionaryItem, DictionaryType } from '~/app/models';

export type IDictionariesState = {
  [key in DictionaryType]: DictionaryItem[] | null;
};

const state = (): IDictionariesState => ({
  autopsyVendor: null,
  caseServiceRequirement: null,
  caseServiceStatus: null,
  caseType: null,
  causeOfDeath: null,
  county: null,
  ethnicity: null,
  funeralHome: null,
  gender: null,
  infectiousDiseaseType: null,
  maritalStatus: null,
  medicalExaminer: null,
  nextOfKinType: null,
  originLocation: null,
  personalBelongingLocation: null,
  physicianType: null,
  race: null,
  serviceLine: null,
  tag: null,
  tissueBank: null,
});

export { state };
export default state;
