import {
  watch,
  WatchCallback,
  WatchOptions,
  WatchSource,
} from '@nuxtjs/composition-api';

/** Executes a callback once, after the watch source evaluates to `true`. */
export function useTripSwitch(
  source: Readonly<WatchSource<boolean>>,
  cb: WatchCallback<
    Readonly<WatchSource<boolean>>,
    Readonly<WatchSource<boolean>> | undefined
  >,
  options?: WatchOptions<boolean>
) {
  const unwatch = watch(
    source,
    (...args) => {
      const [newValue] = args;
      const tripped = typeof newValue === 'boolean' ? newValue : false;
      tripped && unwatch();
      return cb(...args);
    },
    options
  );
}
