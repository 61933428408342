import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';
import { CaseServiceType } from '~/app/models';

export class CreateCaseServiceStatusRequest extends AddDictionaryItemRequest {
  public readonly serviceType: CaseServiceType;
  public readonly triggersCaseHold: boolean;
  public readonly indicatesCompletion: boolean;

  constructor(args: CreateCaseServiceStatusRequest) {
    super(args);
    this.serviceType = args.serviceType;
    this.triggersCaseHold = args.triggersCaseHold;
    this.indicatesCompletion = args.indicatesCompletion;
  }
}

export class EditCaseServiceStatusRequest extends EditDictionaryItemRequest {
  public readonly serviceType: CaseServiceType;
  public readonly triggersCaseHold: boolean;
  public readonly indicatesCompletion: boolean;

  constructor(args: EditCaseServiceStatusRequest) {
    super(args);
    this.serviceType = args.serviceType;
    this.triggersCaseHold = args.triggersCaseHold;
    this.indicatesCompletion = args.indicatesCompletion;
  }
}
