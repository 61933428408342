export class AddDictionaryItemRequest {
  public readonly name: string;
  public readonly externalId?: string;

  constructor(args: AddDictionaryItemRequest) {
    this.name = args.name;
    this.externalId = args.externalId;
  }
}

export class EditDictionaryItemRequest {
  public readonly id: string;
  public readonly name: string;
  public readonly externalId?: string;

  constructor(args: EditDictionaryItemRequest) {
    this.id = args.id;
    this.name = args.name;
    this.externalId = args.externalId;
  }
}

export class DeleteDictionaryItemRequest {
  public readonly entryId: string;

  constructor(args: DeleteDictionaryItemRequest) {
    this.entryId = args.entryId;
  }
}
