



















































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { useVModel } from '@vueuse/core';
import FilterPill from './filter-pill.vue';
import FilterToggle from './filter-toggle.vue';

export enum FilterEvent {
  update = 'update:selected',
}

export default defineComponent({
  name: 'FilterMenu',
  components: {
    FilterPill,
    FilterToggle,
  },
  model: {
    prop: 'selected',
    event: FilterEvent.update,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Array as PropType<string[]>,
      required: true,
    },
    options: {
      type: Array as PropType<
        { name: 'string'; value: any; class?: string; style?: string }[]
      >,
      required: true,
    },
  },
  emits: {
    [FilterEvent.update]: (selected: string[]) => Array.isArray(selected),
  },
  setup(props) {
    const active = ref(false);

    const model = useVModel(props, 'selected');
    const update = ref<any[]>([]);
    // watch(model, () => (update.value = model.value));

    function apply() {
      model.value.splice(0, model.value.length, ...update.value);
    }

    function reset() {
      update.value.splice(0, update.value.length);
      apply();
    }

    return {
      active,
      model,
      update,
      apply,
      reset,
    };
  },
});
