import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import type { RerouteOnRedirectParams } from './types';
import { isRoutePublic } from '~/app/utils/route';

export const AuthMiddleware = defineNuxtMiddleware(
  async ({ $auth, error, redirect, route }) => {
    const {
      redirectState,
      error: loginError,
    } = await $auth.setupAuthAsync<RerouteOnRedirectParams>();

    if (loginError) {
      const invalidSessionErrors = [
        'Invalid state',
        'Invalid authorization code',
      ];
      if (invalidSessionErrors.includes(loginError)) {
        // We have bad session state here, send them home with a hard refresh to restart the session
        window.location.replace('/');
        return error({ message: 'Refreshing session' });
      }

      return error({ message: loginError });
    }

    if (route.meta) {
      route.meta.authState = redirectState;
    }

    // Check if the target page is public and skip authorization if so
    if (isRoutePublic(route)) return;

    const authenticate = async () => {
      if ($auth.isAuthenticated.value) {
        if (redirectState && 'path' in redirectState)
          return redirect(redirectState.path);

        return;
      }

      let rerouteParams: RerouteOnRedirectParams | undefined;
      if (route.path !== '/') rerouteParams = { path: route.path };

      await $auth.loginAsync(rerouteParams);

      // JavaScript redirect frameworks (window.location.assign/replace, etc.) are asynchronous in nature but are not awaitable.
      // Therefore, there is potential for this middleware to continue on (briefly) while a redirect is executing.
      // To guard against this, we will call a Nuxt-defined error to stop program execution.
      // When a 'ERR_REDIRECT' error is thrown, a blank page is displayed instead of the traditional Nuxt error page.

      // Nuxt Implementation: https://github.com/nuxt/nuxt.js/blob/cb22d841fe16dbe3778b83e1254de41911bd74b7/packages/vue-app/template/utils.js#L189
      // Nuxt Render: https://github.com/nuxt/nuxt.js/blob/cb22d841fe16dbe3778b83e1254de41911bd74b7/packages/vue-app/template/client.js#L433

      throw new Error('ERR_REDIRECT');
    };

    return authenticate();
  }
);
