import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { ZebraPrintService } from '~/app/core/services/barcoding/printing';
// import { ITenantState } from '~/store/tenant';

export default defineNuxtPlugin((_, inject) => {
  // const { tenant } = store.state.tenant as ITenantState;
  // if (!tenant!.configuration.features.barcoding.printing.enabled) return;

  const service = new ZebraPrintService({ https: false });
  // @ts-expect-error
  window.browserPrint = service;

  inject('browserPrint', service);
});
