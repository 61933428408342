import { DateOnly } from '~/app/models';

function isNil(value: any) {
  return value === null || value === undefined;
}

export function isDate(value: any): value is Date {
  return value instanceof Date && !isNaN(value.getTime());
}

export function equals(a?: Date | DateOnly, b?: Date | DateOnly) {
  // both values are null or undefined
  if (isNil(a) && isNil(b)) return true;

  // "a" and "b" are the same `DateOnly`
  if (a instanceof DateOnly && b instanceof DateOnly && a.equals(b))
    return true;

  // "a" and "b" are the same `Date`
  if (isDate(a) && isDate(b) && a.valueOf() === b.valueOf()) return true;

  return false;
}

export function toDate(date?: Date | DateOnly): Date | undefined {
  return date instanceof DateOnly ? date?.toDate() : date;
}

export function isInvalidDate(date?: Date | DateOnly): boolean {
  return toDate(date)?.toString() === 'Invalid Date';
}
