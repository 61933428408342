import { Ability, AnyAbility, SubjectType } from '@casl/ability';
import { ref, unref } from '@vue/composition-api';

export function reactiveAbility<TAbility extends AnyAbility = Ability>(
  ability: TAbility
) {
  // eslint-disable-next-line no-prototype-builtins
  if (ability.hasOwnProperty('possibleRulesFor')) {
    return ability;
  }

  const watcher = ref(true);
  ability.on('updated', () => {
    watcher.value = !watcher.value;
  });

  const possibleRulesFor = ability.possibleRulesFor.bind(ability);
  ability.possibleRulesFor = (action: string, subject: SubjectType) => {
    watcher.value = unref(watcher);
    return possibleRulesFor(action, subject);
  };

  return ability;
}
