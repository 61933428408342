






import {
  computed,
  defineComponent,
  PropType,
  provide,
  ref,
  toRefs,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Menu',
  props: {
    ordered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    type: {
      type: String as PropType<'fixed' | 'collapsible'>,
      default: 'fixed',
    },
  },
  setup(props) {
    // #region Menu level + spacing

    const calculateMenuLevelPadding = (level: number) => {
      if (level === 1) return 35;
      return level * 30;
    };
    provide('wb-menu.calculatePadding', calculateMenuLevelPadding);
    provide('wb-menu.level', 1);

    // #endregion

    // #region selection state

    const selectedKey = ref<string>();
    const selectKey = (key: string) => (selectedKey.value = key);
    provide('wb-menu.selectKey', selectKey);
    provide('wb-menu.selectedKey', selectedKey);

    const menuKeys = ref<string[]>([]);
    const addMenuKey = (key: string) => menuKeys.value.push(key);
    provide('wb-menu.menuKeys', menuKeys);
    provide('wb-menu.addMenuKey', addMenuKey);

    // #endregion

    // #region collapsible state

    const { type } = toRefs(props);
    const collapsable = computed(() => type.value === 'collapsible');
    provide('wb-menu.collapsible', collapsable);

    // #endregion

    return { selectedKey, menuKeys };
  },
});
