


























import { defineComponent, PropType } from '@nuxtjs/composition-api';

type ButtonColor =
  | 'brand'
  | 'accent'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'danger'
  | 'green'
  | 'gray';

const buttonColors: ButtonColor[] = [
  'brand',
  'accent',
  'neutral',
  'success',
  'warning',
  'danger',
  'green',
  'gray',
];

type ButtonVariant = 'primary' | 'ghost';

const buttonVariants: ButtonVariant[] = ['primary', 'ghost'];

export default defineComponent({
  name: 'Button',
  props: {
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
      required: false,
      default: 'md',
      validator: (size: any) => ['xs', 'sm', 'md', 'lg'].includes(size),
    },
    /** @deprecated use `variantColor` property instead. */
    color: {
      type: String as PropType<ButtonColor>,
      required: false,
      default: 'brand',
      validator: (size: any) => buttonColors.includes(size),
    },
    variant: {
      type: String as PropType<ButtonVariant>,
      required: false,
      default: 'primary',
      validator: (variant: any) => buttonVariants.includes(variant),
    },
    variantColor: {
      type: String as PropType<ButtonColor>,
      required: false,
      default: 'brand',
      validator: (size: any) => buttonColors.includes(size),
    },
  },
});
