






















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { useVModel } from '@vueuse/core';
import { useServiceWorker } from '~/app/composables/use-service-worker';

export default defineComponent({
  name: 'TheAppUpdateAlert',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const isVisible = useVModel(props, 'visible');
    const { forceReload } = useServiceWorker();

    return { isVisible, forceReload };
  },
});
