
























import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'DeletePopConfirmButton',
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
      default: 'Are you sure delete this?',
    },
    confirmText: {
      type: String as PropType<string>,
      required: false,
      default: 'Yes',
    },
    cancelText: {
      type: String as PropType<string>,
      required: false,
      default: 'No',
    },
    placement: {
      type: String as PropType<string>,
      required: false,
      default: 'top',
    },
  },
  emits: ['confirm', 'cancel'],
  setup(_, { emit }) {
    const onConfirm = () => emit('confirm');
    const onCancel = () => emit('cancel');

    return { onConfirm, onCancel };
  },
});
