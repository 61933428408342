import Vue, { VueConstructor, PluginObject } from 'vue';
import WbMenu from './menu.vue';
import WbMenuItem from './menu-item.vue';
import WbSubMenu from './sub-menu.vue';

const menu: VueConstructor<Vue> & PluginObject<void> = WbMenu as any;
menu.install = function (vue) {
  vue.component('WbMenu', WbMenu);
  vue.component('WbMenuItem', WbMenuItem);
  vue.component('WbSubMenu', WbSubMenu);
};

export { WbMenu, WbMenuItem, WbSubMenu };
export default menu;
