import type { ActionTree } from 'vuex';
import { RootState } from '../index';
import { IAccessState } from './state';
import { Mutation } from './mutations';
import { AccessScopeMasterList, User } from '~/app/models';
import { AccessScopeService } from '~/app/core/services/access-scope-service';

export enum Action {
  setUser = 'access.setUser',
  fetchUser = 'access.fetchUser',
  fetchAccessScopesMasterList = 'access.fetchAccessScopesMasterList',
}

export const actions: ActionTree<IAccessState, RootState> = {
  async [Action.fetchUser](context, options?: { ignoreCache?: boolean }) {
    let user = context.state.user;

    if (options?.ignoreCache || !user) {
      user = await this.$api.user.getCurrentAsync();
      context.commit(Mutation.setUser, user);
    }

    return user;
  },
  async [Action.fetchAccessScopesMasterList](
    context,
    options?: { ignoreCache?: boolean }
  ) {
    let accessScopes = context.state.accessScopeMasterList;

    if (options?.ignoreCache || !accessScopes) {
      accessScopes = await this.$api.user.getAccessScopeMasterListAsync();
      context.commit(Mutation.setAccessScopeMasterList, accessScopes);
    }

    return accessScopes;
  },
  async [Action.setUser](context, user: User) {
    context.commit(Mutation.setUser, user);

    const masterList: AccessScopeMasterList = await context.dispatch(
      Action.fetchAccessScopesMasterList
    );

    const accessScopeService = new AccessScopeService(masterList);
    const scopes = accessScopeService.parseList(user.accessScopes);
    this.$ability.update(scopes);
  },
};

export default actions;
