import { PluginObject } from 'vue';
import WbTransitionFade from './transition-fade.vue';
import WbTransitionPop from './transition-pop.vue';
import WbTransitionRollDown from './transition-roll-down.vue';
import WbTransitionRollInUpOutDown from './transition-roll-in-up-out-down.vue';

const plugin: PluginObject<void> = {
  install(vue) {
    vue.component('WbTransitionFade', WbTransitionFade);
    vue.component('WbTransitionPop', WbTransitionPop);
    vue.component('WbTransitionRollDown', WbTransitionRollDown);
    vue.component('WbTransitionRollInUpOutDown', WbTransitionRollInUpOutDown);
  },
};

export {
  WbTransitionFade,
  WbTransitionPop,
  WbTransitionRollDown,
  WbTransitionRollInUpOutDown,
};
export default plugin;
