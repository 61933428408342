import Vue, { VueConstructor, PluginObject } from 'vue';
import WbMenuButton from './menu-button.vue';
import WbMenuButtonItem from './menu-button-item.vue';

const menuButton: VueConstructor<Vue> &
  PluginObject<void> = WbMenuButton as any;
menuButton.install = function (vue) {
  vue.component('WbMenuButton', WbMenuButton);
  vue.component('WbMenuButtonItem', WbMenuButtonItem);
};

export { WbMenuButton, WbMenuButtonItem };
export default menuButton;
