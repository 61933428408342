export interface PaginatorButtonConfig {
  color: 'white' | 'gray' | 'black';
  icon: string;
  label?: string;
}

export const paginatorConfig = {
  wrapper: 'flex items-stretch -space-x-px',
  base: '',
  rounded: 'first:rounded-s-md last:rounded-e-md',
  default: {
    size: 'sm',
    activeButton: {
      color: 'brand' as const,
    },
    inactiveButton: {
      color: 'white' as const,
    },
    firstButton: {
      color: 'white' as const,
      icon: 'arrow-left',
      label: 'First',
    } as PaginatorButtonConfig,
    lastButton: {
      color: 'white' as const,
      icon: 'arrow-right',
      label: 'Last',
    } as PaginatorButtonConfig,
    prevButton: {
      color: 'white' as const,
      icon: 'caret-left',
    } as PaginatorButtonConfig,
    nextButton: {
      color: 'white' as const,
      icon: 'caret-right',
    } as PaginatorButtonConfig,
  },
};

export const buttonConfig = {
  base:
    'focus:outline-none focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0',
  font: 'font-medium',
  rounded: 'first:rounded-l-md last:rounded-r-md',
  truncate: 'text-left break-all line-clamp-1',
  block: 'w-full flex justify-center items-center',
  inline: 'inline-flex items-center',
  size: {
    '2xs': 'text-xs',
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-sm',
    lg: 'text-sm',
    xl: 'text-base',
  },
  gap: {
    '2xs': 'gap-x-1',
    xs: 'gap-x-1.5',
    sm: 'gap-x-1.5',
    md: 'gap-x-2',
    lg: 'gap-x-2.5',
    xl: 'gap-x-2.5',
  },
  padding: {
    '2xs': 'px-2 py-1',
    xs: 'px-2.5 py-1.5',
    sm: 'px-2.5 py-1.5',
    md: 'px-3 py-2',
    lg: 'px-3.5 py-2.5',
    xl: 'px-3.5 py-2.5',
  },
  square: {
    '2xs': 'p-1',
    xs: 'p-1.5',
    sm: 'p-1.5',
    md: 'p-2',
    lg: 'p-2.5',
    xl: 'p-2.5',
  },
  color: {
    brand: {
      solid:
        'shadow-sm ring-1 ring-inset ring-gray-300 text-white bg-brand-500 disabled:bg-brand-500 hover:bg-brand-600 disabled:text-white focus-visible:ring-2 focus-visible:ring-primary-500',
      ghost:
        'text-gray-900 hover:bg-white focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
    },
    white: {
      solid:
        'shadow-sm ring-1 ring-inset ring-gray-300 text-gray-900 bg-white hover:bg-gray-50 disabled:bg-white focus-visible:ring-2 focus-visible:ring-primary-500',
      ghost:
        'text-gray-900 hover:bg-white focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
    },
    gray: {
      solid:
        'shadow-sm ring-1 ring-inset ring-gray-300 text-gray-700 bg-gray-50 hover:bg-gray-100 disabled:bg-gray-50 focus-visible:ring-2 focus-visible:ring-primary-500',
      ghost:
        'text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
      link:
        'text-gray-500 hover:text-gray-700 underline-offset-4 hover:underline focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
    },
    black: {
      solid:
        'shadow-sm text-white bg-gray-900 hover:bg-gray-800 disabled:bg-gray-900 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
      link:
        'text-gray-900 underline-offset-4 hover:underline focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500',
    },
  },
  variant: {
    solid:
      'shadow-sm text-white bg-{color}-500 hover:bg-{color}-600 disabled:bg-{color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-{color}-500',
    outline:
      'ring-1 ring-inset ring-current text-{color}-500 hover:bg-{color}-50 disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-{color}-500',
    soft:
      'text-{color}-500 bg-{color}-50 hover:bg-{color}-100 disabled:bg-{color}-50 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500',
    ghost:
      'text-{color}-500 hover:bg-{color}-50 disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500',
    link:
      'text-{color}-500 hover:text-{color}-600 disabled:text-{color}-500 underline-offset-4 hover:underline focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500',
  },
  icon: {
    base: 'flex-shrink-0',
    loading: 'animate-spin',
    size: {
      '2xs': 'h-4 w-4',
      xs: 'h-4 w-4',
      sm: 'h-5 w-5',
      md: 'h-5 w-5',
      lg: 'h-5 w-5',
      xl: 'h-6 w-6',
    },
  },
  default: {
    size: 'sm',
    variant: 'solid',
    color: 'primary',
    loadingIcon: 'i-heroicons-arrow-path-20-solid',
  },
};
