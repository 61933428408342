import Vue, { VueConstructor, PluginObject } from 'vue';
import WbMassInput from './mass-input.vue';

const massInput: VueConstructor<Vue> & PluginObject<void> = WbMassInput as any;
massInput.install = function (vue) {
  vue.component('WbMassInput', WbMassInput);
};

export { WbMassInput };
export default massInput;
