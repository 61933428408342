export interface ScanErrorMetrics {
  scanCode: string;
  scanDuration: number;
  avgTimeByCharMs: number;
  minLength: number;
}

export class ScanError extends Error {
  public scanCode: string;
  public scanDuration: number;
  public avgTimeByCharMs: number;
  public minLength: number;

  constructor(message: string, metrics: ScanErrorMetrics) {
    super(message);
    this.scanCode = metrics.scanCode;
    this.scanDuration = metrics.scanDuration;
    this.avgTimeByCharMs = metrics.avgTimeByCharMs;
    this.minLength = metrics.minLength;
  }
}

export const ScanErrorMessage = {
  SequenceTooShort: 'Received code is shorter than minimal length',
  NotEnteredInTime: 'Received code was not entered in time',
} as const;
