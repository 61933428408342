import { render, staticRenderFns } from "./the-tenant-logo.vue?vue&type=template&id=4157563e&"
import script from "./the-tenant-logo.vue?vue&type=script&lang=ts&"
export * from "./the-tenant-logo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports