import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';
import { TagDisplayOptions, TagType } from '~/app/models';

export class AddSystemTagRequest extends AddDictionaryItemRequest {
  public readonly type: TagType;
  public readonly triggersCaseHold: boolean;
  public readonly displayOptions?: TagDisplayOptions;

  constructor(args: Omit<AddSystemTagRequest, 'triggersCaseHold'>) {
    super(args);
    this.type = args.type;
    this.triggersCaseHold = args.type === TagType.Hold;
    this.displayOptions = args.displayOptions;
  }
}

export class EditSystemTagRequest extends EditDictionaryItemRequest {
  public readonly type: TagType;
  public readonly triggersCaseHold: boolean;
  public readonly displayOptions?: TagDisplayOptions;

  constructor(args: Omit<EditSystemTagRequest, 'triggersCaseHold'>) {
    super(args);
    this.type = args.type;
    this.triggersCaseHold = args.type === TagType.Hold;
    this.displayOptions = args.displayOptions;
  }
}
