import Vue, { VueConstructor, PluginObject } from 'vue';
import WbCard from './card.vue';

const card: VueConstructor<Vue> & PluginObject<void> = WbCard as any;
card.install = function (vue) {
  vue.component('WbCard', WbCard);
};

export { WbCard };
export default card;
