import { v4 as uuidv4 } from 'uuid';

export function genId(options?: { length?: number; includeChars?: boolean }) {
  let length = options?.length;

  if (length) {
    if (length <= 2) {
      process.env.NODE_ENV === 'development' &&
        console.warn('length should be greater than 2, falling back to *5*');
      length = 5;
    }

    const { includeChars } = options || {};
    const radix =
      typeof includeChars === 'boolean'
        ? includeChars
          ? 36
          : undefined
        : undefined;

    return Math.random().toString(radix).substr(2, length);
  }

  return uuidv4();
}
