import Vue, { VueConstructor, PluginObject } from 'vue';
import WbIcon from './icon.vue';

const icon: VueConstructor<Vue> & PluginObject<void> = WbIcon as any;
icon.install = function (vue) {
  vue.component('WbIcon', WbIcon);
};

export { WbIcon };
export default icon;
