import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';
import { CaseServiceRequirementType, CaseServiceType } from '~/app/models';

export class CreateCaseServiceRequirementRequest extends AddDictionaryItemRequest {
  public readonly serviceType: CaseServiceType;
  public readonly requirementType: CaseServiceRequirementType;

  constructor(args: CreateCaseServiceRequirementRequest) {
    super(args);
    this.serviceType = args.serviceType;
    this.requirementType = args.requirementType;
  }
}

export class EditCaseServiceRequirementRequest extends EditDictionaryItemRequest {
  public readonly serviceType: CaseServiceType;
  public readonly requirementType: CaseServiceRequirementType;

  constructor(args: EditCaseServiceRequirementRequest) {
    super(args);
    this.serviceType = args.serviceType;
    this.requirementType = args.requirementType;
  }
}
