export enum OriginLocationType {
  Unknown = 0,
  Internal = 1,
  External = 2,
}

export abstract class OriginLocation {
  public readonly type!: OriginLocationType;
  public readonly details?: string;

  constructor(args?: OriginLocation) {
    if (!args) return;
    this.type = args.type;
    this.details = args.details;
  }
}

export class InternalOriginLocation extends OriginLocation {
  public locationId?: string;
  public serviceLineId?: string;
  public unit?: string;
  public room?: string;
  public bed?: string;

  constructor(args?: InternalOriginLocation) {
    super(args);
    this.locationId = args?.locationId || undefined;
    this.serviceLineId = args?.serviceLineId || undefined;
    this.unit = args?.unit || undefined;
    this.room = args?.room || undefined;
    this.bed = args?.bed || undefined;
  }
}

export class ExternalOriginLocation extends OriginLocation {
  public locationId!: string;
  public contactName?: string;
  public contactPhoneNumber?: string;

  constructor(args?: ExternalOriginLocation) {
    super(args);
    if (!args) return;
    this.locationId = args.locationId;
    this.contactName = args.contactName;
    this.contactPhoneNumber = args.contactPhoneNumber;
  }
}
