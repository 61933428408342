import { CaseServiceRequirementType, CaseServiceType } from '../case';
import { FilterableDictionaryItem } from './dictionary-item';

export class CaseServiceRequirementDictionaryItem extends FilterableDictionaryItem {
  public readonly serviceType!: CaseServiceType;
  public readonly requirementType!: CaseServiceRequirementType;

  constructor(args?: CaseServiceRequirementDictionaryItem) {
    super(args);
    this.serviceType = args?.serviceType ?? CaseServiceType.Unknown;
    this.requirementType =
      args?.requirementType ?? CaseServiceRequirementType.Unknown;
  }

  public get filterValue() {
    return this.serviceType;
  }
}
