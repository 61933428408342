import { Type } from 'class-transformer';
import { BodyboxWeightAlert } from './bodybox-weight-alert';
import { BodyboxBariatricConfiguration } from './bodybox-bariatric-configuration';

export class BodyboxWeightConfiguration {
  @Type(() => BodyboxBariatricConfiguration)
  public readonly bariatricConfiguration?: BodyboxBariatricConfiguration;

  @Type(() => BodyboxWeightAlert)
  public readonly alerts!: BodyboxWeightAlert[];

  constructor(args?: BodyboxWeightConfiguration) {
    if (!args) return;
    this.bariatricConfiguration = args.bariatricConfiguration;
    this.alerts = args.alerts;
  }
}
