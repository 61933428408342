import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { registerServiceWorker } from '~/app/composables/use-service-worker';

export default defineNuxtPlugin(async () => {
  await registerServiceWorker({
    onNeedsRefresh() {
      console.debug('There is an update for the PWA, please reload.');
    },
  });
});
