export class CaseNotice {
  public readonly type!: 'success' | 'warning' | 'danger';
  public readonly message!: string;
  public readonly description?: string;

  constructor(args?: CaseNotice) {
    if (!args) return;
    this.type = args.type;
    this.message = args.message;
    this.description = args.description;
  }
}
