import { Device } from './types';

export class PrintError extends Error {
  public readonly device?: Device;

  constructor(message: string, device: Device | undefined = undefined) {
    super(message);
    this.device = device;
  }
}
