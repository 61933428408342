import { Case } from '~/app/models';

export interface ICaseDetailsState {
  case: Case | null;
  identityVerified: boolean;
}

export const state = (): ICaseDetailsState => ({
  case: null,
  identityVerified: false,
});
