



























import {
  computed,
  defineComponent,
  PropType,
  toRef,
  useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'DefaultErrorTemplate',
  props: {
    statusCode: {
      type: [Number, String] as PropType<number | string | undefined>,
      required: false,
      default: undefined,
    },
    message: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const status = toRef(props, 'statusCode');

    /** While not the actual status code, this converts the status code into what we need to get the appropriate result image of the AntD Result component. */
    const antdDResultStatus = computed(() =>
      status.value?.toString() === '400' ? '403' : '500'
    );

    const { $auth, $config } = useContext();

    const userEmailAddress = $auth.user.value?.email;
    const logout = () => $auth.logoutAsync();

    return {
      antdDResultStatus,
      isAuthenticated: $auth.isAuthenticated,
      appBaseUrl: $config.app.baseUrl,
      userEmailAddress,
      logout,
    };
  },
});
