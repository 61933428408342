import { FormatDateTime, IsFormatDateTime } from './dates';

export class UserInvitation {
  public readonly id!: string;

  public readonly emailAddress!: string;

  @IsFormatDateTime
  public readonly localDateTimeCreated!: FormatDateTime;

  @IsFormatDateTime
  public readonly localDateTimeExpiresAt!: FormatDateTime;

  public readonly accessScopes!: string[];

  constructor(args?: UserInvitation);
  constructor(args: UserInvitation) {
    if (!args) return;
    this.id = args.id;
    this.emailAddress = args.emailAddress;
    this.localDateTimeCreated = new FormatDateTime(args.localDateTimeCreated);
    this.localDateTimeExpiresAt = new FormatDateTime(
      args.localDateTimeExpiresAt
    );
    this.accessScopes = args.accessScopes;
  }
}
