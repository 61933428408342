import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { useAuth } from '../service';
import { IAuthProviderOptions } from '../types/service';

export const AuthPlugin = defineNuxtPlugin(async (context, inject) => {
  const { auth0 } = context.$config;
  if (!auth0.domain) throw new Error('Auth domain is required.');
  if (!auth0.clientId) throw new Error('Auth client id is required.');

  const tenant = await context.$tenantApi.discover.getForContextAsync();
  const organizationId = tenant?.organizationId;

  const options: IAuthProviderOptions = {
    domain: auth0.domain,
    clientId: auth0.clientId,
    redirectUrl: location.origin,
    logoutUrl: location.origin,
    audience: auth0.audience,
    claimsNamespace: auth0.claimsNamespace,
    scope: auth0.scope,
    organizationId,
  };

  const { createProviderAsync } = useAuth(options);
  const provider = await createProviderAsync();
  inject('auth', provider);
});
