
import { defineComponent, h, PropType, toRefs } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Overflow',
  props: {
    as: {
      type: String as PropType<string>,
      required: false,
      default: 'div',
    },
    clip: {
      type: String as PropType<'left' | 'right'>,
      required: false,
      default: 'right',
    },
    enabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  setup(props, { attrs, slots }) {
    const { as, clip, enabled } = toRefs(props);

    // TODO: Support ellipses on cut off side

    const mergeStyles = (
      base: string | object | object[] | undefined,
      additional: object
    ) => {
      if (!base) return additional;

      if (Array.isArray(base)) {
        return [...base, additional].reduce(
          (styles, style) => ({ ...styles, ...style }),
          {}
        );
      }

      if (typeof base === 'string') {
        // TODO: figure out how to support this? How do we get a string here in the first place?
        return base;
      }

      return { ...base, ...additional };
    };

    return () => {
      const slotContent = slots.default?.();

      if (enabled.value && Array.isArray(slotContent)) {
        // Set LTR direction for all children
        for (const item of slotContent) {
          const style = mergeStyles(item.data?.style, {
            direction: (item.data?.style as any)?.direction || 'ltr',
            marginRight: clip.value === 'left' ? 'auto' : undefined,
          });

          item.data = { ...(item.data || {}), style };
        }
      }

      return h(
        as.value,
        {
          attrs,
          class: enabled.value ? 'overflow-auto whitespace-nowrap' : undefined,
          style: enabled.value
            ? { direction: clip.value === 'left' ? 'rtl' : 'ltr' }
            : undefined,
        },
        slotContent
      );
    };
  },
});
