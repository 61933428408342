/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  required,
  email,
  is,
  length,
  min,
  min_value,
  max,
  max_value,
  oneOf,
} from 'vee-validate/dist/rules';

extend('email', email);
extend('is', is);
extend('length', length);
extend('min', min);
extend('max', max);
extend('min_value', min_value);
extend('max_value', max_value);
extend('oneOf', oneOf);

extend('required', {
  ...required,
  // message: '{_field_} is required',
  message: (field, { message }) => message || `${field} is required`,
});

/** Validates whether the field has an enumeration value. */
extend('required_enum', {
  validate(value) {
    const hasValue = (value: unknown) =>
      !['', null, undefined, 0, '0'].includes(value as any);

    return {
      required: true,
      valid: hasValue(value),
    };
  },
  computesRequired: true,
  message: '{_field_} is required',
});

const notEmpty = (value: unknown) =>
  Array.isArray(value)
    ? value.length > 0 && value.some(notEmpty)
    : !['', null, undefined].includes(value as any);

/**
 * Validates whether the field or any specified target field(s) have a value.
 * @param target - the value(s) of the specified target field(s).
 */
extend('required_if_empty', {
  params: ['target'],
  validate(value, params) {
    if (!('target' in params)) {
      console.error(
        '[vee-validate: "required_if_empty"] missing "target" parameter.'
      );
      return false;
    }

    const { target } = params;

    return {
      required: true,
      valid: notEmpty(value) || notEmpty(target),
    };
  },
  computesRequired: true,
  message: 'Either {_field_} or {target} is required.',
});

/**
 * Validates whether the applied field or *any* specified target field(s) have a value.
 * @param params - the value(s) of the specified target field(s).
 */
extend('required_if_any_empty', {
  validate(value, params) {
    if (!Array.isArray(params)) {
      console.error(
        '[vee-validate: "required_if_any_empty"] missing list of targets parameter.'
      );
      return false;
    }

    return {
      required: true,
      valid: notEmpty(value) || params.some(notEmpty),
    };
  },
  computesRequired: true,
  message: '{_field_} is required.',
});

/**
 * Validates whether the applied field or *all* specified target field(s) have a value.
 * @param params - the value(s) of the specified target field(s).
 */
extend('required_if_all_empty', {
  validate(value, params) {
    if (!Array.isArray(params)) {
      console.error(
        '[vee-validate: "required_if_all_empty"] missing list of targets parameter.'
      );
      return false;
    }

    return {
      required: true,
      valid: notEmpty(value) || params.every(notEmpty),
    };
  },
  computesRequired: true,
  message: '{_field_} is required.',
});

/**
 * Validates whether the field value is of a specified type.
 * @param type - the type to validate the fields value against.
 */
extend('type', {
  params: ['type'],
  validate(value, params) {
    if (!('type' in params)) {
      console.error('[vee-validate: "type"] missing type parameter.');
      return false;
    }

    // eslint-disable-next-line valid-typeof
    return typeof value === params.type;
  },
  message: (field, { type }) => `${field} must be of type ${type}`,
});
