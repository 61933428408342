export class TagsCollection {
  public systemTagIds: string[];
  public userTags: string[];
  public readonly computedTags: Readonly<string[]>;

  constructor(args?: Partial<TagsCollection>) {
    this.systemTagIds = args?.systemTagIds || [];
    this.userTags = args?.userTags || [];
    this.computedTags = args?.computedTags || [];
  }
}
