var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.as,{tag:"component",staticClass:"wb-tag tag select-none",class:{
    'tag-xs': _vm.size === 'xs',
    'tag-sm': _vm.size === 'sm',
    'tag-md': _vm.size === 'md',
    'tag-lg': _vm.size === 'lg',
    'tag-brand': _vm.type === 'brand',
    'tag-default': _vm.type === 'default',
    'tag-success': _vm.type === 'success',
    'tag-warning': _vm.type === 'warning',
    'tag-danger': _vm.type === 'danger',
  },style:(_vm.dynamicStyles)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }