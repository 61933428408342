





























import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ForbiddenErrorTemplate',
  props: {
    statusCode: {
      type: [Number, String] as PropType<number | string | undefined>,
      required: false,
      default: undefined,
    },
    message: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { $auth, $config } = useContext();

    const userEmailAddress = $auth.user.value?.email;
    const logout = () => $auth.logoutAsync();

    return {
      isAuthenticated: $auth.isAuthenticated,
      appBaseUrl: $config.app.baseUrl,
      userEmailAddress,
      logout,
    };
  },
});
