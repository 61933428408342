import { inject, InjectionKey, provide } from '@vue/composition-api';
import type { AnyAbility, Ability } from '@casl/ability';
import { reactiveAbility } from './reactive-ability';

export const ABILITY_TOKEN: InjectionKey<Ability> = Symbol('ability');

export function injectAbility<
  TAbility extends AnyAbility = Ability
>(): TAbility {
  const ability = inject<TAbility>(ABILITY_TOKEN);

  if (!ability) {
    throw new Error(
      'Cannot inject Ability instance because it was not provided'
    );
  }

  return ability;
}

export function provideAbility(ability: AnyAbility) {
  provide(ABILITY_TOKEN, reactiveAbility(ability));
}
