var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dropdown',{ref:"dropdownRef",attrs:{"theme":(_vm.theme.includes('light') ? 'mb-menu' : 'mb-menu--dark') +
    (_vm.theme.includes('compact') ? '_compact' : ''),"shown":_vm.isOpen,"placement":_vm.placementComputed,"triggers":_vm.triggers,"positioning-disabled":_vm.isMobile,"distance":_vm.arrowOffset,"auto-hide":true,"compute-transform-origin":"","auto-boundary-max-size":""},on:{"apply-show":_vm.onShown,"apply-hide":function($event){_vm.isMobile && _vm.onMobileHide()},"update:shown":_vm.onShow},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var shown = ref.shown;
    var show = ref.show;
    var hide = ref.hide;
return [_vm._t("trigger",[_c('wb-button',_vm._g(_vm._b({ref:_vm.triggerIdResolved,staticClass:"z-10",attrs:{"id":_vm.triggerIdResolved,"size":"sm","aria-label":shown ? 'Close menu' : 'Open menu',"role":"button","aria-haspopup":"menu","aria-controls":_vm.menuId,"aria-expanded":shown}},'wb-button',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"flex items-center justify-center"},[_vm._v("\n          "+_vm._s(_vm.label)+"\n          "),_c('wb-icon',{staticClass:"ml-2 text-xl",attrs:{"name":shown ? 'caret-up' : 'caret-down',"type":"bold","inline":""}})],1)])],{"triggerId":_vm.triggerIdResolved,"menuId":_vm.menuId,"shown":shown,"show":show,"hide":hide,"toggleMenu":function () { return (shown ? hide() : show()); },"isOpen":shown})]}},{key:"popper",fn:function(ref){
    var shown = ref.shown;
    var hide = ref.hide;
return [_c('div',{staticClass:"wb-menu-btn__popover max-w-full",style:({ height: _vm.heightComputed, width: _vm.widthComputed }),attrs:{"id":_vm.menuId,"role":"menu","aria-labelledby":_vm.triggerIdResolved},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEscapeDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onTabDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onArrowDown($event)}]}},[_vm._t("default",null,{"shown":shown,"hide":hide})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }