import { plainToInstance } from 'class-transformer';
import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';
import { Tenant } from '~/app/models';

export const createTenantApi: PartialApiFactory<IApiFactory, 'tenant'> = (
  api
) => {
  return {
    async getAsync() {
      const tenant = await api.getAsync<Tenant>('Tenants/Self');
      return plainToInstance(Tenant, tenant);
    },
  };
};
