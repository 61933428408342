import { IApiFactory } from './IApiFactory';

const DEFAULT_API_NAME = '__default';
const registeredApis: { [name: string]: any } = {};

export function registerAPI<API = IApiFactory>(
  instance: API,
  name: string = DEFAULT_API_NAME
) {
  if (name in registeredApis && process.env.NODE_ENV === 'development')
    throw new Error(
      `An API instance has already been registered under the name "${name}".`
    );

  registeredApis[name] = instance;
  return registeredApis[name] as API;
}

export function useAPI<API = IApiFactory>(options?: { name: string }) {
  const name = options?.name ?? DEFAULT_API_NAME;

  if (!(name in registeredApis))
    throw new Error(
      `An application API instance does not exist under the name "${name}".`
    );

  return registeredApis[name] as API;
}
