import { useStoreModule } from '~/app/composables/use-store-module';
import {
  Action,
  Mutation,
  moduleName,
  ICaseDetailsState,
} from '~/app/modules/case-details/store';

export function useStore() {
  const scope = useStoreModule<ICaseDetailsState>(moduleName);

  return {
    ...scope,
    Action,
    Mutation,
  };
}
