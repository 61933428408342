/* eslint-disable no-dupe-class-members */
import defu from 'defu';
import consola from 'consola';
import { ScanError, ScanErrorMessage } from './errors';
import type {
  ScannerInitOptions,
  ScanOptions,
  ScanResult,
  ScanSessionState,
} from './types';

function noop() {}

export class ScanService {
  private defaults: ScanOptions = {
    onStart: noop,
    onStop: noop,
    onScan: noop,
    onScanError: noop,
    onKeyProcess: noop,
    onKeyDetect: () => true,
    onPaste: noop,
    keyCodeMapper: (event: KeyboardEvent) => this.decodeKeyEvent(event),
    onScanButtonLongPress: noop,
    scanButtonKeyCode: undefined,
    scanButtonLongPressTimeMs: 500,
    timeBeforeScanTestMs: 100,
    avgTimeByCharMs: 30,
    minLength: 3,
    suffixKeyCodes: [9, 13],
    prefixKeyCodes: [],
    ignoreIfFocusOn: [],
    stopPropagation: false,
    preventDefault: false,
    captureEvents: false,
    reactToKeydown: true,
    reactToPaste: false,
    singleScanQuantity: 1,
  };

  private readonly domElement: HTMLElement | Document;
  private readonly options: ScanOptions;

  private sequenceState: ScanSessionState = {
    firstCharTime: 0,
    lastCharTime: 0,
    accumulatedString: '',
    testTimer: undefined,
    longPressTimer: undefined,
    longPressTimeStart: 0,
    longPressed: false,
  };

  constructor(
    options: Partial<ScannerInitOptions> = { startImmediate: true },
    domElement: HTMLElement | Document = document
  ) {
    this.options = defu({}, options, this.defaults);
    this.domElement = domElement;

    (domElement as any).scannerDetectionData && this.stop();

    options.startImmediate && this.start();
  }

  public start() {
    if ((this.domElement as any).scannerDetectionData) return;

    // TODO: use .dataset instead or .setAttribute('scannerDetectionData', ...);
    (this.domElement as any).scannerDetectionData = {
      options: this.options,
      sessionState: this.sequenceState,
      reinitialize: this.reinitialize.bind(this),
      validateScanCode: this.validateScanCode.bind(this),
    };

    this.attachListeners();

    const startTime = new Date();
    this.options.onStart.call(this.domElement, startTime);
    this.dispatchEvent('scanner:start', startTime);

    consola.withTag('scan').info('🔎 STARTED listening for barcode scans...');
  }

  public stop() {
    const el = this.domElement as any;

    // detaching all used events
    if (el.scannerDetectionData.options.reactToPaste)
      this.domElement.removeEventListener(
        'paste',
        this.handlePaste as (e: Event) => void
      );

    if (el.scannerDetectionData.options.scanButtonKeyCode)
      el.removeEventListener('keyup', this.handleKeyUp);

    el.removeEventListener('keydown', this.handleKeyDown);

    // clearing data off DomElement
    el.scannerDetectionData = undefined;

    const stopTime = new Date();
    this.options.onStop.call(this.domElement, stopTime);
    this.dispatchEvent('scanner:stop', stopTime);

    consola.withTag('scan').info('🛑 STOPPED listening for barcode scans');
  }

  // #region Static Utils

  /**
   * Indicates if the service is attached to the given DOM element.
   * @param domElement The DOM element to check
   * @returns TRUE if the service is attached to the given DOM element and FALSE otherwise
   */
  public static isAttachedTo(domElement: HTMLElement | Document): boolean {
    return !!(domElement as any).scannerDetectionData;
  }

  /**
   * Indicates if the service is currently in the middle of a scan sequence.
   * @param domElement The DOM element to check
   * @returns TRUE if the service is in a scan sequence and FALSE otherwise
   */
  public static isScanInProgressFor(
    domElement: HTMLElement | Document
  ): boolean {
    return (
      (domElement as any).scannerDetectionData.sessionState.firstCharTime > 0
    );
  }

  /**
   * Simulates a scan of the provided code.
   *
   * The scan code can be defined as
   * - a string - in this case no keyCode decoding is done and the code is merely validated
   * against constraints like minLength, etc.
   * - an array of keyCodes (e.g. `[70,71,80]`) - will produce `keydown` events with corresponding
   * `keyCode` properties. NOTE: these events will have empty `key` properties, so decoding may
   * yield different results than with native events.
   * - an array of objects (e.g. `[{keyCode: 70, key: "F", shiftKey: true}, {keyCode: 71, key: "g"}]`) -
   * this way almost any event can be simulated, but it's a lot of work to do.
   *
   * @param domElement The DOM element to simulate with
   * @param string|array stringOrArray
   * @return self
   */
  public static simulate(
    stringOrArray: string | any[],
    domElement: HTMLElement | Document = document
  ): void {
    const instance = (domElement as any).scannerDetectionData;
    instance.reinitialize();

    if (Array.isArray(stringOrArray)) {
      stringOrArray.forEach((key) => {
        let eventProps: Partial<KeyboardEvent> = {};
        if ((typeof key === 'object' || typeof key === 'function') && !!key) {
          eventProps = key;
        } else {
          // @ts-expect-error - the interface is readonly, our init object is not
          eventProps.keyCode = parseInt(key);
        }
        const event = new KeyboardEvent('keydown', eventProps);
        document.dispatchEvent(event);
      });
    } else {
      instance.validateScanCode(stringOrArray);
    }
  }

  // #endregion

  private attachListeners(): void {
    if (this.options.reactToPaste) {
      this.domElement.addEventListener(
        'paste',
        (event) => this.handlePaste(event as ClipboardEvent),
        this.options.captureEvents
      );
    }

    const hasScanButtonKeyCode =
      typeof this.options.scanButtonKeyCode === 'number';
    if (hasScanButtonKeyCode) {
      this.domElement.addEventListener(
        'keyup',
        (event) => this.handleKeyUp(event as KeyboardEvent),
        this.options.captureEvents
      );
    }

    if (this.options.reactToKeydown || hasScanButtonKeyCode) {
      this.domElement.addEventListener(
        'keydown',
        (event) => this.handleKeyDown(event as KeyboardEvent),
        this.options.captureEvents
      );
    }
  }

  private handleKeyDown(event: KeyboardEvent): void {
    const keyCode = this.getNormalizedKeyNum(event);
    let scanFinished = false;
    let character: string | null = null;

    if (!this.options.onKeyDetect.call(this.domElement, keyCode, event)) return;

    if (this.isFocusOnIgnoredElement()) return;

    // If it's just the button of the scanner, ignore it and wait for the real input
    const { scanButtonKeyCode } = this.options;
    if (
      typeof scanButtonKeyCode === 'number' &&
      keyCode === scanButtonKeyCode
    ) {
      // If the button was first pressed, start a timeout for the callback, which gets interrupted if the scan button gets released
      if (!this.sequenceState.longPressed) {
        this.sequenceState.longPressTimer = window.setTimeout(
          () => this.options.onScanButtonLongPress.call(this.domElement),
          this.options.scanButtonLongPressTimeMs
        );
        this.sequenceState.longPressTimeStart = Date.now();
        this.sequenceState.longPressed = true;
      }

      return;
    }

    if (
      this.sequenceState.firstCharTime &&
      this.options.suffixKeyCodes.includes(keyCode)
    ) {
      // If it's not the first character and we encounter a terminating character, trigger scan process
      event.preventDefault();
      event.stopImmediatePropagation();
      scanFinished = true;
    } else if (
      // If it's the first character and we encountered one of the starting characters, don't process the scan
      this.sequenceState.firstCharTime &&
      this.options.prefixKeyCodes.includes(keyCode)
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      scanFinished = false;
    } else {
      // Add the character to the scan string we're building
      character = this.options.keyCodeMapper.call(this.domElement, event);
      if (typeof character !== 'string') return;

      this.sequenceState.accumulatedString += character;

      this.options.preventDefault && event.preventDefault();
      this.options.stopPropagation && event.stopImmediatePropagation();

      scanFinished = false;
    }

    if (!this.sequenceState.firstCharTime) {
      // Start the timer when the first character is entered
      this.sequenceState.firstCharTime = Date.now();
    }

    this.sequenceState.lastCharTime = Date.now();

    if (this.sequenceState.testTimer) {
      clearTimeout(this.sequenceState.testTimer);
    }

    if (scanFinished) {
      this.validateScanCode(this.sequenceState.accumulatedString);
      this.sequenceState.testTimer = undefined;
    } else {
      this.sequenceState.testTimer = window.setTimeout(
        () => this.validateScanCode(this.sequenceState.accumulatedString),
        this.options.timeBeforeScanTestMs
      );
    }

    this.options.onKeyProcess.call(this.domElement, character as string, event);
  }

  private handleKeyUp(event: KeyboardEvent): void {
    if (this.isFocusOnIgnoredElement()) return;

    const keyCode = this.getNormalizedKeyNum(event);

    // If the hardware key is not being pressed anymore stop the timeout and reset
    if (keyCode === this.options.scanButtonKeyCode) {
      window.clearTimeout(this.sequenceState.longPressTimer);
      this.sequenceState.longPressTimeStart = 0;
      this.sequenceState.longPressed = false;
    }
  }

  private handlePaste(event: ClipboardEvent): void {
    const clipboardText = event.clipboardData?.getData('text');
    if (!clipboardText) return;

    if (this.isFocusOnIgnoredElement()) return;

    event.preventDefault();
    this.options.stopPropagation && event.stopImmediatePropagation();

    this.options.onPaste.call(this.domElement, clipboardText, event);

    this.sequenceState.firstCharTime = 0;
    this.sequenceState.lastCharTime = 0;

    this.validateScanCode(clipboardText);
  }

  /** Checks if the documents currently focused element is an ignored element */
  private isFocusOnIgnoredElement(): boolean {
    const ignoreSelectors = this.options.ignoreIfFocusOn;
    if (!ignoreSelectors) return false;

    if (Array.isArray(ignoreSelectors)) {
      for (const selector of ignoreSelectors) {
        if (document.activeElement?.matches(selector)) return true;
      }
    } else if (document.activeElement?.matches(ignoreSelectors)) {
      return true;
    }

    return false;
  }

  /** Validates the scan code and dispatches a successful or error scan event */
  private validateScanCode(scanCode: string): boolean {
    const { singleScanQuantity } = this.options;
    const { firstCharTime, lastCharTime } = this.sequenceState;

    const dispatchScanError = (message: string) => {
      const { avgTimeByCharMs, minLength } = this.options;
      const scanDuration = lastCharTime - firstCharTime;

      const error = new ScanError(message, {
        scanCode,
        scanDuration,
        avgTimeByCharMs,
        minLength,
      });

      this.options.onScanError.call(this.domElement, error);
      this.dispatchEvent('scan:error', error);
    };

    if (scanCode.length < this.options.minLength) {
      dispatchScanError(ScanErrorMessage.SequenceTooShort);
      this.reinitialize();
      return false;
    }

    if (
      lastCharTime - firstCharTime >
      scanCode.length * this.options.avgTimeByCharMs
    ) {
      dispatchScanError(ScanErrorMessage.NotEnteredInTime);
      this.reinitialize();
      return false;
    }

    this.options.onScan.call(this.domElement, scanCode, singleScanQuantity);
    this.dispatchEvent('scan', { scanCode, quantity: singleScanQuantity });

    this.reinitialize();
    return true;
  }

  /** Dispatches a custom event on the DOM element */
  private dispatchEvent(eventName: 'scan', result: ScanResult): void;
  private dispatchEvent(eventName: 'scan:error', error: ScanError): void;
  private dispatchEvent(eventName: 'scanner:start', time: Date): void;
  private dispatchEvent(eventName: 'scanner:stop', time: Date): void;
  private dispatchEvent(eventName: string, data: any = undefined): void {
    const event = new CustomEvent(eventName, { detail: data });
    this.domElement.dispatchEvent(event);
  }

  /** Resets the scan session state */
  private reinitialize(): void {
    this.sequenceState.firstCharTime = 0;
    this.sequenceState.lastCharTime = 0;
    this.sequenceState.accumulatedString = '';

    if (this.sequenceState.testTimer) {
      window.clearTimeout(this.sequenceState.testTimer);
      this.sequenceState.testTimer = undefined;
    }

    if (this.sequenceState.longPressTimer) {
      window.clearTimeout(this.sequenceState.longPressTimer);
      this.sequenceState.longPressTimer = undefined;
    }

    this.sequenceState.longPressTimeStart = 0;
    this.sequenceState.longPressed = false;
  }

  /** Gets the key code from an event */
  private getNormalizedKeyNum(event: KeyboardEvent): number {
    // const parsed = parseInt(event.code.replace(/\D/g, ''), 10);
    // console.log('which', event.which);
    // console.log('keyCode', event.keyCode);
    // console.log('parsed', parsed);
    return event.which || event.keyCode;
  }

  /**
   * Decodes a keyboard event into a string representation of the key
   *
   * By default on the following key codes are taken into account
   * - 48-90 (0-9, A-Z)
   * - 96-105 (Numpad 0-9)
   * - 106-111 (Numpad operations: Multiply, Add, Subtract, Decimal, Divide)
   * - 186 (Semicolon)
   * - 189 (Minus), 189+Shift (Underscore)
   *
   * All other keys will yield empty strings!
   * The above key codes will be decoded using the KeyboardEvent.key property on modern
   * browsers. On older browsers the method will fall back to String.fromCharCode()
   * putting the result to upper/lower case depending on KeyboardEvent.shiftKey if it is set.
   *
   * @param The keyboard event to decode
   * @returns The decoded key string
   */
  private decodeKeyEvent(event: KeyboardEvent): string {
    // TODO: shouldn't we just use event.key instead?
    const code = this.getNormalizedKeyNum(event);

    const isAlphanumeric = code >= 48 && code <= 90;
    const isNumpadDigit = code >= 96 && code <= 105;
    const isNumpadOperator = code >= 106 && code <= 111;
    const isSpecialChar = [186, 189].includes(code);

    if (isAlphanumeric || isNumpadOperator || isSpecialChar) {
      if (event.key) return event.key;

      const decodedChar = event.shiftKey
        ? String.fromCharCode(code).toUpperCase()
        : String.fromCharCode(code).toLowerCase();

      return decodedChar;
    }

    // Convert numpad digits to string (subtract 96 to get the correct digit non-numpad key)
    if (isNumpadDigit) return `${0 + (code - 96)}`;

    // Unparsable key
    return '';
  }
}
