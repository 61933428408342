


























import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Divider',
  props: {
    color: {
      type: String as PropType<string>,
      require: false,
      default: 'bg-comet-200',
    },
    height: {
      type: Number as PropType<number>,
      require: false,
      default: 2,
    },
    grow: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [1, 1, 1],
    },
  },
  setup(props, { slots }) {
    const hasContent = !!slots.default;

    const growValues = computed(() => {
      const { grow } = toRefs(props);
      return grow.value.length < 3
        ? grow.value.fill(1, grow.value.length, 2)
        : grow.value;
    });
    return { hasContent, growValues };
  },
});
