
























































import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ForbiddenTenantErrorTemplate',
  props: {
    statusCode: {
      type: [Number, String] as PropType<number | string | undefined>,
      required: false,
      default: undefined,
    },
    message: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { $auth, $config, store } = useContext();

    const tenantId: string = store.state.tenant.tenant?.id;

    const userEmailAddress = $auth.user.value?.email;
    const logout = () => $auth.logoutAsync();

    const version = `${$config.app.version} ${$config.app.buildNumber}`;
    const appBaseUrl = $config.app.baseUrl;
    const supportEmail = $config.app.support.email;

    return {
      tenantId,
      isAuthenticated: $auth.isAuthenticated,
      logout,
      appBaseUrl,
      userEmailAddress,
      version,
      supportEmail,
    };
  },
});
