











import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from '@nuxtjs/composition-api';
import '@phosphor-icons/web/src/regular/style.css';
import '@phosphor-icons/web/src/bold/style.css';
import '@phosphor-icons/web/src/fill/style.css';

type IconType = 'regular' | 'bold' | 'fill';

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<IconType | undefined>,
      required: false,
      default: 'regular',
      validator: (type: any) => {
        return ['thin', 'light', 'regular', 'bold', 'fill', 'duotone'].includes(
          type
        );
      },
    },
    inline: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { name, type } = toRefs(props);

    const iconClass = computed(() => {
      let classes = `ph-${name.value} ph`; // regular weight just uses ".ph" class
      type.value !== 'regular' && (classes += `-${type.value}`);
      return classes;
    });

    return { iconClass };
  },
});
