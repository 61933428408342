































































































import {
  computed,
  defineComponent,
  PropType,
  reactive,
} from '@nuxtjs/composition-api';
import { CaseSummary, CustodyStatus } from '~/app/models';
import CaseCardDetails from '~mod:bodybox/components/molecules/case-card-details.vue';

// TODO: Show current bodybox location

export enum MatchAction {
  View = 'view',
  Transfer = 'transfer',
  CheckIn = 'checkIn',
  CheckOut = 'checkOut',
}

export default defineComponent({
  name: 'CaseScanMatchCard',
  components: {
    CaseCardDetails,
  },
  props: {
    summary: {
      type: Object as PropType<CaseSummary>,
      required: true,
      validator: (value: unknown) => value instanceof CaseSummary,
    },
    canReadCase: {
      type: Boolean,
      required: false,
      default: true,
    },
    canCheckInCase: {
      type: Boolean,
      required: false,
      default: true,
    },
    canCheckOutCase: {
      type: Boolean,
      required: false,
      default: true,
    },
    canTransferCase: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    MatchAction.View,
    MatchAction.Transfer,
    MatchAction.CheckIn,
    MatchAction.CheckOut,
  ],
  setup(props, { emit }) {
    const custody = computed(() => {
      const { custodyStatus } = props.summary;
      if (custodyStatus === CustodyStatus.Expected)
        return { type: 'Incoming', class: 'text-success-600' };
      if (custodyStatus === CustodyStatus.Departed)
        return { type: 'Released', class: 'text-warning-600' };
      if (custodyStatus === CustodyStatus.InCustody) {
        const { bodybox, storageLabel, storageLocation } = props.summary;
        if (storageLabel && storageLocation)
          return {
            type: 'In Custody',
            class: 'text-brand-600',
            location: `${bodybox} (${storageLabel} ${storageLocation})`,
          };
        return {
          type: 'In Custody',
          class: 'text-brand-600',
          location: bodybox,
        };
      }
      throw new Error('Invalid custody status');
    });

    function goToCase() {
      emit(MatchAction.View, props.summary.id);
    }

    function transferCase() {
      emit(MatchAction.Transfer, props.summary.id);
    }

    function checkInCase() {
      emit(MatchAction.CheckIn, props.summary.id);
    }

    function checkOutCase() {
      emit(MatchAction.CheckOut, props.summary.id);
    }

    const actions = reactive({
      view: computed(() => props.canReadCase),
      transfer: computed(
        () =>
          props.canTransferCase &&
          props.summary.custodyStatus === CustodyStatus.InCustody
      ),
      checkIn: computed(
        () =>
          props.canCheckInCase &&
          [CustodyStatus.Expected, CustodyStatus.Departed].includes(
            props.summary.custodyStatus
          )
      ),
      checkOut: computed(
        () =>
          props.canCheckOutCase &&
          props.summary.custodyStatus === CustodyStatus.InCustody
      ),
    });

    return {
      custody,
      goToCase,
      transferCase,
      checkInCase,
      checkOutCase,
      actions,
      CustodyStatus,
    };
  },
});
