/* eslint-disable camelcase */
import Vue, { VueConstructor, PluginObject } from 'vue';
import WbColorSwatchPicker from './color-swatch-picker.vue';

const colorPicker: VueConstructor<Vue> &
  PluginObject<void> = WbColorSwatchPicker as any;
colorPicker.install = function (vue) {
  vue.component('WbColorSwatchPicker', WbColorSwatchPicker);
};

export { WbColorSwatchPicker };
export * from './constants';
export * from './useColorPicker';
export default colorPicker;
