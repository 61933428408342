import { Route } from 'vue-router';

/** Indicates if a user is authorized to view the given route. */
export function isRoutePublic(route: Route) {
  if (route.meta && Array.isArray(route.meta)) {
    const isPublic =
      route.meta.length && route.meta.every((meta) => !!meta.public);
    return isPublic;
  }
  return false;
}
