import Vue, { VueConstructor, PluginObject } from 'vue';
import WbButton from './button.vue';

const button: VueConstructor<Vue> & PluginObject<void> = WbButton as any;
button.install = function (vue) {
  vue.component('WbButton', WbButton);
};

export { WbButton };
export default button;
