import type { IdleTimerOptions } from './options';

type EventObject = typeof window | typeof document | HTMLElement;

const bulkAddEventListener = (
  object: EventObject,
  events: string[],
  callback: (event: Event) => void
) => {
  events.forEach((event) => {
    object.addEventListener(event, callback);
  });
};

const bulkRemoveEventListener = (
  object: EventObject,
  events: string[],
  callback: (event: Event) => void
) => {
  events.forEach((event) => {
    object.removeEventListener(event, callback);
  });
};

type BaseOptions = Omit<
  IdleTimerOptions,
  'onIdle' | 'onTick' | 'onActivity' | 'onActive' | 'onHide' | 'onShow'
>;

const DEFAULT_OPTIONS: IdleTimerOptions & Required<BaseOptions> = {
  idle: 60000,
  events: ['mousemove', 'scroll', 'keydown', 'mousedown', 'touchstart'],
  tickMeasure: 1000,
  onIdle: undefined,
  onTick: undefined,
  onActivity: undefined,
  onActive: undefined,
  onHide: undefined,
  onShow: undefined,
  keepTracking: true,
  startAtIdle: false,
  recurIdleCall: false,
};

export class IdleTimer {
  private readonly settings: IdleTimerOptions & Required<BaseOptions>;
  private readonly idlenessEventsHandler: (event: Event) => void;
  private readonly visibilityEventsHandler: (event: Event) => void;
  private readonly visibilityEvents = [
    'visibilitychange',
    'webkitvisibilitychange',
    'mozvisibilitychange',
    'msvisibilitychange',
  ];

  private stopListener: (event: Event) => void;

  private clearTimeout: (() => void) | null = null;
  private isIdle: boolean = false;
  private isActiveWindow = true;

  constructor(options: IdleTimerOptions = {}) {
    this.settings = this.parseSettings(options);

    this.reset();

    this.stopListener = (_event: Event) => this.stop();

    this.idlenessEventsHandler = (_: Event) => {
      this.settings.onActivity?.();

      if (this.isIdle) {
        this.isIdle = false;
        this.settings.onActive?.();
      }

      this.resetTimeout();
    };
    this.visibilityEventsHandler = (_: Event) => {
      if (
        document.hidden ||
        (document as any).webkitHidden ||
        (document as any).mozHidden ||
        (document as any).msHidden
      ) {
        if (this.isActiveWindow) {
          this.isActiveWindow = false;
          this.settings.onHide?.();
        }
      } else if (!this.isActiveWindow) {
        this.isActiveWindow = true;
        this.settings.onShow?.();
      }
    };
  }

  /** Start idle tracking. */
  public start() {
    if (this.clearTimeout) {
      throw new Error('Timer is already running');
    }

    window.addEventListener('idle:stop', this.stopListener);
    this.startTimeout();

    bulkAddEventListener(
      window,
      this.settings.events || DEFAULT_OPTIONS.events,
      this.idlenessEventsHandler
    );

    if (this.settings.onShow || this.settings.onHide) {
      bulkAddEventListener(
        document,
        this.visibilityEvents,
        this.visibilityEventsHandler
      );
    }

    return this;
  }

  /** Stop idle tracking. */
  public stop() {
    if (!this.clearTimeout) {
      throw new Error('Timer is not running');
    }

    window.removeEventListener('idle:stop', this.stopListener);

    bulkRemoveEventListener(
      window,
      this.settings.events || DEFAULT_OPTIONS.events,
      this.idlenessEventsHandler
    );
    this.resetTimeout(false);

    if (this.settings.onShow || this.settings.onHide) {
      bulkRemoveEventListener(
        document,
        this.visibilityEvents,
        this.visibilityEventsHandler
      );
    }

    return this;
  }

  /** Reset the idle tracking state. */
  public reset({
    idle = this.settings.startAtIdle,
    visible = !this.settings.startAtIdle,
  } = {}) {
    this.isIdle = idle as boolean;
    this.isActiveWindow = visible;

    return this;
  }

  private resetTimeout(keepTracking = this.settings.keepTracking) {
    if (this.clearTimeout) {
      this.clearTimeout();
      this.clearTimeout = null;
    }

    if (keepTracking) {
      this.startTimeout();
    }
  }

  private startTimeout() {
    const onIdle = () => {
      this.isIdle = true;
      this.settings?.onIdle?.();
    };

    if (this.settings.onTick) {
      let tick = 0;
      const idleInterval = this.settings.idle;
      const measure = this.settings.tickMeasure;
      const id = window.setInterval(() => {
        this.settings.onTick?.(tick + 1, idleInterval, measure);

        if (++tick * measure >= idleInterval) {
          window.clearInterval(id); // stop the timeout
          onIdle();
        }
      }, measure);

      this.clearTimeout = () => window.clearInterval(id);
      return;
    }

    // TODO: Isn't this redundant if also using the keepTracking option?
    const timer = this.settings.recurIdleCall
      ? {
          set: window.setInterval.bind(window),
          clear: window.clearInterval.bind(window),
        }
      : {
          set: window.setTimeout.bind(window),
          clear: window.clearTimeout.bind(window),
        };

    const id = timer.set(onIdle, this.settings.idle);
    this.clearTimeout = () => timer.clear(id);
  }

  private parseSettings(options: IdleTimerOptions) {
    this.throwOnBadKey(Object.keys(options), Object.keys(DEFAULT_OPTIONS));
    return { ...DEFAULT_OPTIONS, ...options };
  }

  private throwOnBadKey(keys: string[], goodKeys: string[]) {
    keys.forEach(function (key) {
      if (!goodKeys.includes(key)) {
        throw new Error(`set: Unknown key ${key}`);
      }
    });
  }
}
