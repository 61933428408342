/* eslint-disable no-dupe-class-members */
import { DEFAULT_LANGUAGE } from './defaults';
import { GrammarRuleForm, Language } from '~/app/models';

type LanguageConceptResolver = (lang: Language) => GrammarRuleForm;
type LanguageGrammarRule = keyof GrammarRuleForm;
type LanguageToken<
  TGrammarRule extends LanguageGrammarRule
> = `${string}{${TGrammarRule}}${string}`;

type LanguageScope = {
  token: (token: LanguageToken<keyof GrammarRuleForm>) => string;
  grammar: GrammarRuleForm;
};

export class LanguageService {
  private language?: Readonly<Language>;
  private readonly throwIfNotSet: boolean;

  constructor(language?: Readonly<Language>, throwIfNotSet = false) {
    this.language = language;
    this.throwIfNotSet = throwIfNotSet;
  }

  public updateLanguage(language: Readonly<Language>) {
    if (!language) {
      throw new Error('language is required.');
    }

    this.language = language;
  }

  public get(resolver: keyof Language): GrammarRuleForm;
  public get(resolver: LanguageConceptResolver): GrammarRuleForm;
  public get(
    resolver: keyof Language | LanguageConceptResolver
  ): GrammarRuleForm {
    if (!this.language && this.throwIfNotSet) {
      throw new Error('language has not been set.');
    }

    const language = this.language || DEFAULT_LANGUAGE;

    if (typeof resolver === 'string') {
      return language[resolver as keyof Language];
    }

    return resolver(language);
  }

  public token(
    resolver: keyof Language,
    token: LanguageToken<keyof GrammarRuleForm>
  ): string;

  public token(
    resolver: LanguageConceptResolver,
    token: LanguageToken<keyof GrammarRuleForm>
  ): string;

  public token(
    resolver: keyof Language | LanguageConceptResolver,
    token: LanguageToken<keyof GrammarRuleForm>
  ) {
    if (!this.language && this.throwIfNotSet) {
      throw new Error('language has not been set.');
    }

    const language = this.language || DEFAULT_LANGUAGE;

    const grammarRule =
      typeof resolver === 'string'
        ? language[resolver as keyof Language]
        : resolver(language);

    return this.replaceGrammarRules(grammarRule, token);
  }

  public createScope(resolver: keyof Language): LanguageScope;
  public createScope(resolver: LanguageConceptResolver): LanguageScope;
  public createScope(resolver: keyof Language | LanguageConceptResolver) {
    if (!this.language && this.throwIfNotSet) {
      throw new Error('language has not been set.');
    }

    const language = this.language || DEFAULT_LANGUAGE;

    const grammarRule =
      typeof resolver === 'string'
        ? language[resolver as keyof Language]
        : resolver(language);

    return {
      token: (token: LanguageToken<keyof GrammarRuleForm>) => {
        return this.replaceGrammarRules(grammarRule, token);
      },
      grammar: grammarRule,
    };
  }

  private replaceGrammarRules(
    rule: GrammarRuleForm,
    token: LanguageToken<keyof GrammarRuleForm>
  ) {
    return token
      .replace('{singular}', rule.singular)
      .replace('{plural}', rule.plural)
      .replace('{article}', rule.article);
  }
}
