export class AuthTaskQueueEntry {
  public readonly callback: (...args: any[]) => any;
  public readonly args: any[];

  constructor(callback: (...args: any[]) => any, ...args: any[]) {
    this.callback = callback;
    this.args = args;
  }
}

export type AuthTaskQueueDeferralController = {
  processQueue: Function;
  dumpQueueEvents: Function;
};

export class AuthTaskQueue {
  private events: AuthTaskQueueEntry[];
  private dispatchEvents?: Function;
  private deferralTask?: Promise<any>;
  private isProcessing = false;
  private hasProcessed = false;
  private _priority = 3;

  constructor(events: AuthTaskQueueEntry[]) {
    this.events = events || [];
  }

  public get priority() {
    return this._priority;
  }

  public get isDeferred() {
    return !!this.deferralTask;
  }

  public get deferral() {
    return this.deferralTask;
  }

  public setPriority(priority: number) {
    this._priority = priority;
  }

  public process() {
    if (this.hasProcessed)
      throw new Error(
        'The queue has already processed its events. This object can now be disposed.'
      );

    if (this.isProcessing)
      throw new Error('The queue is currently processing events.');

    const startProcessing = () => (this.isProcessing = true);
    const completeProcessing = () => {
      this.deferralTask = undefined;
      this.events = [];
      this.isProcessing = false;
      this.hasProcessed = true;
    };

    this.dispatchEvents = function () {
      startProcessing();

      this.events.forEach(function (this: any, { callback, args }) {
        try {
          callback(...args);
        } catch (error) {
          console.error(error);
        }
      });

      completeProcessing();
    };

    const dumpQueue = () => {
      this.events = [];
      this.isProcessing = false;
      this.hasProcessed = true;
    };

    if (this.deferralTask) {
      this.deferralTask
        .then(() => this.dispatchEvents?.()) // process queue
        .catch(() => dumpQueue()); // dump queue
    } else {
      this.dispatchEvents();
    }
  }

  public defer() {
    let _processQueue: Function | undefined;
    let _dumpQueueEvents: Function | undefined;

    this.deferralTask = new Promise((resolve, reject) => {
      _processQueue = resolve;
      _dumpQueueEvents = reject;
    });

    const controller: AuthTaskQueueDeferralController = {
      processQueue() {
        _processQueue?.();
      },
      dumpQueueEvents() {
        _dumpQueueEvents?.();
      },
    };

    return controller;
  }
}
