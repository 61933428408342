import Validator from 'validator';
import { extend } from 'vee-validate';

extend('phone', {
  params: ['locale'],
  validate(value, args) {
    const { isMobilePhone, isMobilePhoneLocales } = Validator;
    const locale =
      'locale' in args && isMobilePhoneLocales.includes(args.locale)
        ? args.locale
        : 'en-US';

    const phone = value.replace(/ /g, '').replace(/-/g, ''); // remove mask;
    return isMobilePhone(phone, locale);
    // return phone.length === 10;
  },
  // message: 'Phone number must be exactly 10 digits.',
  message: 'Phone number is an invalid format.',
});
