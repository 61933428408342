import { Type } from 'class-transformer';
import { FormatDateTime, IsFormatDateTime } from '../dates';
import { RequirementStatus } from './requirement-status';

export class OrganDonationRecord {
  public readonly id?: string;

  public description?: string;

  public organBankId?: string;

  @IsFormatDateTime
  public dateNotified?: FormatDateTime;

  @IsFormatDateTime
  public dateCompleted?: FormatDateTime;

  constructor(args?: OrganDonationRecord) {
    this.id = args?.id;
    this.description = args?.description;
    this.organBankId = args?.organBankId;
    this.dateNotified = args?.dateNotified
      ? new FormatDateTime(args?.dateNotified)
      : undefined;
    this.dateCompleted = args?.dateCompleted
      ? new FormatDateTime(args?.dateCompleted)
      : undefined;
  }
}

export class OrganDonationCollection {
  public requirementStatus?: RequirementStatus;

  @Type(() => OrganDonationRecord)
  public records?: OrganDonationRecord[];

  constructor(args?: Partial<OrganDonationCollection>) {
    this.requirementStatus = args?.requirementStatus;
    this.records = args?.records || [];
  }
}
