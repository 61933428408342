









































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { defu } from 'defu';
import type { ProgressAnimation, ProgressSize } from './types';
import { progressConfig } from './config';

export default defineComponent({
  name: 'Progress',
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: null,
    },
    max: {
      type: [Number, Array],
      default: 100,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: String as PropType<ProgressAnimation>,
      default: () => progressConfig.default.animation,
      validator: (value: string) => {
        return Object.keys(progressConfig.animation).includes(value);
      },
    },
    size: {
      type: String as PropType<ProgressSize>,
      default: () => progressConfig.default.size,
      validator: (value: string) => {
        return Object.keys(progressConfig.progress.size).includes(value);
      },
    },
    config: {
      type: Object as PropType<Partial<typeof progressConfig>>,
      default: () => ({}),
    },
  },
  setup(props) {
    const ui = computed<typeof progressConfig>(() =>
      defu({}, props.config, progressConfig)
    );

    const indicatorContainerClass = computed(() => [
      ui.value.indicator.container.base,
      ui.value.indicator.container.width,
      ui.value.indicator.container.transition,
    ]);

    const indicatorClass = computed(() => [
      ui.value.indicator.align,
      ui.value.indicator.width,
      ui.value.indicator.color,
      ui.value.indicator.size[props.size],
    ]);

    const progressClass = computed(() => {
      const classes = [ui.value.progress.size[props.size]];

      if (isIndeterminate.value) {
        classes.push(ui.value.animation[props.animation]);
      }

      return classes;
    });

    const stepsClass = computed(() => [
      ui.value.steps.base,
      ui.value.steps.color,
      ui.value.steps.size[props.size],
    ]);

    const stepClass = computed(() => [ui.value.step.base, ui.value.step.align]);

    const stepActiveClass = computed(() => [ui.value.step.active]);

    const stepFirstClass = computed(() => [ui.value.step.first]);

    function isActive(index: number) {
      return index === Number(props.value);
    }

    function isFirst(index: number) {
      return index === 0;
    }

    function stepClasses(index: string | number) {
      index = Number(index);

      const classes = [...stepClass.value];

      if (isFirst(index)) {
        classes.push(...stepFirstClass.value);
      }

      if (isActive(index)) {
        classes.push(...stepActiveClass.value);
      }

      return classes;
    }

    const isIndeterminate = computed(
      () => props.value === undefined || props.value === null
    );

    function isSteps(value: unknown): value is number[] {
      return Array.isArray(value);
    }

    const realMax = computed(() => {
      if (isIndeterminate.value) {
        return null;
      }

      if (Array.isArray(props.max)) {
        return props.max.length - 1;
      }

      return Number(props.max);
    });

    const percent = computed(() => {
      if (isIndeterminate.value) {
        return null;
      }

      switch (true) {
        case props.value < 0:
          return 0;
        case props.value > (realMax.value as number):
          return 100;
        default:
          return (props.value / (realMax.value as number)) * 100;
      }
    });

    const attrs = computed(() =>
      isIndeterminate.value
        ? { max: realMax.value }
        : { max: realMax.value, value: props.value }
    );

    return {
      ui,
      indicatorContainerClass,
      indicatorClass,
      progressClass,
      stepsClass,
      stepClasses,
      isIndeterminate,
      isSteps,
      realMax,
      percent,
      attrs,
    };
  },
});
