import {
  computed,
  ComputedRef,
  inject,
  isRef,
  onMounted,
  onUnmounted,
  provide,
  ref,
  Ref,
  unref,
  watch,
} from '@nuxtjs/composition-api';
import { cloneDeep } from 'lodash-es';
import FormComponent from './form.vue';
import {
  SYMBOL_FORM_PARTIAL_ADD,
  SYMBOL_FORM_PARTIAL_REMOVE,
  SYMBOL_FORM_PARTIAL_SELF_MANAGED_ADD,
  SYMBOL_FORM_PARTIAL_SELF_MANAGED_REMOVE,
} from './symbols';

// @ts-ignore
export type Form = FormComponent & {
  isDirty: boolean;
  errors: Record<string, string[]>;
  validateAsync: () => Promise<boolean>;
  validateWithErrorsAsync: () => Promise<{
    isValid: boolean;
    errors: Record<string, string[]>;
  }>;
  resetAsync: () => Promise<void>;
  resetValidationStateAsync: () => Promise<void>;
  handleSubmit: Function;
};

type FormRef = Ref<Form>;
type MaybeForm = Ref<Form | undefined>;

export type SelfManagedFormState = {
  name?: string;
  isDirty: ComputedRef<boolean>;
  errors: ComputedRef<Error[]>;
  validateAsync: () => Promise<boolean>;
  reset: () => void;
  // TODO: support async by default
  resetValidationState: () => void;
  $on: (event: string, cb: Function) => void;
  $off: (event: string, cb?: Function) => void;
  $emit: (event: string, ...args: any[]) => void;
};

export type SelfManagedFormOptions<TModel = unknown> = {
  /** Used to identify the section while debugging. */
  name?: string;
  model?: Ref<TModel>;
  /** Sets the `isDirty` flag when changes to the model are detected. @default true */
  watchModelForChanges?: boolean;
  watchDeep?: boolean;
  validateAsync?: () => Promise<Error[]>;
  reset?: () => void;
  // TODO: support async by default
  resetValidationState?: () => void;
};

const nullInjectFunc = (injectName: string) => {
  throw new Error(
    `[usePartialForm] unable to resolve expected injection "${injectName}".`
  );
};

export function usePartialFormProvider() {
  const forms: Ref<FormRef[]> = ref([]);
  const selfManagedForms: Ref<SelfManagedFormState[]> = ref([]);

  const partials = computed(() => [
    ...unref(forms).map((form) => unref(form)),
    ...unref(selfManagedForms),
  ]);

  const isDirty = computed(() => {
    const flags = unref(partials).map((form) =>
      isRef(form.isDirty) ? unref(form.isDirty) : form.isDirty
    );

    return unref(partials).length > 0 ? flags.some((dirty) => dirty) : false;
  });

  const addForm = (form: MaybeForm) => {
    if (!form.value) {
      throw new Error(
        '[usePartialForm:SYMBOL_FORM_PARTIAL_ADD] form is required.'
      );
    }

    unref(forms).push(form as FormRef);
  };

  const removeForm = (form: MaybeForm) => {
    if (!form.value) {
      throw new Error(
        '[usePartialForm:SYMBOL_FORM_PARTIAL_REMOVE] form is required.'
      );
    }

    const index = unref(forms).indexOf(form as FormRef);
    unref(forms).splice(index, 1);
  };

  const addSelfManagedForm = (state: SelfManagedFormState) => {
    unref(selfManagedForms).push(state);
  };

  const removeSelfManagedForm = (state: SelfManagedFormState) => {
    const index = unref(selfManagedForms).indexOf(state);
    unref(selfManagedForms).splice(index, 1);
  };

  provide(SYMBOL_FORM_PARTIAL_ADD, addForm);
  provide(SYMBOL_FORM_PARTIAL_REMOVE, removeForm);
  provide(SYMBOL_FORM_PARTIAL_SELF_MANAGED_ADD, addSelfManagedForm);
  provide(SYMBOL_FORM_PARTIAL_SELF_MANAGED_REMOVE, removeSelfManagedForm);

  const validateAsync = () => {
    return new Promise<boolean>((resolve, reject) => {
      const resultPromises = unref(partials).map(
        async (form) => await form.validateAsync()
      );

      Promise.all(resultPromises)
        .then((results) => resolve(results.every((isValid) => isValid)))
        .catch(reject);
    });
  };

  const resetAsync = async () => {
    for (const form of unref(partials).map(unref)) {
      if ('reset' in form) {
        form.reset();
      } else {
        await form.resetAsync();
      }
    }
  };

  const resetValidationStateAsync = async () => {
    for (const form of unref(partials).map(unref)) {
      if ('resetValidationState' in form) {
        await Promise.resolve(form.resetValidationState());
      } else {
        await form.resetValidationStateAsync();
      }
    }
  };

  return {
    forms: computed(() => forms.value),
    selfManagedForms: computed(() => selfManagedForms.value),
    partials: computed(() => partials.value),
    isDirty,
    validateAsync,
    resetAsync,
    resetValidationStateAsync,
  };
}

export function usePartialForm(form: MaybeForm) {
  const linkPartialForm = inject<(form: MaybeForm) => void>(
    SYMBOL_FORM_PARTIAL_ADD,
    () => nullInjectFunc('linkPartialForm')
  );
  const unlinkPartialForm = inject<(form: MaybeForm) => void>(
    SYMBOL_FORM_PARTIAL_REMOVE,
    () => nullInjectFunc('unlinkPartialForm')
  );

  onMounted(() => linkPartialForm(form));
  onUnmounted(() => unlinkPartialForm(form));

  const noopAsync = () => Promise.resolve();

  return {
    isDirty: computed(() => unref(form)?.isDirty ?? false),
    errors: computed(() => unref(form)?.errors.value || []),
    validateAsync: unref(form)?.validateAsync ?? (() => Promise.resolve(true)),
    resetAsync: unref(form)?.resetAsync || noopAsync,
    resetValidationStateAsync:
      unref(form)?.resetValidationStateAsync || noopAsync,
  };
}

export function usePartialSelfManagedForm<TModel = unknown>(
  options?: SelfManagedFormOptions<TModel>
) {
  const model = options?.model;
  const initialModel = model?.value ? cloneDeep(model.value) : undefined;
  const watchModelForChanges = options?.watchModelForChanges ?? true;
  const watchDeep = options?.watchDeep ?? true;

  const isDirty = ref(false);
  const setDirty = (dirty: boolean) => {
    isDirty.value = dirty;
  };

  let isResetting = false;
  if (model?.value && watchModelForChanges) {
    watch(model, () => (isResetting ? (isResetting = false) : setDirty(true)), {
      deep: watchDeep,
    });
  }

  const errors = ref<Error[]>([]);

  const validateAsync = async () => {
    errors.value = await (options?.validateAsync?.() ||
      Promise.resolve<Error[]>([]));

    return !unref(errors).length;
  };

  const resetValidationState = () => {
    options?.resetValidationState?.();
    errors.value = [];
    setDirty(false);
  };

  const reset = () => {
    if (model?.value) {
      watchModelForChanges && (isResetting = true);
      model.value = cloneDeep(initialModel)!;
    }

    options?.reset?.();
    resetValidationState();
  };

  // #region Event bus

  const eventBus = new Map<string, Function[]>();
  const $on = (event: string, cb: Function) => {
    if (eventBus.has(event)) {
      const listeners = eventBus.get(event);
      eventBus.set(event, [...(listeners || []), cb]);
      return;
    }

    eventBus.set(event, [cb]);
  };
  const $emit = (event: string, ...args: any[]) => {
    if (!eventBus.has(event)) return;

    eventBus.get(event)?.forEach((cb) => cb(...args));
  };

  const $off = (event: string, cb?: Function) => {
    if (!eventBus.has(event)) return;

    if (!cb) {
      eventBus.delete(event);
      return;
    }

    const listeners = eventBus.get(event);
    const index = listeners?.findIndex((listener) => listener === cb);
    if (typeof index === 'number' && index > -1) {
      listeners?.splice(index, 1);
      eventBus.set(event, listeners || []);
    }
  };

  // #endregion

  const state: SelfManagedFormState = {
    name: options?.name,
    isDirty: computed(() => isDirty.value),
    errors: computed(() => errors.value),
    validateAsync,
    reset,
    resetValidationState,
    $on,
    $off,
    $emit,
  };

  const linkPartialSelfManagedForm = inject<
    (state: SelfManagedFormState) => void
  >(SYMBOL_FORM_PARTIAL_SELF_MANAGED_ADD, () =>
    nullInjectFunc('linkPartialSelfManagedForm')
  );
  const unlinkPartialSelfManagedForm = inject<
    (state: SelfManagedFormState) => void
  >(SYMBOL_FORM_PARTIAL_SELF_MANAGED_REMOVE, () =>
    nullInjectFunc('unlinkPartialSelfManagedForm')
  );

  onMounted(() => linkPartialSelfManagedForm(state));
  onUnmounted(() => {
    unlinkPartialSelfManagedForm(state);
    eventBus.clear();
  });

  return { ...state, setDirty };
}
