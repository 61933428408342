import { Tenant } from '~/app/models';

export interface ITenantState {
  tenant: Readonly<Tenant> | null;
}

const state = (): ITenantState => ({
  tenant: null,
});

export { state };
export default state;
