

























import {
  computed,
  defineComponent,
  inject,
  PropType,
  Ref,
  toRefs,
} from '@nuxtjs/composition-api';
import {
  SYMBOL_TAB_BAR_MENU_ACTIVE_LEVEL,
  SYMBOL_TAB_BAR_MENU_IS_CHILD,
} from './symbols';

export default defineComponent({
  name: 'TabBarItem',
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    to: {
      type: [String, Function] as PropType<
        string | ((...args: any[]) => any) | undefined
      >,
      required: false,
      default: undefined,
    },
    orientation: {
      type: String as PropType<'horizontal' | 'vertical' | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const { to, orientation } = toRefs(props);

    const isInMenu = inject(SYMBOL_TAB_BAR_MENU_IS_CHILD, false);
    const orien = computed(
      () => orientation.value || (isInMenu ? 'horizontal' : 'vertical')
    );

    const attrs = computed(() => (to ? { to: to.value } : {}));

    const activeLevel = inject<Ref<number>>(SYMBOL_TAB_BAR_MENU_ACTIVE_LEVEL);

    function onLinkClick() {
      activeLevel!.value = 0; // Hide all menus
    }

    function onButtonClick() {
      if (to.value && typeof to.value === 'function') to.value();

      onLinkClick();
    }

    return { attrs, orien, onLinkClick, onButtonClick };
  },
});
