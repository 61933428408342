import { CaseServiceType } from '../case';
import { FilterableDictionaryItem } from './dictionary-item';

export class CaseServiceStatusDictionaryItem extends FilterableDictionaryItem {
  public readonly serviceType!: CaseServiceType;
  public readonly triggersCaseHold!: boolean;
  public readonly indicatesCompletion!: boolean;

  constructor(args?: CaseServiceStatusDictionaryItem) {
    super(args);
    this.serviceType = args?.serviceType ?? CaseServiceType.Unknown;
    this.triggersCaseHold = args?.triggersCaseHold ?? false;
    this.indicatesCompletion = args?.indicatesCompletion ?? false;
  }

  public get filterValue() {
    return this.serviceType;
  }
}
