// See: https://gist.github.com/metafloor/773bc61480d1d05a976184d45099ef56
export const ZebraPrinterHostQuery = {
  CheckStatus: '~HQES',
  GetHardwareAddress: '~HQHA',
  GetPrintHeadSummary: '~HQJT',
  GetCurrentSettings: '~HQMA',
  GetSerialNumber: '~HQSN',
  GetProductIdAndVersion: '~HQUI',
} as const;

export const ZebraPrinterLabelDefinition = {
  DefinitionStart: '^XA',
  DefinitionEnd: '^XZ',
} as const;
