export const SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP = Symbol(
  'Toolbar.ButtonRadioGroup'
);

export const SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_VALUE = Symbol(
  'Toolbar.ButtonRadioGroup.value'
);

export const SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_NAME = Symbol(
  'Toolbar.ButtonRadioGroup.name'
);
