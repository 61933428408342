













































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import CheckListItem from './checklist-item.vue';

export interface ChecklistItem {
  id?: string | number;
  label: string;
  value: unknown;
  description?: string;
  required?: boolean;
  disabled?: boolean;
}

export default defineComponent({
  name: 'Checklist',
  components: {
    CheckListItem,
  },
  props: {
    value: {
      type: Array as PropType<any[]>,
      required: false,
      default: () => [],
    },
    items: {
      type: Array as PropType<ChecklistItem[]>,
      required: false,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
      default: undefined,
    },
    heading: {
      type: String,
      required: false,
      default: undefined,
    },
    description: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    function hasId(item: ChecklistItem) {
      return ['string', 'number'].includes(typeof item.id);
    }

    return { hasId };
  },
});
