import { state, ICaseDetailsState } from './state';
import { getters, Getter } from './getters';
import { mutations, Mutation } from './mutations';
import { actions, Action } from './actions';
import { createModule } from '~/store';

export { Getter, Mutation, Action };
export type { ICaseDetailsState };

export const moduleName = 'case';
export const { module } = createModule<ICaseDetailsState>(moduleName, {
  state,
  getters,
  mutations,
  actions,
});
