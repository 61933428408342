import { PluginObject } from 'vue';
import WbDeleteConfirm from './delete-confirm.vue';
import WbDeletePopConfirm from './delete-popconfirm.vue';

const plugin: PluginObject<void> = {
  install(vue) {
    vue.component('WbDeleteConfirm', WbDeleteConfirm);
    vue.component('WbDeletePopConfirm', WbDeletePopConfirm);
  },
};

export { WbDeleteConfirm, WbDeletePopConfirm };
export default plugin;
