import Vue, { PluginObject, VueConstructor } from 'vue';
import TabBar from './tab-bar.vue';
import TabBarItem from './tab-bar-item.vue';
import TabBarMenu from './tab-bar-menu.vue';

const tabBar: VueConstructor<Vue> & PluginObject<void> = TabBar as any;

tabBar.install = function (vue) {
  vue.component('WbTabBar', TabBar);
  vue.component('WbTabBarItem', TabBarItem);
  vue.component('WbTabBarMenu', TabBarMenu);
};

export { TabBar, TabBarItem, TabBarMenu };
export default tabBar;
