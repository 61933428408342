var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-button',_vm._g(_vm._b({staticClass:"wb-btn btn",class:{
    'btn-xs': _vm.size === 'xs',
    'btn-sm': _vm.size === 'sm',
    'btn-md': _vm.size === 'md',
    'btn-lg': _vm.size === 'lg',
    'btn-primary': _vm.variant === 'primary',
    'btn-ghost': _vm.variant === 'ghost',
    'btn-brand': _vm.variantColor === 'brand' || _vm.color === 'brand',
    'btn-accent': _vm.variantColor === 'accent' || _vm.color === 'accent',
    'btn-neutral': _vm.variantColor === 'neutral' || _vm.color === 'neutral',
    'btn-success': _vm.variantColor === 'success' || _vm.color === 'success',
    'btn-warning': _vm.variantColor === 'warning' || _vm.color === 'warning',
    'btn-danger': _vm.variantColor === 'danger' || _vm.color === 'danger',
    'btn-green': _vm.variantColor === 'green' || _vm.color === 'green',
    'btn-gray': _vm.variantColor === 'gray' || _vm.color === 'gray',
  }},'a-button',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }