







import { defineComponent, PropType } from '@nuxtjs/composition-api';
import WbFormItemError from './form-item-error.vue';

export default defineComponent({
  name: 'FormItemContent',
  components: {
    WbFormItemError,
  },
  props: {
    errors: {
      type: Array as PropType<Array<string | Error>>,
      required: false,
      default: () => [],
    },
  },
});
