export enum MassUnit {
  Unknown = 0,
  Centigram = 1,
  Decagram = 2,
  Decigram = 3,
  EarthMass = 4,
  Femtogram = 35,
  Grain = 5,
  Gram = 6,
  Hectogram = 7,
  Kilogram = 8,
  Kilopound = 9,
  Kilotonne = 10,
  LongHundredweight = 11,
  LongTon = 12,
  Megapound = 13,
  Megatonne = 14,
  Microgram = 0xf,
  Milligram = 0x10,
  Nanogram = 17,
  Ounce = 18,
  Picogram = 29,
  Pound = 19,
  ShortHundredweight = 20,
  ShortTon = 21,
  Slug = 22,
  SolarMass = 23,
  Stone = 24,
  Tonne = 25,
}
