import type { Moment } from 'moment';
import { parse } from 'date-fns';

function isNaN(v: any): boolean {
  // eslint-disable-next-line no-self-compare
  return typeof v !== 'number' || v !== v;
}

export class DateOnly {
  private date!: number;
  private month!: number;
  private year!: number;

  public static readonly ISO_PATTERN = 'yyyy-MM-dd' as const;

  constructor(date?: string | number | Date | DateOnly | Moment) {
    let value: Date | null = null;

    if (typeof date === 'string') {
      value = new Date(date); // date-fns.parse(date, 'yyyy-MM-dd', new Date());
    } else if (typeof date === 'number') {
      value = DateOnly.numberToDate(date);
    } else if (date instanceof DateOnly) {
      value = date.toDate();
    } else if (date && date.constructor && date.constructor.name === 'Moment') {
      value = DateOnly.momentToDate(date as Moment);
    } else if (date instanceof Date) {
      value = date;
    }

    this.setDateParts(value || new Date());
  }

  public static from(year: number, month: number, day: number) {
    const pad = DateOnly.pad;
    const dateString = `${pad(year, 4)}-${pad(month, 2)}-${pad(day, 2)}`;
    const date = parse(dateString, this.ISO_PATTERN, new Date());
    return new DateOnly(date);
  }

  public static toDate(dateOnlyStamp: number): Date {
    return new DateOnly(dateOnlyStamp).toDate();
  }

  public getDate(): number {
    return this.date;
  }

  public setDate(date: number): void {
    this.date = date;
  }

  public getMonth(): number {
    return this.month;
  }

  public setMonth(month: number): void {
    this.month = month;
  }

  public getFullYear(): number {
    return this.year;
  }

  public setFullYear(year: number): void {
    this.year = year;
  }

  public getDay(): number {
    return this.toDate().getDay();
  }

  public toDate(): Date {
    return DateOnly.partsToDate(this.year, this.month, this.date);
  }

  public valueOf(): number {
    if (isNaN(this.year) || isNaN(this.month) || isNaN(this.date)) {
      return NaN;
    }

    const year = DateOnly.pad(this.year, 4);
    const month = DateOnly.pad(this.month, 2);
    const date = DateOnly.pad(this.date, 2);

    return parseInt(year + month + date, 10);
  }

  public equals(date: DateOnly): boolean {
    return this.valueOf() === date.valueOf();
  }

  public toString(): string {
    return this.toDate().toDateString();
  }

  public toISOString(): string {
    const year = DateOnly.pad(this.year, 4);
    const month = DateOnly.pad(this.month + 1, 2);
    const date = DateOnly.pad(this.date, 2);

    return `${year}-${month}-${date}`;
  }

  public toJSON(): string {
    return this.toISOString();
  }

  private setDateParts(date: Date): void {
    this.date = date.getDate();
    this.month = date.getMonth();
    this.year = date.getFullYear();
  }

  private static pad(num: number, length: number): string {
    let numStr = num.toString();
    while (numStr.length < length) {
      numStr = '0' + numStr;
    }
    return numStr;
  }

  private static numberToDate(n: number): Date {
    const s = n.toString();

    if (s.length === 8) {
      const year = parseInt(s.slice(0, 4), 10);
      const month = parseInt(s.slice(4, 6), 10) - 1;
      const day = parseInt(s.slice(6, 8), 10);

      return DateOnly.partsToDate(year, month, day);
    } else {
      return new Date(n);
    }
  }

  private static momentToDate(m: Moment): Date {
    return m.toDate();
  }

  private static partsToDate(year: number, month: number, day: number): Date {
    const date = new Date(year, month, day);
    date.setHours(0, 0, 0, 0);
    return date;
  }
}
