import Vue, { VueConstructor, PluginObject } from 'vue';
import WbDivider from './divider.vue';

const divider: VueConstructor<Vue> & PluginObject<void> = WbDivider as any;
divider.install = function (vue) {
  vue.component('WbDivider', WbDivider);
};

export { WbDivider };
export default divider;
