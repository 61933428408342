import { MutationTree } from 'vuex';
import { IBodyboxState } from './state';
import { Bodybox } from '~/app/models';

export enum Mutation {
  setBodyboxes = 'SET_BODYBOXES',
  updateBodybox = 'UPDATE_BODYBOX',
  removeBodybox = 'REMOVE_BODYBOX',
}

export const mutations: MutationTree<IBodyboxState> = {
  [Mutation.setBodyboxes](state, boxes: Bodybox[]) {
    state.bodyboxes = boxes;
  },
  [Mutation.updateBodybox](state, bodybox: Bodybox) {
    const index = state.bodyboxes?.findIndex((box) => box.id === bodybox.id);

    if (state.bodyboxes && index) {
      if (index < 0) state.bodyboxes.push(bodybox);
      else state.bodyboxes.splice(index, 1, bodybox);
    } else {
      state.bodyboxes = [bodybox];
    }
  },
  [Mutation.removeBodybox](state, bodybox: Bodybox) {
    const index = state.bodyboxes?.findIndex((box) => box.id === bodybox.id);

    if (state.bodyboxes && index) {
      if (index === -1) return;
      state.bodyboxes.splice(index, 1);
    }
  },
};
