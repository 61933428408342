






























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { Placement } from 'tippy.js';
import ToolbarButton from './toolbar-button.vue';

export default defineComponent({
  name: 'ToolbarDropdown',
  components: {
    ToolbarButton,
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    placement: {
      type: String as PropType<Placement>,
      required: false,
      default: 'bottom',
    },
  },
  setup() {
    const menuTrigger = ref<typeof ToolbarButton>();
    return { menuTrigger };
  },
});
