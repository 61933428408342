import { Type } from 'class-transformer';

export class NoteRecord {
  public readonly id?: string;

  public author!: string;

  public content!: string;

  public tagIds?: string[];

  @Type(() => Date)
  public dateTimeAuthored!: Date;

  constructor(args: NoteRecord) {
    if (!args) return;
    this.id = args.id;
    this.author = args.author;
    this.content = args.content;
    this.dateTimeAuthored = args.dateTimeAuthored;
    this.tagIds = args.tagIds;
  }
}

export class NotesCollection {
  @Type(() => NoteRecord)
  public records: NoteRecord[];

  constructor(args?: Partial<NotesCollection>) {
    this.records = args?.records || [];
  }
}
