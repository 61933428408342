import ky from 'ky';
import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { Tenant } from '~/app/models';
import {
  ITenantState,
  moduleName as TenantStoreName,
  Action,
  Mutation,
} from '~/store/tenant';

export default defineNuxtMiddleware(
  async ({ $language, $log, store, error }) => {
    const state = store.state.tenant as ITenantState;

    if (state.tenant) return;

    try {
      const tenant: Tenant = await store.dispatch(
        `${TenantStoreName}/${Action.fetchTenant}`
      );

      store.commit(`${TenantStoreName}/${Mutation.setTenant}`, tenant);

      $language.updateLanguage(tenant.configuration.language);
    } catch (err) {
      if (err instanceof ky.HTTPError) {
        if (err.response.status === 403)
          return error({
            statusCode: 403,
            message: 'You do not have access to this organization.',
          });

        $log.error(err as Error, undefined, { ui: false });

        return error({
          statusCode: err.response.status,
          message: err.response.statusText,
        });
      }

      $log.error(err as Error, undefined, { ui: false });

      return error({
        statusCode: err instanceof TypeError ? 500 : 403,
        message:
          'An unexpected error has occurred. Please try again in a few minutes. If the issue persists, please contact support.',
      });
    }
  }
);
