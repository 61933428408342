import { message, Modal } from 'ant-design-vue';

export const useSuccessModal = () => Modal.success;
export const useInfoModal = () => Modal.info;
export const useErrorModal = () => Modal.error;
export const useWarningModal = () => Modal.warning;
export const useConfirmModal = () => Modal.confirm;

export const useLoadingMessage = () => message.loading;
export const useSuccessMessage = () => message.success;
export const useInfoMessage = () => message.info;
export const useErrorMessage = () => message.error;
export const useWarningMessage = () => message.warning;
export const useMessage = () => message.open;
