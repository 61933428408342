import { PluginObject } from 'vue';
import WbLogoText from './logo-text.vue';
import WbLogoImage from './logo-image.vue';

const plugin: PluginObject<void> = {
  install(vue) {
    vue.component('WbLogoText', WbLogoText);
    vue.component('WbLogoImage', WbLogoImage);
  },
};

export { WbLogoText, WbLogoImage };
export default plugin;
