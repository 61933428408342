import { Address } from '../address';
import { FilterableDictionaryItem } from './dictionary-item';

export enum AssociateOrganizationType {
  Unknown = 0,
  AutopsyVendor = 1,
  FuneralHome = 2,
  OrganBank = 3,
  MedicalExaminer = 4,
}

export class OrganizationDictionaryItem extends FilterableDictionaryItem {
  public readonly type!: AssociateOrganizationType;
  public readonly address?: Address;
  public readonly contactPhoneNumber?: string;
  public readonly contactFaxNumber?: string;
  public readonly contactEmailAddress?: string;

  public get filterValue() {
    return this.type;
  }

  constructor(args?: Omit<OrganizationDictionaryItem, 'filterValue'>) {
    super(args);
    this.type = args?.type ?? AssociateOrganizationType.Unknown;
    this.address = args?.address ? new Address(args.address) : undefined;
    this.contactPhoneNumber = args?.contactPhoneNumber;
    this.contactFaxNumber = args?.contactFaxNumber;
    this.contactEmailAddress = args?.contactEmailAddress;
  }
}
