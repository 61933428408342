import { Type } from 'class-transformer';
import { AppAbilitySubject } from '~/app/core/casl';

export class AccessScopeDescription {
  public readonly scope!: string;
  public readonly description!: string;
}

export class AccessScopeMasterList {
  @Type(() => AccessScopeDescription)
  public readonly [AppAbilitySubject.case]!: Readonly<AccessScopeDescription[]>;

  @Type(() => AccessScopeDescription)
  public readonly [AppAbilitySubject.bodybox]!: Readonly<
    AccessScopeDescription[]
  >;

  @Type(() => AccessScopeDescription)
  public readonly [AppAbilitySubject.dictionary]!: Readonly<
    AccessScopeDescription[]
  >;

  @Type(() => AccessScopeDescription)
  public readonly [AppAbilitySubject.user]!: Readonly<AccessScopeDescription[]>;

  @Type(() => AccessScopeDescription)
  public readonly [AppAbilitySubject.report]!: Readonly<
    AccessScopeDescription[]
  >;
}
