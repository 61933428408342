import { state, IBodyboxState } from './state';
import { getters, Getter } from './getters';
import { mutations, Mutation } from './mutations';
import { actions, Action } from './actions';
import { createModule } from '~/store';

export { Getter, Mutation, Action };
export type { IBodyboxState };

export const moduleName = 'bodybox';
export const { module } = createModule<IBodyboxState>(moduleName, {
  state,
  getters,
  mutations,
  actions,
});
