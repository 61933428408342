import { useStoreModule } from './use-store-module';
import { ITenantState, moduleName as TenantModuleName } from '~/store/tenant';
import { Tenant, TenantConfiguration } from '~/app/models';

export function useTenantConfig() {
  const tenantStore = useStoreModule<ITenantState>(TenantModuleName);
  const tenant = tenantStore.state.tenant as Tenant;
  const config: TenantConfiguration = tenant.configuration;
  return config;
}

export function useTenantConfigCaseSection<
  K extends keyof TenantConfiguration['cases']
>(section: K) {
  const tenantStore = useStoreModule<ITenantState>(TenantModuleName);
  const tenant = tenantStore.state.tenant as Tenant;
  const config = tenant.configuration.cases;
  return config[section];
}
