import { Type } from 'class-transformer';
import { IIdentifiable } from './interfaces';

export enum TagType {
  Case = 0,
  Hold = 1,
  Note = 2,
}

export class TagDisplayOptions {
  public readonly color!: string;
  public readonly colorLevel!: number;

  constructor(args?: TagDisplayOptions) {
    if (!args) return;
    this.color = args.color;
    this.colorLevel = args.colorLevel;
  }
}

export class DisplayTag {
  public readonly label!: string;

  @Type(() => TagDisplayOptions)
  public readonly displayOptions?: TagDisplayOptions;

  public get isDefault() {
    return this.displayOptions?.color === 'default';
  }

  constructor(args?: Pick<DisplayTag, 'label' | 'displayOptions'>) {
    if (!args) return;
    this.label = args.label;
    this.displayOptions = args.displayOptions;
  }
}

export class Tag implements IIdentifiable {
  public id!: string;
  public type!: TagType;
  public label!: string;
  public displayOptions?: TagDisplayOptions;

  constructor(args: Tag) {
    if (!args) return;
    if (!args.id) throw new Error('property "id" is required.');
    if (!args.type) throw new Error('property "type" is required.');
    if (!args.label) throw new Error('property "label" is required.');

    this.id = args.id;
    this.type = args.type;
    this.label = args.label;
    this.displayOptions = args.displayOptions;
  }
}
