import { message as toast } from 'ant-design-vue';
import type { MessageOptions as ToastOptions } from 'ant-design-vue/types/message';
import { ILogger, LoggerArg } from '../ILogger';

export type { ToastOptions };

export interface IToastLogger extends ILogger {
  log(message: string, data?: LoggerArg, options?: ToastOptions): void;
  info(message: string, data?: LoggerArg, options?: ToastOptions): void;
  warn(message: string, data?: LoggerArg, options?: ToastOptions): void;
  debug(message: string, data?: LoggerArg, options?: ToastOptions): void;
  error(
    message: string | Error,
    data?: LoggerArg,
    options?: ToastOptions
  ): void;
}

export class ToastLogger implements ILogger {
  public log(message: string, data?: LoggerArg, options?: ToastOptions) {
    this.createToast('info', message, data, options);
  }

  public info(message: string, data?: LoggerArg, options?: ToastOptions) {
    this.createToast('info', message, data, options);
  }

  public warn(message: string, data?: LoggerArg, options?: ToastOptions) {
    this.createToast('info', message, data, options);
  }

  public debug(message: string, data?: LoggerArg, options?: ToastOptions) {
    if (process.env.NODE_ENV === 'development') {
      this.createToast('warning', message, data, options);
    }
  }

  public error(
    message: string | Error,
    data?: LoggerArg,
    options?: ToastOptions
  ) {
    const error = message instanceof Error ? message : new Error(message);
    this.createToast('error', error.message, data, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public track(event: string, data?: LoggerArg) {
    // do nothing.
  }

  private createToast(
    type: ToastOptions['type'],
    message: string,
    _data?: LoggerArg,
    options?: ToastOptions
  ) {
    if (process.server) return;
    toast.open({ ...(options || {}), content: message, type });
  }
}
