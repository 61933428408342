import Vue, { VueConstructor, PluginObject } from 'vue';
import WbPaginator from './paginator.vue';
import WbPaginationSummary from './pagination-summary.vue';

const paginator: VueConstructor<Vue> & PluginObject<void> = WbPaginator as any;
paginator.install = function (vue) {
  vue.component('WbPaginator', WbPaginator);
  vue.component('WbPaginationSummary', WbPaginationSummary);
};

export { WbPaginator, WbPaginationSummary };
// export * from './types';
export default paginator;
