




import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ToolbarSpacer',
  props: {
    grow: {
      type: Number as PropType<number>,
      required: false,
      default: 1,
    },
  },
});
