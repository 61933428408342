




















import {
  computed,
  defineComponent,
  inject,
  onDeactivated,
  PropType,
  provide,
  Ref,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { onClickOutside } from '@vueuse/core';
import {
  SYMBOL_TAB_BAR_MENU_ACTIVE_LEVEL,
  SYMBOL_TAB_BAR_MENU_IS_CHILD,
  SYMBOL_TAB_BAR_MENU_LEVEL,
  SYMBOL_TAB_BAR_MENU_SET_LAST_LEVEL,
} from './symbols';
import TabBarMenuTrigger from './tab-bar-menu-trigger.vue';

export default defineComponent({
  name: 'TabBarMenu',
  components: { TabBarMenuTrigger },
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(_props, _context) {
    const root = ref<HTMLDivElement>();
    const isOpen = ref(false);
    onClickOutside(root, () => (isOpen.value = false));

    const getLevels = () => inject(SYMBOL_TAB_BAR_MENU_LEVEL, 1);
    const level = getLevels();
    provide(SYMBOL_TAB_BAR_MENU_LEVEL, level + 1);
    provide(SYMBOL_TAB_BAR_MENU_IS_CHILD, true);

    const setLevels = inject<(level: number) => void>(
      SYMBOL_TAB_BAR_MENU_SET_LAST_LEVEL
    );

    setLevels?.(level);
    onDeactivated(() => setLevels?.(getLevels() - 1));

    const activeLevel = inject<Ref<number>>(SYMBOL_TAB_BAR_MENU_ACTIVE_LEVEL);
    const isLastLevel = computed(() => activeLevel?.value === level);

    watch(activeLevel!, (newLevel) => {
      if (newLevel < level) {
        isOpen.value = false;
      }
    });

    const toggleVisibilty = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        activeLevel && (activeLevel.value = level);
      } else {
        activeLevel && (activeLevel.value = level - 1);
      }
    };

    return { root, level, isOpen, toggleVisibilty, isLastLevel };
  },
});
