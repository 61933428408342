import { Store, Module } from 'vuex';
import { IAccessState, moduleName as AccessStoreModuleName } from './access';
import {
  IDictionariesState,
  moduleName as DictionariesStoreName,
} from './dictionaries';
import { moduleName as TenantModuleName, ITenantState } from './tenant';
import {
  moduleName as CaseDetailsStoreName,
  ICaseDetailsState,
} from '~mod:case-details/store';
import {
  moduleName as BodyboxStoreName,
  IBodyboxState,
} from '~mod:bodybox/store';
import {
  moduleName as SettingsModuleName,
  ISettingsState,
} from '~mod:settings/store';

export type ModuleState = {
  [AccessStoreModuleName]: IAccessState;
  [BodyboxStoreName]: IBodyboxState;
  [CaseDetailsStoreName]: ICaseDetailsState;
  [DictionariesStoreName]: IDictionariesState;
  [SettingsModuleName]: ISettingsState;
  [TenantModuleName]: ITenantState;
};

export type RootState = {} & ModuleState;

export type AppStore = Store<RootState> & ModuleState;

export function createModule<TState>(
  moduleName: keyof ModuleState,
  modulePartial: Omit<Module<TState, RootState>, 'namespaced'>
) {
  const module: Module<TState, RootState> = {
    namespaced: true,
    ...modulePartial,
  };

  return { module, moduleName };
}

export function createSubModule<
  TState,
  TParentModule = Module<TState, RootState>['modules']
>(
  moduleName: keyof TParentModule,
  modulePartial: Omit<Module<TState, RootState>, 'namespaced'>
) {
  const module: Module<TState, RootState> = {
    namespaced: true,
    ...modulePartial,
  };

  return { module, moduleName };
}
