import { Context } from '@nuxt/types';
import { computed, ref, Ref } from '@nuxtjs/composition-api';
import { AppModule, AppModuleOptions } from './module';

declare type ModuleClassType<T, TState = any> = new (
  context: Context,
  options?: AppModuleOptions<TState>,
  ...args: any[]
) => T;

type RegisterModuleTuple<TState = any> = [
  ModuleClassType<AppModule<TState>>,
  AppModuleOptions<TState> | undefined
];

export const registeredModules = new Map<string, RegisterModuleTuple>();
const moduleInstances = new Map<string, AppModule>();
const activeModules = ref<string[]>([]);

export const initModuleSystem = (context: Context) => {
  // TODO: Support async activation methods .forEach -> for (...)
  registeredModules.forEach(([ModuleCtor, options]) => {
    const module = new ModuleCtor(context, options);
    moduleInstances.set(ModuleCtor.name, module);

    if (options?.activationMethod !== 'route-enter') {
      module.activate();
    }
  });
};

export const getRegisteredModules = (): Readonly<AppModule[]> => {
  const modules: Readonly<AppModule>[] = [];
  for (const module of moduleInstances.values()) {
    modules.push(module);
  }
  return Object.freeze(modules) as Readonly<AppModule[]>;
};

export const getActiveModules = (): Readonly<AppModule[]> => {
  return getActiveModulesRef().value;
};

export const getActiveModulesRef = (): Ref<Readonly<AppModule[]>> => {
  return computed(() => {
    const modules = activeModules.value
      .map<AppModule | undefined>((name) => moduleInstances.get(name))
      .filter((module) => !!module);

    return Object.freeze(modules) as Readonly<AppModule[]>;
  });
};

export const registerModule = <TModule extends AppModule, TState = undefined>(
  ModuleCtor: ModuleClassType<TModule>,
  options?: AppModuleOptions<TState>
) => {
  const name = ModuleCtor.name;
  registeredModules.set(name, [ModuleCtor, options]);
};

export const useModule = <TModule extends AppModule>(
  ModuleCtorOrStringName: ModuleClassType<TModule> | string
) => {
  const name =
    typeof ModuleCtorOrStringName === 'string'
      ? ModuleCtorOrStringName
      : ModuleCtorOrStringName.name;

  if (moduleInstances.has(name)) {
    const module = moduleInstances.get(name) as AppModule;
    !module.isActive && module.activate();

    if (!activeModules.value.includes(name)) {
      activeModules.value.push(name);
    }

    return module as TModule;
  }

  throw new Error(
    `AppModule with name "${name}" could not be found. Ensure this was registering at startup.`
  );
};

export const deactivateModule = <TModule extends AppModule>(
  ModuleCtorOrStringName: ModuleClassType<TModule> | string
) => {
  const name =
    typeof ModuleCtorOrStringName === 'string'
      ? ModuleCtorOrStringName
      : ModuleCtorOrStringName.name;

  const moduleIndex = activeModules.value.indexOf(name);
  const isModuleActive = moduleIndex > -1;
  const doesModuleInstanceExist = moduleInstances.has(name);

  if (isModuleActive) activeModules.value.splice(moduleIndex, 1);

  if (!doesModuleInstanceExist)
    throw new Error(
      `AppModule with name "${name}" could not be found. Ensure this was registering at startup.`
    );

  const module = moduleInstances.get(name) as AppModule;

  if (!isModuleActive || !module.isActive)
    throw new Error(
      `AppModule with name "${name}" is already in an inactive state.`
    );

  module.deactivate();
};
