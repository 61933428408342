import Vue from 'vue';
import { computed, reactive } from '@vue/composition-api';
import createAuth0Client, {
  Auth0Client,
  User as Auth0User,
} from '@auth0/auth0-spa-js';
import { AuthEvent, AuthEventBus } from './events';
import { AuthTaskQueue, AuthTaskQueueEntry } from './events/task-queue';
import {
  AuthProviderLoginMiddlewareFunction,
  AuthProviderMiddlewareFunction,
  IAuthProviderMiddleware,
  IAuthProviderOptions,
  ICreateAuthClientOptions,
  GetTokenOptions,
  GetUserOptions,
  AuthProviderMiddlewareContext,
  InvitationContext,
} from './types/service';

async function createClientAsync(config: ICreateAuthClientOptions) {
  const client = await createAuth0Client({
    domain: config.domain,
    audience: config.audience,
    client_id: config.clientId,
    redirect_uri: config.redirectUrl,
    organization: config.organizationId,
  });

  return client;
}

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (_appState?: any) =>
  // Remove redirect params from url to maintain a cleaner history
  window.history.replaceState({}, document.title, window.location.pathname);

export class AuthProvider {
  private readonly client: Auth0Client;
  private readonly options: IAuthProviderOptions;
  private readonly middleware: Required<IAuthProviderMiddleware> = {
    afterLogin: [],
  };

  private state = reactive<{ isAuthenticated: boolean; user?: Auth0User }>({
    isAuthenticated: false,
    user: undefined,
  });

  private readonly eventQueues: {
    [eventName in AuthEvent]?: AuthTaskQueue[];
  } = {};

  private readonly eventEmitter = new Vue();
  public readonly events = new AuthEventBus(this.eventEmitter);

  constructor(client: Auth0Client, options: IAuthProviderOptions) {
    if (!client) throw new Error('Auth0Client is required.');
    if (!options) throw new Error('AuthProvider options is required.');
    this.client = client;
    this.options = {
      ...options,
      redirectCallback: options.redirectCallback || DEFAULT_REDIRECT_CALLBACK,
    };

    // Add any pre-registered middleware.
    if (this.options.middleware) {
      const middlewares = Object.entries(this.options.middleware) as [
        keyof IAuthProviderMiddleware,
        Function[] | undefined
      ][];

      middlewares.forEach(([event, fnStack]) => {
        if (!fnStack?.length) return;
        (this.middleware[event] as unknown) = fnStack;
      });
    }
  }

  public get isAuthenticated() {
    return computed(() => this.state.isAuthenticated);
  }

  public get user() {
    return computed(() => this.state.user);
  }

  /**
   * Logs the user in creating a session on the Auth0 server.
   * @param state And optional state object to be persisted across session creation and passed to the redirect callback.
   */
  public async loginAsync<TState = any>(state?: TState) {
    try {
      await this.client.loginWithRedirect({
        redirect_uri: this.options.redirectUrl,
        appState: state,
      });
      this.state.isAuthenticated = true;
    } catch (error) {
      this.state.isAuthenticated = false;
    }
  }

  /**
   * Logs the user out of the application and removes their session from the Auth0 server.
   * @param options Logout options.
   */
  public logoutAsync(options?: { returnToUrl?: string }) {
    this.client.logout({
      returnTo: options?.returnToUrl || this.options.logoutUrl,
    });
    this.state.isAuthenticated = false;
  }

  /**
   * Core function which executes the authentication pipeline ensuring a managed authenticated session is created.
   */
  public async setupAuthAsync<TRedirectState = unknown>() {
    const {
      state: redirectState,
      error: redirectError,
    } = await this.tryHandleRedirectAsync<TRedirectState>();

    const error: string | undefined = redirectError?.message;

    const { isRedirect, error: loginError } = this.isLoginRedirect();

    if (redirectError || loginError) {
      return {
        redirectState: undefined,
        error: redirectError?.message || loginError,
      };
    }

    if (await this.tryHandleInvitationAsync()) {
      // never resolve, wait for login redirect
      return new Promise<{
        redirectState: TRedirectState;
        error: string | undefined;
      }>(() => ({ redirectState, error }));
    }

    // Indicates if the user is either returning from login or has an existing session that needs to be be reinitialized.
    const newSession = !this.state.isAuthenticated;
    const isPostLogin = isRedirect || newSession;

    // Set internal state from session.
    const { isAuthenticated } = await this.setAuthStateAsync();

    if (isPostLogin && isAuthenticated) {
      const dispatchLoginEvent = new AuthTaskQueueEntry(() =>
        this.eventEmitter.$emit(AuthEvent.Login, this.state.user, redirectState)
      );

      const loginTaskQueue = new AuthTaskQueue([dispatchLoginEvent]);
      this.addEventTaskQueue(AuthEvent.Login, loginTaskQueue);

      const middlewareContext: AuthProviderMiddlewareContext = {
        provider: this,
        events: loginTaskQueue,
      };

      for (const middleware of this.middleware.afterLogin) {
        await Promise.resolve(
          middleware(middlewareContext, this.state.user as Auth0User)
        );
      }

      this.processEventTaskQueue(AuthEvent.Login);
    }

    return { redirectState, error };
  }

  /**
   * Retrieves the current user from the Auth0 server.
   * @returns A promise that resolves to an Auth0 user object.
   */
  public async getUserAsync(options: GetUserOptions = { ignoreCache: false }) {
    try {
      if (options.ignoreCache) await this.getTokenAsync({ ignoreCache: true });

      const user = await this.client.getUser();
      this.state.user = user;

      this.eventEmitter.$emit(AuthEvent.FetchUser, user);

      return user;
    } catch (error) {
      this.state.user = undefined;
      throw error;
    }
  }

  public getUserClaim(name: string) {
    if (!this.state.user)
      throw new Error(
        'A user does not exist. A login session must first be created.'
      );

    const namespace = this.options.claimsNamespace;
    const claim = namespace ? `${namespace}/${name}` : name;

    return this.state.user[claim];
  }

  /**
   * Retrieves an authentication token from the current session.
   * @param force Force retrieve token from server, ignoring local cache.
   * @returns A promise that resolves to a JWT token.
   */
  public async getTokenAsync(
    options: GetTokenOptions = { ignoreCache: false }
  ) {
    try {
      const token = await this.client.getTokenSilently({
        scope: this.options.scope,
        audience: this.options.audience,
        ignoreCache: options.ignoreCache,
      });

      this.eventEmitter.$emit(AuthEvent.TokenRefresh, token);

      return token;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  /* eslint-disable no-dupe-class-members */
  /**
   * Registers a function to be executed at a specified event. Returning a promise will block process execution until the promise is resolved.
   * @param name The name of the middleware event to add middleware to.
   * @param fn The middleware function to be executed.
   * @param index Optional, index within the middleware queue to insert the middleware at.
   */
  public addMiddleware(
    name: keyof Pick<IAuthProviderMiddleware, 'afterLogin'>,
    fn: AuthProviderLoginMiddlewareFunction,
    index?: number
  ): void;

  public addMiddleware(
    name: keyof IAuthProviderMiddleware,
    fn: AuthProviderMiddlewareFunction,
    index?: number
  ) {
    if (!(name in this.middleware))
      throw new Error(`Middleware does not exist for "${name}".`);

    if (typeof index === 'number') {
      (this.middleware[name] as unknown[]).splice(index, 0, fn);
    } else {
      (this.middleware[name] as unknown[]).push(fn);
    }
  }
  /* eslint-enable no-dupe-class-members */

  /**
   * Remove a registered middleware function from the specified event.
   * @param name The name of the middleware event to remove middleware from.
   * @param fn The middleware function to be removed.
   */
  public removeMiddleware(name: keyof IAuthProviderMiddleware, fn: Function) {
    if (!(name in this.middleware))
      throw new Error(`Middleware does not exist for "${name}".`);

    const fnIndex = (this.middleware[name] as unknown[]).indexOf(fn);
    this.middleware[name]!.splice(fnIndex, 1);
  }

  private addEventTaskQueue(event: AuthEvent, queue: AuthTaskQueue) {
    if (!this.eventQueues[event]) this.eventQueues[event] = [queue];
    else this.eventQueues[event]!.push();
  }

  private processEventTaskQueue(event: AuthEvent) {
    const queues = this.eventQueues[event];

    if (!queues?.length)
      throw new Error(`AuthEventQueue for event "${event}" does not exist.`);

    // Retrieve highest priority queue
    const queue = queues.reduce((highest, current) => {
      if (current.priority > highest.priority) return current;
      return highest;
    }, queues[0]);

    queue.process();

    if (queue.isDeferred) {
      queue.deferral?.then(() => delete this.eventQueues[event]);
      return;
    }

    delete this.eventQueues[event];
  }

  /**
   * Attempts to process the redirect workflow while executing user callbacks and notifying event subscribers of relevant events that occur within.
   * @returns Redirect state object if determined a redirect occurred, otherwise undefined.
   */
  private async tryHandleRedirectAsync<TRedirectState>() {
    const { isRedirect } = this.isLoginRedirect();
    if (!isRedirect) return { state: undefined, error: undefined };

    try {
      const redirectResult = await this.client.handleRedirectCallback();
      const redirectState: TRedirectState | undefined = redirectResult.appState;

      if (this.isRedirectFromInvitation(redirectState)) {
        this.eventEmitter.$emit(AuthEvent.InvitationAccepted, redirectState);
      }

      if (this.options.redirectCallback) {
        await Promise.resolve(this.options.redirectCallback(redirectState));
      }

      return { state: redirectState, error: undefined };
    } catch (error) {
      return {
        state: undefined,
        error: error instanceof Error ? error : new Error(error as any),
      };
    }
  }

  /**
   * Attempts to process the invitation workflow, logging the user in if an invite is found.
   * @returns A flag indicating if an invitation was parsed from the URL.
   */
  private async tryHandleInvitationAsync() {
    const { isInvitation, ...inviteContext } = this.tryParseInvitation();

    if (isInvitation) {
      const ctx = inviteContext as InvitationContext;
      await this.client.loginWithRedirect({
        invitation: ctx.invitation,
        organization: ctx.organization,
        appState: ctx,
      });
    }

    return isInvitation;
  }

  private isLoginRedirect() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const isRedirect = params.has('code') && params.has('state');

    let error: string | undefined;
    if (params.has('error')) {
      error = params.get('error') || 'Unexpected login error';
      params.has('error_description') &&
        (error += `: ${params.get('error_description')}`);
    }

    return { isRedirect, error };
  }

  private isRedirectFromInvitation(redirectState: any) {
    if (typeof redirectState !== 'object') return false;
    return 'invitation' in redirectState && 'organization' in redirectState;
  }

  private tryParseInvitation() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const invitation = params.get('invitation');
    const organization = params.get('organization');
    const organizationName = params.get('organization_name');

    const isInvitation = !!invitation && !!organization;
    return { isInvitation, invitation, organization, organizationName };
  }

  private async setAuthStateAsync() {
    const isAuthenticated = await this.client.isAuthenticated();

    this.state.isAuthenticated = isAuthenticated;

    if (isAuthenticated) {
      !this.state.user && (await this.getUserAsync());
    }

    return this.state;
  }
}

let _client: Auth0Client;
let _provider: AuthProvider;

export function useAuth(options: IAuthProviderOptions) {
  const client = () => _client;
  const provider = () => _provider;

  const createProviderAsync = async () => {
    _client = await createClientAsync(options);
    _provider = new AuthProvider(_client, options);
    return provider();
  };

  return {
    createProviderAsync,
    client,
    provider,
  };
}
