import { Type } from 'class-transformer';
import { DateTimeInfo, IsDateTimeInfo } from '../../dates';
import { CaseService } from './case-service';
import { CaseServiceCollection } from './case-services';

export class Authorization {
  public readonly requested?: boolean;

  @IsDateTimeInfo()
  public readonly completedOn?: DateTimeInfo;

  public readonly completedBy?: string;

  constructor(args: Partial<Authorization>) {
    this.requested = args?.requested;
    this.completedOn = args?.completedOn
      ? new DateTimeInfo(args.completedOn)
      : undefined;
    this.completedBy = args?.completedBy;
  }
}

export class MedicolegalInvestigation extends CaseService {
  @Type(() => Authorization)
  public readonly cremationViewing?: Authorization;

  constructor(args: Partial<MedicolegalInvestigation>) {
    super(args);
    this.cremationViewing = args?.cremationViewing;
  }
}

export class MedicolegalInvestigationCollection extends CaseServiceCollection<MedicolegalInvestigation> {
  @Type(() => MedicolegalInvestigation)
  public readonly instances!: MedicolegalInvestigation[];

  constructor(args?: Partial<MedicolegalInvestigationCollection>) {
    super(args);
    this.instances = args?.instances
      ? args.instances.map((i) => new MedicolegalInvestigation(i))
      : [];
  }
}
