


























import { computed, defineComponent } from '@nuxtjs/composition-api';
import TheAsideNavigation from './the-aside-navigation.vue';
import TheTenantLogo from '~/app/modules/layout/components/organisms/the-tenant-logo.vue';
import { useStoreModule } from '~/app/composables/use-store-module';
import { ITenantState, moduleName as TenantModuleName } from '~/store/tenant';

export default defineComponent({
  name: 'TheAside',
  components: {
    TheAsideNavigation,
    TheTenantLogo,
  },
  setup() {
    const { state: tenantState } = useStoreModule<ITenantState>(
      TenantModuleName
    );

    const tenantLogoUrl = computed(
      () => tenantState.tenant?.configuration.theme.logoUrl
    );

    return { tenantLogoUrl };
  },
});
