






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Card',
  props: {
    as: {
      type: String,
      required: false,
      default: 'div',
    },
  },
});
