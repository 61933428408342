





















import {
  computed,
  defineComponent,
  PropType,
  toRefs,
} from '@nuxtjs/composition-api';
import tinycolor from 'tinycolor2';

export default defineComponent({
  name: 'Tag',
  props: {
    as: {
      type: String as PropType<string>,
      required: false,
      default: 'div',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg'>,
      required: false,
      default: 'md',
      validator: (size: any) => ['xs', 'sm', 'md', 'lg'].includes(size),
    },
    type: {
      type: String as PropType<
        'default' | 'brand' | 'success' | 'warning' | 'danger' | 'dynamic'
      >,
      required: false,
      default: 'default',
      validator: (size: any) =>
        [
          'default',
          'brand',
          'success',
          'warning',
          'danger',
          'dynamic',
        ].includes(size),
    },
    color: {
      type: String as PropType<string>,
      required: false,
      default: () => undefined,
    },
  },
  setup(props) {
    const { type, color } = toRefs(props);
    const dynamicStyles = computed(() => {
      if (type.value !== 'dynamic') return undefined;
      return {
        'background-color': color.value,
        color: getTextColor(color.value),
      };
    });

    function getRGB(color: string | number) {
      return parseInt(color as any, 16) || (color as number);
    }

    function getsRGB(color: string) {
      return getRGB(color) / 255 <= 0.03928
        ? getRGB(color) / 255 / 12.92
        : Math.pow((getRGB(color) / 255 + 0.055) / 1.055, 2.4);
    }

    function getLuminance(hexColor: string) {
      return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
      );
    }

    function getContrast(foregroundColor: string, backgroundColor: string) {
      const L1 = getLuminance(foregroundColor);
      const L2 = getLuminance(backgroundColor);
      return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
    }

    function getTextColor(backgroundColor: string) {
      const whiteContrast = getContrast(backgroundColor, '#ffffff');
      const blackContrast = getContrast(backgroundColor, '#000000');

      const lightColor = '#ffffff';
      const darkColor = tinycolor(backgroundColor).isValid()
        ? tinycolor(backgroundColor).darken(48).toString()
        : '#000000';

      return whiteContrast > blackContrast ? lightColor : darkColor;
    }

    return { dynamicStyles };
  },
});
