import { nextTick } from '@nuxtjs/composition-api';

const enterClass = ['transform', 'scale-95', 'opacity-0'];
const enterActiveClass = ['transition', 'duration-100', 'ease-out'];
const enterToClass = ['transform', 'scale-100', 'opacity-100'];
const leaveClass = ['transform', 'scale-100', 'opacity-100'];
const leaveActiveClass = ['transition', 'duration-100', 'ease-in'];
const leaveToClass = ['transform', '!scale-95', '!opacity-0'];

export const applyEnterTransition = (el: HTMLElement, onEnd?: Function) => {
  el.classList.add(...enterClass);

  nextTick(() => {
    el.classList.remove(...enterClass);
    el.classList.add(...enterActiveClass);
  });

  requestAnimationFrame(() => {
    el.classList.add(...enterToClass);
  });

  setTimeout(async () => {
    await nextTick();
    removeEnterTransition(el);
    onEnd?.();
  }, 100);
};

const removeEnterTransition = (el: HTMLElement) => {
  el.classList.remove(...enterClass, ...enterActiveClass, ...enterToClass);
};

export const applyLeaveTransition = (el: HTMLElement, onEnd?: Function) => {
  el.classList.add(...leaveClass, ...leaveActiveClass);

  nextTick(() => {
    el.classList.remove(...leaveClass);
    el.classList.add(...leaveToClass);
  });

  setTimeout(async () => {
    await nextTick();
    removeLeaveTransition(el);
    onEnd?.();
  }, 100);
};

export const removeLeaveTransition = (el: HTMLElement) => {
  el.classList.remove(...leaveClass, ...leaveActiveClass, ...leaveToClass);
};
