export class DictionaryItem {
  public readonly id!: string;
  public readonly name!: string;
  public readonly externalId?: string;
  public readonly activated?: boolean;

  constructor(args?: DictionaryItem) {
    if (args) {
      this.id = args?.id;
      this.name = args?.name;
      this.externalId = args?.externalId;
      this.activated = args?.activated ?? true;
    }
  }
}

export abstract class FilterableDictionaryItem extends DictionaryItem {
  abstract get filterValue(): unknown;
}
