import Vue from 'vue';
import { AuthEvent } from './events';

export class AuthEventBus {
  private readonly bus: Vue;

  constructor(emitter: Vue) {
    this.bus = emitter;
  }

  public on(event: AuthEvent, callback: Function) {
    this.bus.$on(event, callback);
  }

  public once(event: AuthEvent, callback: Function) {
    this.bus.$once(event, callback);
  }

  public off(event?: AuthEvent, callback?: Function) {
    this.bus.$off(event, callback);
  }
}
