






import {
  defineComponent,
  computed,
  inject,
  PropType,
  toRefs,
  Ref,
  watch,
} from '@nuxtjs/composition-api';
import { TColumn } from 'gridjs/dist/src/types';
import { SortConfig } from 'gridjs/dist/src/view/plugin/sort/sort';
import { SYMBOL_GRID_INSTANCE_ID, SYMBOL_GRID_ADD_COLUMN } from './symbols';
import { createComponentFormatter } from './use-grid';

export default defineComponent({
  name: 'GridColumn',
  props: {
    id: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    name: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    data: {
      type: Function as PropType<((row: any) => any) | undefined>,
      required: false,
      default: undefined,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    sort: {
      type: [Boolean, Object] as PropType<boolean | SortConfig | undefined>,
      required: false,
      default: undefined,
    },
    hidden: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup(props, { slots }) {
    const instanceId = inject<string>(SYMBOL_GRID_INSTANCE_ID);
    const addSelfToGrid = inject<Function>(SYMBOL_GRID_ADD_COLUMN);

    const createSlotFormatter = () => {
      if (!slots.default) return undefined;

      return createComponentFormatter((cell, row, column) => {
        const nodes = slots.default!({ cell, row, column });
        return nodes.length > 1 ? (
          <div class="w-full">
            <v-nodes nodes={nodes} />
          </div>
        ) : (
          nodes[0]
        );
      }, instanceId);
    };

    const { id, name, data, sort, width, hidden } = toRefs(props);
    const config: Ref<TColumn> = computed(() => ({
      id: id.value,
      name: name.value,
      data: data.value,
      sort: sort.value
        ? typeof sort.value === 'boolean'
          ? { enabled: sort.value }
          : sort.value
        : undefined,
      width: width.value,
      hidden: hidden.value,
      formatter: createSlotFormatter(),
    }));

    watch(config, () => addSelfToGrid?.(config.value), { immediate: true });
  },
});
