import Vue, { PluginObject, VueConstructor } from 'vue';
import Form from './form.vue';
import FormItem from './form-item.vue';
import FormItemContent from './form-item-content.vue';
import FormItemError from './form-item-error.vue';
import FormRow from './form-row.vue';
import {
  usePartialForm,
  usePartialSelfManagedForm,
  usePartialFormProvider,
} from './use-partial-form';
import type { Rule, Rules } from './rules';
import './rules';

const form: VueConstructor<Vue> & PluginObject<void> = Form as any;

form.install = function (vue) {
  vue.component('WbForm', Form);
  vue.component('WbFormItem', FormItem);
  vue.component('WbFormItemContent', FormItemContent);
  vue.component('WbFormItemError', FormItemError);
  vue.component('WbFormRow', FormRow);
};

export type { Rule, Rules };

const Builder = {
  FormItemContent,
  FormItemError,
};

export {
  Form,
  FormItem,
  FormRow,
  usePartialForm,
  usePartialSelfManagedForm,
  usePartialFormProvider,
  Builder,
};
export default form;
