import Vue from 'vue';
import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { ConsoleLogger, LogProvider } from '~/app/core/logging';

export default defineNuxtPlugin(({ isDev }, inject) => {
  const provider = new LogProvider();

  isDev && provider.addLogger(new ConsoleLogger());

  Vue.prototype.$log = provider;
  inject('log', provider);
});
