import { CheckAvailablePrintersResponse } from './client-response';
import { BrowserPrintApiUrl } from './constants/api-url';
import {
  ZebraPrinterHostQuery,
  ZebraPrinterLabelDefinition,
} from './constants/commands';
import { PrintError } from './errors';
import type { Device, PrintInit } from './types';

export class ZebraPrintService {
  private readonly apiUrl: string;

  /** The target printer to send commands to. */
  private device?: Device;

  /** Indicates if the connection is to BrowserPrint is served over HTTPS. */
  public readonly isSSLConnection: boolean;

  constructor(options: PrintInit = { https: true }) {
    this.isSSLConnection = options.https;
    this.apiUrl = options.https
      ? BrowserPrintApiUrl.Https
      : BrowserPrintApiUrl.Http;
  }

  /** Gets a list of available Zebra printers. */
  public async getAvailablePrintersAsync() {
    const endpoint = this.apiUrl + '/available';

    try {
      const res = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8',
        },
      });

      const data: CheckAvailablePrintersResponse = await res.json();
      return data.printer || [];
    } catch (error) {
      if (error instanceof PrintError) throw error;
      throw new PrintError(error as any, this.device);
    }
  }

  /** Gets the default printer configured in the Zebra Browser Client app. */
  public async getDefaultPrinterAsync(): Promise<Device | null> {
    const endpoint = this.apiUrl + '/default';

    try {
      const res = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8',
        },
      });

      const data = await res.text();

      if (data && typeof data !== 'object' && data.split('\n\t').length === 7) {
        const deviceRaw = data.split('\n\t');

        const name = this.cleanUpString(deviceRaw[1]);
        const deviceType = this.cleanUpString(deviceRaw[2]);
        const connection = this.cleanUpString(deviceRaw[3]);
        const uid = this.cleanUpString(deviceRaw[4]);
        const provider = this.cleanUpString(deviceRaw[5]);
        const manufacturer = this.cleanUpString(deviceRaw[6]);

        return {
          connection,
          deviceType,
          manufacturer,
          name,
          provider,
          uid,
          version: 0,
        };
      }

      return null;
    } catch (error) {
      if (error instanceof PrintError) throw error;
      throw new PrintError(error as any, this.device);
    }
  }

  /** Sets the target printer to send commands to. */
  public setTargetPrinter(device?: Device): void {
    this.device = device;
  }

  /** Gets the target printer the service is configured to send commands to. */
  public getTargetPrinter(): Device | null {
    return this.device || null;
  }

  /** Checks the status of the printer. */
  public async checkStatusAsync() {
    await this.writeAsync(ZebraPrinterHostQuery.CheckStatus);
    const result = await this.readAsync();

    const errors: string[] = [];
    let isReadyToPrint = false;

    const isError = result.charAt(70);
    const media = result.charAt(88);
    const head = result.charAt(87);
    const pause = result.charAt(84);

    isReadyToPrint = isError === '0';

    switch (media) {
      case '1':
        errors.push('Paper out');
        break;
      case '2':
        errors.push('Ribbon Out');
        break;
      case '4':
        errors.push('Media Door Open');
        break;
      case '8':
        errors.push('Cutter Fault');
        break;
      default:
        break;
    }

    switch (head) {
      case '1':
        errors.push('Printhead Overheating');
        break;
      case '2':
        errors.push('Motor Overheating');
        break;
      case '4':
        errors.push('Printhead Fault');
        break;
      case '8':
        errors.push('Incorrect Printhead');
        break;
      default:
        break;
    }

    if (pause === '1') errors.push('Printer Paused');

    if (!isReadyToPrint && errors.length === 0)
      errors.push('Error: Unknown Error');

    return {
      ready: isReadyToPrint,
      errors: errors.join(),
    };
  }

  /** Sends a ZPL command to the printer for label printing. **/
  public async printAsync(zpl: string): Promise<void> {
    this.assertZplIsValidLabelDefinition(zpl);

    try {
      await this.writeAsync(zpl);
    } catch (error) {
      if (error instanceof PrintError) throw error;
      throw new PrintError(error as any, this.device);
    }
  }

  /**
   * Validates the SSL certificate of the BrowserPrint connection by opening a new tab to the endpoint.
   */
  public validateSslCertificate(): void {
    const endpoint = `${this.apiUrl}/ssl_support`;
    window.open(endpoint, '_blank');
  }

  /**
   * Validates the SSL certificate of the BrowserPrint connection.
   * @returns True if the SSL certificate is valid, false otherwise.
   */
  public async validateSslCertificateSilentlyAsync(): Promise<boolean> {
    const endpoint = `${this.apiUrl}/ssl_support`;
    const successMessage = 'SSL Certificate Has been accepted. Retry connection.' as const;

    const res = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
    });

    if (!res.ok) return false;

    const data = await res.text();
    return data === successMessage;
  }

  /**
   * Reads the response from the printer.
   * @returns The response from the printer.
   */
  private async readAsync(): Promise<string> {
    try {
      const endpoint = this.apiUrl + '/read';

      if (!this.device) throw new PrintError('Target printer is not set');

      // TODO: create a type for this
      const body = { device: this.device };

      const res = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8',
        },
        body: JSON.stringify(body),
      });
      const data = await res.text();
      return data;
    } catch (error) {
      if (error instanceof PrintError) throw error;
      throw new PrintError(error as any, this.device);
    }
  }

  /** Sends the ZPL command to the printer. */
  private async writeAsync(zpl: string): Promise<void> {
    try {
      const endpoint = this.apiUrl + '/write';

      // this.assertZplIsValid(zpl);

      if (!this.device) throw new PrintError('Target printer is not set');

      // TODO: create a type for this
      const body = {
        device: this.device,
        data: zpl,
      };

      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain;charset=UTF-8',
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      if (error instanceof PrintError) throw error;
      throw new PrintError(error as any, this.device);
    }
  }

  /** Asserts that the ZPL command is valid. */
  private assertZplIsValid(zpl: string) {
    const labelStart = '^';
    const commandStart = '~';
    if ([labelStart, commandStart].includes(zpl.trim().charAt(0))) return;

    throw new PrintError(
      `Invalid ZPL command. ZPL command must start with ^ or ~
        ${zpl}`,
      this.device
    );
  }

  /** Asserts that the ZPL label definition is valid. */
  private assertZplIsValidLabelDefinition(zpl: string): void {
    if (
      !zpl.trim().startsWith(ZebraPrinterLabelDefinition.DefinitionStart) ||
      !zpl.trim().endsWith(ZebraPrinterLabelDefinition.DefinitionEnd)
    )
      throw new PrintError('Invalid ZPL label definition', this.device);
  }

  private cleanUpString(str: string): string {
    const arr = str.split(':');
    const result = arr[1].trim();
    return result;
  }
}
