import Vue from 'vue';
import { EventBus } from '~/app/core/services/event-bus';
import { AppModule } from '~/app/core/module-system/module';
import { registerModule } from '~/app/core/module-system/use-module';

export enum BarcodingEvent {
  RequestingRefresh = 'mb.barcoding.requestingRefresh',
}

export class BarcodingModule extends AppModule {
  private readonly _events = new Vue();

  public readonly name = BarcodingModule.name;
  public readonly events = new EventBus<BarcodingEvent>(this._events);

  public requestRefresh() {
    this._events.$emit(BarcodingEvent.RequestingRefresh);
  }
}

export const ModuleName = BarcodingModule.name;

export const registerBarcodingModule = () => registerModule(BarcodingModule);
