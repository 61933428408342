















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  name: 'SkeletonList',
  components: {
    ContentLoader,
  },
  props: {
    height: {
      type: Number as PropType<number>,
      required: false,
      default: 200,
    },
    width: {
      type: Number as PropType<number>,
      required: false,
      default: 400,
    },
  },
});
