






// @ts-ignore
import enUSLocale from 'ant-design-vue/lib/locale-provider/en_US';
import {
  computed,
  defineComponent,
  ref,
  watch,
  useContext,
} from '@nuxtjs/composition-api';
import { ConsoleLogger } from '~/app/core/logging';
import { useServiceWorker } from '~/app/composables/use-service-worker';

export default defineComponent({
  name: 'EmptyLayout',
  setup() {
    const { $auth, $config } = useContext();
    const version = `${$config.app.version} ${$config.app.buildNumber}`;

    const isAuthenticated = computed(() => $auth.isAuthenticated.value);

    const { needsRefresh } = useServiceWorker();
    const isUpdateAlertVisible = ref(needsRefresh.value);
    watch(needsRefresh, (refresh) => (isUpdateAlertVisible.value = refresh));

    const locale = enUSLocale;

    return {
      isAuthenticated,
      version,
      locale,
      isUpdateAlertVisible,
    };
  },
  errorCaptured: (err, vm, info) => {
    try {
      vm.$log.error(
        err,
        {
          componentName: vm.$options.name!,
          info,
        },
        { ui: true }
      );
    } finally {
      const hasConsoleLogger = vm.$log.getLogger(ConsoleLogger);
      !hasConsoleLogger && console.error(err);
    }

    return false;
  },
});
