







import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PaginationSummary',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = computed(() => (props.loading ? 'Loading' : 'Showing'));

    const pageStart = computed(
      () => props.page * props.pageCount - props.pageCount + 1
    );
    const pageEnd = computed(() =>
      Math.min(props.page * props.pageCount, props.total)
    );

    return { state, pageStart, pageEnd };
  },
});
