export type UnitOfTime = 'ms' | 's' | 'm' | 'h';

/** A utility to convert a tick based timer to representative units of time. */
export class TickTimer {
  private readonly durationMs: number;
  private readonly measureMs: number;

  /**
   * Creates a new instance of `TickTimer`.
   * @param durationMs The total number of milliseconds the timer should last.
   * @param tickMeasureMs The number of milliseconds between each tick. **Default: `1000`**
   */
  constructor(durationMs: number, tickMeasureMs: number = 1000) {
    this.durationMs = durationMs;
    this.measureMs = tickMeasureMs;
  }

  /** @returns {number} The total number of ticks that make up the timer's interval. */
  public get ticks() {
    return Math.floor(this.durationMs / this.measureMs);
  }

  /**
   * Gets the tick within the interval which corresponds with the provided time in milliseconds.
   * @param {number} ms The number of milliseconds to convert to a tick.
   * @returns {number} The tick within the interval which corresponds with the provided time in milliseconds.
   */
  public getTick(ms: number) {
    return Math.floor(ms / this.measureMs);
  }

  /**
   * Gets the time that has elapsed at a specified tick.
   * @param {number} tick The tick to get the time elapsed at.
   * @param {UnitOfTime} unit The unit of time to return. **Default: `'s'`**
   * @returns {number} The time that has elapsed at a specified tick.
   */
  public getTime(tick: number, unit: UnitOfTime = 's') {
    if (tick < 0) return 0;

    const seconds = tick / (1000 / this.measureMs);

    switch (unit) {
      case 'ms':
        return seconds * 1000;
      case 's':
        return seconds;
      case 'm':
        return seconds / 60;
      case 'h':
        return seconds / 60 / 60;
      default:
        throw new Error('Unsupported unit of time');
    }
  }

  /**
   * Gets the time remaining at a specified tick.
   * @param {number} tick The tick to get the time remaining at.
   * @param {UnitOfTime} unit The unit of time to return. **Default: `'s'`**
   * @returns {number} The time remaining at the specified tick.
   */
  public getTimeRemaining(tick: number, unit: UnitOfTime = 's') {
    const remainingTicks = this.ticks - tick;
    return this.getTime(remainingTicks, unit);
  }

  /**
   * Gets the time interval the timer was set to.
   * @param {UnitOfTime} unit The unit of time to return. **Default: `'s'`**
   * @returns {number} The time interval the timer was set to.
   */
  public getTotalTime(unit: UnitOfTime = 's') {
    return this.getTime(this.ticks, unit);
  }
}
