import type { ActionTree } from 'vuex';
import { RootState } from '../index';
import { ITenantState } from './state';
import { Mutation } from './mutations';

export enum Action {
  fetchTenant = 'tenant.fetchTenant',
}

export const actions: ActionTree<ITenantState, RootState> = {
  async [Action.fetchTenant](context, options?: { ignoreCache?: boolean }) {
    let tenant = context.state.tenant;

    if (options?.ignoreCache || !tenant) {
      tenant = await this.$api.tenant.getAsync();
      context.commit(Mutation.setTenant, tenant);
    }

    return tenant;
  },
};

export default actions;
