import { PluginObject } from 'vue';
import WbSkeletonCard from './skeleton-card.vue';
import WbSkeletonList from './skeleton-list.vue';
import WbSkeletonMisc from './skeleton-misc.vue';

const plugin: PluginObject<void> = {
  install(vue) {
    vue.component('WbSkeletonCard', WbSkeletonCard);
    vue.component('WbSkeletonList', WbSkeletonList);
    vue.component('WbSkeletonMisc', WbSkeletonMisc);
  },
};

export { WbSkeletonCard, WbSkeletonList, WbSkeletonMisc };
export default plugin;
