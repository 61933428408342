import { MutationTree } from 'vuex';
import { IDictionariesState } from './state';
import {
  AssociateOrganizationType,
  DictionaryItem,
  DictionaryType,
  OrganizationDictionaryItem,
} from '~/app/models';

export enum Mutation {
  set = 'SET',
  setOrganizations = 'SET_ORGANIZATIONS',
  setAutopsyVendors = 'SET_AUTOPSY_VENDORS',
  setFuneralHomes = 'SET_FUNERAL_HOMES',
  setGenders = 'SET_GENDERS',
  setOrganBanks = 'SET_ORGAN_BANKS',
  setMedicalExaminers = 'SET_MEDICAL_EXAMINERS',
  setPersonalBelongingLocations = 'SET_PERSONAL_BELONGING_LOCATIONS',
  setOriginLocations = 'SET_ORIGIN_LOCATIONS',
  setTags = 'SET_TAGS',
  setNextOfKinTypes = 'SET_NEXT_OF_KIN_TYPES',
  setInfectiousDiseaseTypes = 'SET_INFECTIOUS_DISEASE_TYPES',
  setCaseTypes = 'SET_CASE_TYPES',
}

export const mutations: MutationTree<IDictionariesState> = {
  [Mutation.set](
    state,
    { type, entries }: { type: DictionaryType; entries: DictionaryItem[] }
  ) {
    if (!type) throw new Error('Dictionary type is required');
    if (!entries) throw new Error('Dictionary entries are required');
    const key = `${type}s` as keyof IDictionariesState;
    state[key] = entries;
  },
  [Mutation.setOrganizations](
    state,
    organizations: OrganizationDictionaryItem[]
  ) {
    state.autopsyVendor = organizations.filter(
      (o) => o.type === AssociateOrganizationType.AutopsyVendor
    );
    state.tissueBank = organizations.filter(
      (o) => o.type === AssociateOrganizationType.OrganBank
    );
    state.funeralHome = organizations.filter(
      (o) => o.type === AssociateOrganizationType.FuneralHome
    );
    state.medicalExaminer = organizations.filter(
      (o) => o.type === AssociateOrganizationType.MedicalExaminer
    );
  },
  [Mutation.setAutopsyVendors](state, vendors: DictionaryItem[]) {
    state.autopsyVendor = vendors;
  },
  [Mutation.setFuneralHomes](state, funeralHomes: DictionaryItem[]) {
    state.funeralHome = funeralHomes;
  },
  [Mutation.setGenders](state, genders: DictionaryItem[]) {
    state.gender = genders;
  },
  [Mutation.setOrganBanks](state, organBanks: DictionaryItem[]) {
    state.tissueBank = organBanks;
  },
  [Mutation.setMedicalExaminers](state, medicalExaminers: DictionaryItem[]) {
    state.medicalExaminer = medicalExaminers;
  },
  [Mutation.setPersonalBelongingLocations](state, locations: DictionaryItem[]) {
    state.personalBelongingLocation = locations;
  },
  [Mutation.setOriginLocations](state, locations: DictionaryItem[]) {
    state.originLocation = locations;
  },
  [Mutation.setTags](state, tags: DictionaryItem[]) {
    state.tag = tags;
  },
  [Mutation.setNextOfKinTypes](state, types: DictionaryItem[]) {
    state.nextOfKinType = types;
  },
  [Mutation.setInfectiousDiseaseTypes](state, types: DictionaryItem[]) {
    state.infectiousDiseaseType = types;
  },
  [Mutation.setCaseTypes](state, types: DictionaryItem[]) {
    state.caseType = types;
  },
};

export default mutations;
