import Vue, { VueConstructor, PluginObject } from 'vue';
import WbOverflow from './overflow.vue';

const overflow: VueConstructor<Vue> & PluginObject<void> = WbOverflow as any;
overflow.install = function (vue) {
  vue.component('WbOverflow', WbOverflow);
};

export { WbOverflow };
export default overflow;
