export class Address {
  public streetAddressLine1?: string;
  public streetAddressLine2?: string;
  public city?: string;
  public state?: string;
  public zipCode?: string;

  constructor(args?: Address) {
    this.streetAddressLine1 = args?.streetAddressLine1;
    this.streetAddressLine2 = args?.streetAddressLine2;
    this.city = args?.city;
    this.state = args?.state;
    this.zipCode = args?.zipCode;
  }
}
