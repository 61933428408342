import { get, orderBy as _orderBy } from 'lodash-es';

const isNil = (value: any) => value === null || value === undefined;

export function orderBy<TItem extends object>(
  arr: TItem[] | readonly TItem[],
  prop: keyof TItem | Array<keyof TItem>,
  options?: {
    /** @default 'asc'  */
    direction?: 'asc' | 'desc' | Array<'asc' | 'desc'>;
    /** @default 'hoist'' */
    nilCompare?: 'hoist' | 'lower';
  }
) {
  const direction = Array.isArray(options?.direction)
    ? options?.direction
    : [options?.direction ?? 'asc'];

  const nils: TItem[] = [];
  const valueResolver = Array.isArray(prop)
    ? prop
    : (item: TItem) => get(item, prop, null);

  const props = Array.isArray(prop) ? prop : [prop];

  const sorted = _orderBy(arr, valueResolver, direction).reduce(
    (_arr, item) => {
      const values = props.map((p) => get(item, p, null));

      if (values.some(isNil)) {
        nils.push(item);
        return _arr;
      }

      return [..._arr, item];
    },
    [] as TItem[]
  );

  const nilCompare = options?.nilCompare || 'hoist';
  return nilCompare === 'hoist' ? [...nils, ...sorted] : [...sorted, ...nils];
}
