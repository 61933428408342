export class SocialSecurityNumber {
  private _ssn: string;
  private delimiter: string;

  constructor(
    ssn: string,
    options: { delimiter: string } = { delimiter: '-' }
  ) {
    this._ssn = this.getRawSSN(ssn);
    this.delimiter = options.delimiter;
  }

  /** The formatted/masked value of the SSN. */
  get value() {
    return this.formatSSN(this._ssn);
  }

  set value(value: string) {
    this._ssn = this.getRawSSN(this._ssn);
  }

  /** The unformatted, numeric only, value of the SSN. */
  public get raw() {
    return this._ssn;
  }

  /**
   * Gets a discretely masked version of the social security number, padded with asterisks.
   * @param asteriskLength The length of the asterisks to pad the SSN with.
   * @returns A discretely masked social security number.
   */
  public getDiscreteValue(asteriskLength: number = 5) {
    if (!this.isValid) {
      return this.value;
    }

    const raw = this.raw.slice(asteriskLength);
    const masked = raw.padStart(9, '*');

    return this.formatSSN(masked);
  }

  /** Indicates if the social security number is composed of a valid format. */
  public get isValid() {
    const parts = [...this.raw]; // remove mask
    const nums = '0123456789';

    const isNumbers = parts.every((part) => nums.includes(part));
    const isLength = parts.length === 0 || parts.length === 9;

    return !!isNumbers && !!isLength;
  }

  /**
   * Validates the structure of the social security number. Errors are throw for each structure violation.
   */
  public validate() {
    const parts = [...this.raw]; // remove mask
    const nums = '0123456789';

    if (!parts.every((part) => nums.includes(part)))
      throw new Error('Social Security Number must contain only numbers.');

    if (parts.length !== 0 && parts.length !== 9)
      throw new Error('Social Security Number must be 9 digits long.');
  }

  private formatSSN(value: string): string {
    // https://codepen.io/ashblue/pen/LGeqxx

    if (typeof value !== 'string') {
      throw new TypeError('Social Security Number value must be a string.');
    }

    let maskedValue = value.replace(/[^0-9|\\*]/g, '');
    maskedValue = maskedValue.substring(0, 9);

    // Inject dashes
    if (maskedValue.length >= 4) {
      maskedValue =
        maskedValue.slice(0, 3) + this.delimiter + maskedValue.slice(3);
    }

    if (maskedValue.length >= 7) {
      maskedValue =
        maskedValue.slice(0, 6) + this.delimiter + maskedValue.slice(6);
    }

    return maskedValue;
  }

  private getRawSSN(value: string) {
    if (typeof value !== 'string') {
      throw new TypeError('Social Security Number value must be a string.');
    }

    return value.replace(/-/g, '');
  }
}
