







import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MenuTitle',
  props: {
    title: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    icon: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
});
