import { AppAbilitySubject } from '~/app/core/casl';
import { Action, Subject } from '~/app/core/casl/types';
import {
  AccessScope,
  AccessScopeDescription,
  AccessScopeMasterList,
} from '~/app/models';

export class AccessScopeService {
  private readonly masterList: AccessScopeMasterList;

  constructor(masterList: AccessScopeMasterList) {
    this.masterList = masterList;
  }

  public parse(scope: string): AccessScope {
    const parts = scope.split(':');

    if (parts.length !== 2)
      throw new Error('Invalid Access scope string format.');

    const [subject] = parts;

    const availableSubjects = Object.keys(this.masterList);
    const isValidSubject = availableSubjects.includes(subject);

    if (!isValidSubject)
      throw new Error(
        `Invalid subject. Subject "${subject}" must be one of the following ${availableSubjects}`
      );

    const scopes = (this.masterList as unknown) as {
      [sub: string]: AccessScopeDescription[];
    };

    const actions = scopes[subject].map((s) => s.scope);
    const isValidAction = actions.includes(scope);

    if (!isValidAction)
      throw new Error(
        `Invalid action. Action "${scope}" must be one of the following ${actions}`
      );

    return new AccessScope(scope as Action, subject as Subject);
  }

  public parseList(scopes: string[]): AccessScope[] {
    return scopes.map((scope) => this.parse(scope));
  }

  public getSubjectScopes(subject: AppAbilitySubject): AccessScope[] {
    const doesMasterListContainSubject = subject in this.masterList;

    if (!doesMasterListContainSubject)
      throw new Error(
        `Invalid subject. Subject "${subject}" must be one of the following ${Object.keys(
          this.masterList
        )}`
      );

    const scopes = this.masterList as {
      [sub in AppAbilitySubject]: AccessScopeDescription[];
    };

    return scopes[subject].map(({ scope }) => this.parse(scope));
  }
}
