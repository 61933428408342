import { Plugin } from '@nuxt/types';
import {
  registerModule,
  initModuleSystem,
} from '~/app/core/module-system/use-module';
import { AppModule } from '~/app/core/module-system/module';
import { registerBarcodingModule } from '~mod:barcoding';
import { registerLayoutModule } from '~mod:layout';
import { ReservationsModule } from '~mod:reservations';
import { registerBodyboxModule } from '~mod:bodybox';
import { OutboxModule } from '~mod:outbox';
import { registerSettingsModule } from '~mod:settings';
import { registerCaseDetailsModule } from '~mod:case-details';
import { registerUserRegistrationModule } from '~mod:registration';

const plugin: Plugin = (context) => {
  AppModule.enableDebugLogging = process.env.NODE_ENV === 'development';

  // Register *All* application modules below
  registerModule(ReservationsModule, { route: 'reservations' });
  registerModule(OutboxModule, { route: 'outbox' });
  registerBarcodingModule();
  registerBodyboxModule();
  registerCaseDetailsModule();
  registerLayoutModule();
  registerUserRegistrationModule();
  registerSettingsModule();

  // Create module instances
  initModuleSystem(context);
};

export default plugin;
