import Vue, { VueConstructor, PluginObject } from 'vue';
import WbViewportObserver from './viewport-observer.vue';

const viewportObserver: VueConstructor<Vue> &
  PluginObject<void> = WbViewportObserver as any;
viewportObserver.install = function (vue) {
  vue.component('WbViewportObserver', WbViewportObserver);
};

export { WbViewportObserver };
export default viewportObserver;
