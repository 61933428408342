











































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { useDebounce } from '@vueuse/core';

export type ZoneVariant = 'active' | 'success' | 'warning' | 'error';

export interface IconConfig {
  name: string;
  class?: string;
}

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<ZoneVariant | undefined>,
      required: false,
      default: undefined,
    },
    pulse: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String as PropType<'left' | 'right' | undefined>,
      required: false,
      default: undefined,
    },
    iconLeft: {
      type: [String, Object] as PropType<string | IconConfig | undefined>,
      required: false,
      default: undefined,
    },
    iconRight: {
      type: [String, Object] as PropType<string | IconConfig | undefined>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const input = ref('');
    const inputDebounced = useDebounce(input, 30);
    const scans = ref<string[]>([]);

    watch(inputDebounced, (value) => {
      if (value === '') return;
      scans.value.push(value);
      input.value = '';
    });

    const icons = computed(() => {
      return {
        left:
          typeof props.iconLeft === 'string'
            ? { name: props.iconLeft }
            : props.iconLeft,
        right:
          typeof props.iconRight === 'string'
            ? { name: props.iconRight }
            : props.iconRight,
      };
    });

    return { input, scans, icons };
  },
});
