export class BodyboxBariatricConfiguration {
  public readonly threshold!: number;
  public readonly instructions?: string;

  constructor(args?: BodyboxBariatricConfiguration) {
    if (!args) return;
    this.threshold = args.threshold;
    this.instructions = args.instructions;
  }
}
