import { computed, inject, provide, ref } from '@nuxtjs/composition-api';
import { useWindowFocus } from '@vueuse/core';
import {
  ScanError,
  ScanErrorMessage,
  ScannerInitOptions,
  ScanService,
} from '~/app/core/services/barcoding/scanning';
import { IgnoredElementSelectors } from '~/app/core/services/barcoding/scanning/ignored-elements';
import { sleep } from '~/app/utils/async';

const SCANNER_SYMBOL = Symbol('useScanner.scanner');

export type OnScanFunction = (data: string) => void;
export type OnScanErrorFunction = (error: ScanError) => void;

const isRunning = ref(false);
const onScanFns: OnScanFunction[] = [];
const onErrorFns: OnScanErrorFunction[] = [];

// #region Simulation
async function simulate(...data: string[]) {
  for (let i = 0; i < data.length; i++) {
    console.log('Simulating sequence:', data[i]);
    ScanService.simulate(data[i]);
    if (i < data.length - 1) await sleep(500);
  }
}

// @ts-expect-error - Expose simulate to window for testing
window.scanner = { simulate };
// #endregion

export function useScanner(
  options: Partial<ScannerInitOptions> = { startImmediate: true }
) {
  function onScan(fn: OnScanFunction) {
    onScanFns.push(fn);

    // Unsubscribe
    return () => {
      onScanFns.splice(onScanFns.indexOf(fn), 1);
    };
  }

  function onError(fn: OnScanErrorFunction) {
    onErrorFns.push(fn);

    // Unsubscribe
    return () => {
      onErrorFns.splice(onErrorFns.indexOf(fn), 1);
    };
  }

  function createScanner() {
    return new ScanService({
      ...options,
      ignoreIfFocusOn: IgnoredElementSelectors,
      onStart: () => (isRunning.value = true),
      onStop: () => (isRunning.value = false),
      onScan: (data) => onScanFns.forEach((fn) => fn(data)),
      onScanError: (error) => {
        if (
          error instanceof ScanError &&
          error.message === ScanErrorMessage.SequenceTooShort
        ) {
          // Don't show an error for short sequences
          return;
        }

        // TODO: if we are encountering scan errors, show a small toast saying like "Having trouble, click here?"
        // This would launch a modal with a text input focused where the scanner would write directly too. The styled should change based on its focus
        // if focused: make it look like it is waiting scans i.e show green "scanning"
        // if not focused: make it look like a button that says "start scanning" so that when they "click" it its actually just focusing the input
        // Possible debounced input event on an input ignore by the scan service (i.e. in form)
        console.error(error.message, error);
        onErrorFns.forEach((fn) => fn(error));
      },
    });
  }

  const scanner = inject(SCANNER_SYMBOL, () => createScanner(), true);
  provide(SCANNER_SYMBOL, scanner);

  const focused = useWindowFocus();
  const isListening = computed(() => focused.value && isRunning.value);

  return { scanner, isListening, onScan, onError };
}
