import * as sentry from '@sentry/core';
import { ILogger, LoggerArg } from '../ILogger';

export class SentryLogger implements ILogger {
  constructor() {
    if (!sentry) throw new Error('Sentry instance is required.');
  }

  public log(message: string, data?: LoggerArg): void {
    sentry.captureMessage(message, {
      level: 'log',
      extra: data,
    });
  }

  public info(message: string, data?: LoggerArg): void {
    sentry.captureMessage(message, {
      level: 'info',
      extra: data,
    });
  }

  public warn(message: string, data?: LoggerArg): void {
    sentry.captureMessage(message, {
      level: 'warning',
      extra: data,
    });
  }

  public debug(message: string, data?: LoggerArg): void {
    sentry.captureMessage(message, {
      level: 'debug',
      extra: data,
    });
  }

  public error(message: string | Error, data?: LoggerArg): void {
    sentry.captureException(message, { extra: data });
  }

  public track(event: string, data?: LoggerArg): void {
    console.info('SentryLogger.track', event, data);
  }
}
