import type { ActionTree } from 'vuex';
import { ISettingsState } from './state';
import { RootState } from '~/store';
import { Bodybox } from '~/app/models';
import {
  moduleName as bodyboxModuleName,
  Mutation as BodyboxMutation,
} from '~mod:bodybox/store';
import { CreateBodyboxRequest } from '~/app/core/api/requests/bodybox/create-bodybox-request';
import { UpdateBodyboxRequest } from '~/app/core/api/requests/bodybox/update-bodybox-request';

export enum Action {
  updateBodybox = 'updateBodybox',
  addBodybox = 'addBodybox',
  deleteBodybox = 'deleteBodybox',
}

export const actions: ActionTree<ISettingsState, RootState> = {
  async [Action.addBodybox]({ commit }, request: CreateBodyboxRequest) {
    const newBox = await this.$api.bodyboxes.addAsync(request);

    commit(`${bodyboxModuleName}/${BodyboxMutation.updateBodybox}`, newBox, {
      root: true,
    });

    return newBox;
  },
  async [Action.deleteBodybox]({ commit }, bodybox: Bodybox) {
    await this.$api.bodyboxes.deleteAsync(bodybox.id);

    commit(`${bodyboxModuleName}/${BodyboxMutation.removeBodybox}`, bodybox, {
      root: true,
    });
  },
  async [Action.updateBodybox]({ commit }, request: UpdateBodyboxRequest) {
    const updatedBox = await this.$api.bodyboxes.updateAsync(request);

    commit(
      `${bodyboxModuleName}/${BodyboxMutation.updateBodybox}`,
      updatedBox,
      { root: true }
    );

    return updatedBox;
  },
};
