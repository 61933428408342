import { AlertType } from './alert-type';

export const styles: {
  [T in AlertType]: {
    base: string;
    icon: string;
    description: string;
  };
} = {
  default: {
    base: 'bg-comet-50 border-comet-200 text-comet-900',
    icon: 'bg-gray-300 text-comet-700',
    description: 'text-comet-800',
  },
  info: {
    base: 'bg-blue-100 border-blue-200 text-blue-900',
    icon: 'bg-blue-300 text-blue-700',
    description: 'text-blue-800',
  },
  success: {
    base: 'bg-success-100 border-success-200 text-success-900',
    icon: 'bg-success-300 text-success-700',
    description: 'text-success-800',
  },
  warning: {
    base: 'bg-warning-100 border-warning-200 text-warning-900',
    icon: 'bg-warning-300 text-warning-700',
    description: 'text-warning-800',
  },
  danger: {
    base: 'bg-danger-100 border-danger-200 text-danger-900',
    icon: 'bg-danger-300 text-danger-700',
    description: 'text-danger-800',
  },
  multi: {
    base:
      'bg-gradient-to-br from-success-100 via-warning-100 to-danger-100 border-danger-200 text-danger-900',
    icon:
      'bg-gradient-to-br from-success-300 via-warning-300 to-danger-300 text-danger-700',
    description: 'text-danger-800',
  },
} as const;
