import { DictionaryItem } from './dictionary-item';

export class NextOfKinTypeDictionaryItem extends DictionaryItem {
  public readonly triggersCaseHold: boolean;

  constructor(args?: NextOfKinTypeDictionaryItem) {
    super(args);
    this.triggersCaseHold = args?.triggersCaseHold ?? false;
  }
}
