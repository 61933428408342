

















import { defineComponent, PropType } from '@nuxtjs/composition-api';

// TODO: Implement tab selection

export default defineComponent({
  name: 'Folder',
  props: {
    tabs: {
      type: Array as PropType<any[]>,
      required: false,
      default: () => [],
    },
    tabLabelProp: {
      type: String as PropType<string>,
      required: false,
      default: 'label',
    },
    tabSlant: {
      type: String as PropType<'left' | 'right' | undefined>,
      required: false,
      default: 'left',
    },
  },
});
