import Vue, { VueConstructor, PluginObject } from 'vue';
import WbTable from './table.vue';

const table: VueConstructor<Vue> & PluginObject<void> = WbTable as any;
table.install = function (vue) {
  vue.component('WbTable', WbTable);
};

export { WbTable };
export * from './types';
export default table;
