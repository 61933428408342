import Vue, { VNode } from 'vue';
import {
  isRef,
  onMounted,
  reactive,
  Ref,
  watch,
  onUnmounted,
  getCurrentInstance,
  ComponentInternalInstance,
} from '@nuxtjs/composition-api';
import { useResizeObserver, useMutationObserver } from '@vueuse/core';
import { Grid, html, UserConfig as _UserConfig } from 'gridjs';
import type { TCell, TColumn, TData } from 'gridjs/dist/src/types';
import 'gridjs/dist/theme/mermaid.css';
import { genId } from '~/app/utils/id';

export type { TColumn, TData };
export type UserConfig = Omit<_UserConfig, 'columns' | 'data'>;
export type GridConfig = {
  rows: Ref<TData[]>;
  columns: Ref<TColumn[]>;
  options?: UserConfig | Ref<UserConfig>;
  instanceId?: string;
};

type ComponentExtendedInstance = ComponentInternalInstance & {
  __$wbGridInstanceId: string[];
};

const instanceComponents: Record<string, Record<string, Vue>> = {};
const instanceComponentFactories: Record<
  string,
  Record<string, () => Vue>
> = {};

// clean up resize and mutation observer when unmounting

function listenForAndApplyComponentFormatters(
  gridRef: Ref<HTMLElement | SVGElement | null>,
  components: Record<string, Vue>,
  componentFactories: Record<string, () => Vue>
) {
  const { stop } = useMutationObserver(
    gridRef,
    (mutations) => {
      mutations.forEach(({ addedNodes }) => {
        Array.from(addedNodes).forEach((node) => {
          if (node instanceof HTMLSpanElement) {
            const rowId = node.getAttribute('data-row');
            const columnId = node.getAttribute('data-col');
            const lookupId = `${columnId}_${rowId}`;

            if (lookupId in components) {
              const component = components[lookupId];
              if ((component as any)._isMounted) {
                node.parentElement?.replaceChild(component.$el, node);
              }
            } else {
              const factory = componentFactories[lookupId];

              if (!factory) {
                console.error(node);
                throw new Error(
                  'Unable to resolve component factory.' + lookupId
                );
              }

              const component = factory();
              component.$mount(node);

              components[lookupId] = component;
              delete componentFactories[lookupId];
            }
          }
        });
      });
    },
    { attributes: true, childList: true, subtree: true }
  );

  return stop;
}

export function createComponentFormatter(
  renderer: (cell: TCell, row: any, column: TColumn) => VNode,
  instanceId?: string
) {
  const vm = getCurrentInstance();

  return (_cell: TCell, _row: any, _column: TColumn) => {
    const _instanceId =
      instanceId || (vm as ComponentExtendedInstance)?.__$wbGridInstanceId[0];

    if (!_instanceId) {
      throw new Error('Unable to resolve grid instance.');
    }

    const factories = instanceComponentFactories[_instanceId];

    const id = `table-row__${genId()}`;
    const lookupId = `${_column.id}_${_row.id}`;

    if (!(lookupId in factories)) {
      factories[lookupId] = () =>
        new Vue({
          key: id,
          parent: vm?.proxy,
          render: () => renderer(_cell, _row, _column),
        });
    }

    return html(
      `<span id="${lookupId}" data-col="${_column.id}" data-row="${_row.id}">${id}</span>`
    );
  };
}

export function useGrid(
  gridRef: Ref<HTMLElement | SVGElement | null>,
  config: GridConfig
) {
  let userConfig: UserConfig;
  if (isRef(config.options)) {
    config.options &&
      watch(
        config.options,
        (newOptions) => {
          userConfig = newOptions;
          grid.updateConfig(userConfig).forceRender();
        },
        { deep: true }
      );

    userConfig = config?.options.value || {};
  } else {
    userConfig = config?.options || {};
  }

  // watch([config.rows, config.columns], (n, o) => {
  //   console.log('update', n, o);
  // });

  watch(config.rows, (newRows) => {
    grid
      .updateConfig({
        ...userConfig,
        columns: config.columns.value,
        data: newRows,
      })
      .forceRender();
  });

  watch(config.columns, (newColumns) => {
    grid
      .updateConfig({
        ...userConfig,
        columns: newColumns,
        data: config.rows.value,
      })
      .forceRender();
  });

  const grid = new Grid({
    width: '100%',
    ...userConfig,
    className: {
      ...userConfig?.className,
      table: `w-full ${userConfig.className?.table}`.trimEnd(),
    },
    columns: config.columns.value,
    data: config.rows.value,
  });

  onMounted(() => {
    if (!gridRef.value) {
      throw new Error('gridRef is not defined');
    }

    grid.render(gridRef.value);
  });

  const instanceId = config.instanceId || genId();
  const components: Record<string, Vue> = reactive({});
  const componentFactories: Record<string, () => Vue> = reactive({});
  instanceComponents[instanceId] = components;
  instanceComponentFactories[instanceId] = componentFactories;

  const stopComponentListener = listenForAndApplyComponentFormatters(
    gridRef,
    components,
    componentFactories
  );

  const { stop: stopResizeObserver } = useResizeObserver(document.body, () => {
    grid.forceRender();
  });

  const vm = getCurrentInstance();
  if (vm) {
    const instance = vm as ComponentExtendedInstance;
    if (vm && Array.isArray(instance.__$wbGridInstanceId)) {
      instance.__$wbGridInstanceId.push(instanceId);
    } else {
      instance.__$wbGridInstanceId = [instanceId];
    }
  } else {
    throw new Error('Could resolve current instance');
  }

  onUnmounted(() => {
    for (const id in components) {
      components[id]?.$destroy();
    }

    delete instanceComponents[instanceId];

    stopComponentListener();
    stopResizeObserver();
  });

  return {
    grid,
  };
}
