


























import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { styles } from './styles';
import type { AlertType } from './alert-type';
import AlertIcon from './alert-icon.vue';

export default defineComponent({
  name: 'Alert',
  components: {
    AlertIcon,
  },
  props: {
    message: {
      type: String as PropType<string>,
      required: true,
    },
    description: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    type: {
      type: String as PropType<AlertType>,
      required: false,
      default: 'info',
    },
    icon: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    ping: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup() {
    return { styles };
  },
});
