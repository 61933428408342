import type { ActionTree } from 'vuex';
import type { IDictionariesState } from './state';
import { Mutation } from './mutations';
import type { RootState } from '~/store';
import {
  CaseTypeDictionaryItem,
  DictionaryItem,
  DictionaryType,
  NextOfKinTypeDictionaryItem,
  OrganizationDictionaryItem,
  OriginLocationDictionaryItem,
  SystemTagDictionaryItem,
  TagType,
} from '~/app/models';
import {
  AddDictionaryItemRequest,
  AddNextOfKinTypeRequest,
  AddOrganizationDictionaryItemRequest,
  AddOriginLocationRequest,
  AddSystemTagRequest,
  CreateCaseTypeRequest,
  DeleteDictionaryItemRequest,
  EditCaseTypeRequest,
  EditDictionaryItemRequest,
  EditNextOfKinTypeRequest,
  EditOrganizationDictionaryItemRequest,
  EditOriginLocationRequest,
  EditSystemTagRequest,
} from '~/app/core/api/requests/dictionaries';

export enum Action {
  fetch = 'fetch',
  fetchOne = 'fetchOne',
  add = 'add',
  edit = 'edit',
  remove = 'remove',

  fetchAssociateOrganizations = 'fetchAssociateOrganizations',

  fetchAutopsyVendors = 'fetchAutopsyVendors',
  fetchAutopsyVendor = 'fetchAutopsyVendorById',
  addAutopsyVendor = 'addAutopsyVendor',
  editAutopsyVendor = 'editAutopsyVendor',
  removeAutopsyVendor = 'removeAutopsyVendor',

  fetchFuneralHomes = 'fetchFuneralHomes',
  fetchFuneralHome = 'fetchFuneralHomeById',
  addFuneralHome = 'addFuneralHome',
  editFuneralHome = 'editFuneralHome',
  removeFuneralHome = 'removeFuneralHome',

  fetchGenders = 'fetchGenders',
  fetchGender = 'fetchGenderById',
  addGender = 'addGender',
  editGender = 'editGender',
  removeGender = 'removeGender',

  fetchOrganBanks = 'fetchOrganBanks',
  fetchOrganBank = 'fetchOrganBankById',
  addOrganBank = 'addOrganBank',
  editOrganBank = 'editOrganBank',
  removeOrganBank = 'removeOrganBank',

  fetchMedicalExaminers = 'fetchMedicalExaminers',
  fetchMedicalExaminer = 'fetchMedicalExaminerById',
  addMedicalExaminer = 'addMedicalExaminer',
  editMedicalExaminer = 'editMedicalExaminer',
  removeMedicalExaminer = 'removeMedicalExaminer',

  fetchPersonalBelongingLocations = 'fetchPersonalBelongingLocations',
  fetchPersonalBelongingLocation = 'fetchPersonalBelongingLocationById',
  addPersonalBelongingLocation = 'addPersonalBelongingLocation',
  editPersonalBelongingLocation = 'editPersonalBelongingLocation',
  removePersonalBelongingLocation = 'removePersonalBelongingLocation',

  fetchOriginLocations = 'fetchOriginLocations',
  fetchOriginLocation = 'fetchOriginLocationById',
  addOriginLocation = 'addOriginLocation',
  editOriginLocation = 'editOriginLocation',
  removeOriginLocation = 'removeOriginLocation',

  fetchSystemTags = 'fetchSystemTags',
  fetchSystemTag = 'fetchSystemTagById',
  addSystemTag = 'addSystemTag',
  editSystemTag = 'editSystemTag',
  removeSystemTag = 'removeSystemTag',
  fetchComputedTags = 'fetchComputedTags',

  fetchNextOfKinTypes = 'fetchNextOfKinTypes',
  fetchNextOfKinType = 'fetchNextOfKinTypeById',
  addNextOfKinType = 'addNextOfKinType',
  editNextOfKinType = 'editNextOfKinType',
  removeNextOfKinType = 'removeNextOfKinType',

  fetchInfectiousDiseaseTypes = 'fetchInfectiousDiseaseTypes',
  fetchInfectiousDiseaseType = 'fetchInfectiousDiseaseTypeById',
  addInfectiousDiseaseType = 'addInfectiousDiseaseType',
  editInfectiousDiseaseType = 'editInfectiousDiseaseType',
  removeInfectiousDiseaseType = 'removeInfectiousDiseaseType',

  fetchCaseTypes = 'fetchCaseTypes',
  fetchCaseType = 'fetchCaseTypeById',
  addCaseType = 'addCaseType',
  editCaseType = 'editCaseType',
  removeCaseType = 'removeCaseType',
}

export interface AddEntryPayload {
  type: DictionaryType;
  request: AddDictionaryItemRequest;
}

export interface EditEntryPayload {
  type: DictionaryType;
  request: EditDictionaryItemRequest;
}

export interface RemoveEntryPayload {
  type: DictionaryType;
  request: DeleteDictionaryItemRequest;
}

export const actions: ActionTree<IDictionariesState, RootState> = {
  async [Action.fetch]({ commit }, type: DictionaryType) {
    const entries = await this.$api.dictionaries.getAsync(type);

    commit(Mutation.set, { type, entries });

    return [...entries].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchOne](_context, { slug, entryId }) {
    const entry = await this.$api.dictionaries.getByIdAsync(slug, entryId);
    return entry;
  },
  async [Action.add]({ state, commit }, { type, request }: AddEntryPayload) {
    const newEntry = await this.$api.dictionaries.addAsync(type, request);

    const key = `${type}s` as keyof IDictionariesState;
    commit(Mutation.set, {
      type,
      entries: [...(state[key] || []), newEntry],
    });

    return newEntry;
  },
  async [Action.edit]({ state, commit }, { type, request }: EditEntryPayload) {
    const editedEntry = await this.$api.dictionaries.editAsync(type, request);

    const key = `${type}s` as keyof IDictionariesState;
    const entryIndex = state[key]?.findIndex((e) => e.id === editedEntry.id);
    const _entries = [...(state[key] || [])];
    entryIndex !== undefined && _entries.splice(entryIndex, 1, editedEntry);
    commit(Mutation.set, { type, entries: _entries });

    return editedEntry;
  },
  async [Action.remove](
    { state, commit },
    { type, request }: RemoveEntryPayload
  ) {
    await this.$api.dictionaries.deleteAsync(type, request);

    const key = `${type}s` as keyof IDictionariesState;
    const entryIndex = state[key]?.findIndex((e) => e.id === request.entryId);
    const _entries = [...(state[key] || [])];
    entryIndex !== undefined && _entries.splice(entryIndex, 1);
    commit(Mutation.set, { type, entries: _entries });
  },

  async [Action.fetchAssociateOrganizations]({ commit }) {
    const orgs = await this.$api.dictionaries.getAssociateOrganizationsAsync();
    commit(Mutation.setAutopsyVendors, orgs);

    return orgs;
  },
  // #region Autopsy Vendors
  async [Action.fetchAutopsyVendors]({ commit }) {
    const vendors = await this.$api.dictionaries.getAutopsyVendorsAsync();

    commit(Mutation.setAutopsyVendors, vendors);

    return [...vendors].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchAutopsyVendor](_context, entryId: string) {
    const vendor = await this.$api.dictionaries.getAutopsyVendorAsync(entryId);
    return vendor;
  },
  async [Action.addAutopsyVendor](
    { state, commit },
    vendor: OrganizationDictionaryItem
  ) {
    const request = new AddOrganizationDictionaryItemRequest(vendor);
    const newVendor = await this.$api.dictionaries.addAutopsyVendorAsync(
      request
    );

    commit(Mutation.setAutopsyVendors, [
      ...(state.autopsyVendor || []),
      newVendor,
    ]);

    return newVendor;
  },
  async [Action.editAutopsyVendor](
    { state, commit },
    vendor: OrganizationDictionaryItem
  ) {
    const request = new EditOrganizationDictionaryItemRequest(vendor);
    const editedVendor = await this.$api.dictionaries.editAutopsyVendorAsync(
      request
    );

    const vendorIndex = state.autopsyVendor?.findIndex(
      (v) => v.id === vendor.id
    );
    const _vendors = [...(state.autopsyVendor || [])];
    vendorIndex !== undefined && _vendors.splice(vendorIndex, 1, editedVendor);
    commit(Mutation.setAutopsyVendors, _vendors);

    return editedVendor;
  },
  async [Action.removeAutopsyVendor](
    { state, commit },
    vendor: DictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({ entryId: vendor.id });
    await this.$api.dictionaries.deleteAutopsyVendorAsync(request);

    const vendorIndex = state.autopsyVendor?.findIndex(
      (v) => v.id === vendor.id
    );
    const _vendors = [...(state.autopsyVendor || [])];
    vendorIndex !== undefined && _vendors.splice(vendorIndex, 1);
    commit(Mutation.setAutopsyVendors, _vendors);
  },
  // #endregion Autopsy Vendors
  // #region Funeral Homes
  async [Action.fetchFuneralHomes]({ commit }) {
    const funeralHomes = await this.$api.dictionaries.getFuneralHomesAsync();

    commit(Mutation.setFuneralHomes, funeralHomes);

    return [...funeralHomes].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchFuneralHome](_context, entryId: string) {
    const funeralHome = await this.$api.dictionaries.getFuneralHomeAsync(
      entryId
    );
    return funeralHome;
  },
  async [Action.addFuneralHome](
    { state, commit },
    funeralHome: OrganizationDictionaryItem
  ) {
    const request = new AddOrganizationDictionaryItemRequest(funeralHome);
    const newFuneralHome = await this.$api.dictionaries.addFuneralHomeAsync(
      request
    );

    commit(Mutation.setFuneralHomes, [
      ...(state.funeralHome || []),
      newFuneralHome,
    ]);

    return newFuneralHome;
  },
  async [Action.editFuneralHome](
    { state, commit },
    funeralHome: OrganizationDictionaryItem
  ) {
    const request = new EditOrganizationDictionaryItemRequest(funeralHome);
    const editedFuneralHome = await this.$api.dictionaries.editFuneralHomeAsync(
      request
    );

    const funeralHomeIndex = state.funeralHome?.findIndex(
      (fh) => fh.id === funeralHome.id
    );
    const _funeralHomes = [...(state.funeralHome || [])];
    funeralHomeIndex !== undefined &&
      _funeralHomes.splice(funeralHomeIndex, 1, editedFuneralHome);
    commit(Mutation.setFuneralHomes, _funeralHomes);

    return editedFuneralHome;
  },
  async [Action.removeFuneralHome](
    { state, commit },
    funeralHome: OrganizationDictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: funeralHome.id,
    });
    await this.$api.dictionaries.deleteFuneralHomeAsync(request);

    const funeralHomeIndex = state.funeralHome?.findIndex(
      (fh) => fh.id === funeralHome.id
    );
    const _funeralHomes = [...(state.funeralHome || [])];
    funeralHomeIndex !== undefined && _funeralHomes.splice(funeralHomeIndex, 1);
    commit(Mutation.setFuneralHomes, _funeralHomes);
  },
  // #endregion Funeral Home
  // #region Organ Banks
  async [Action.fetchOrganBanks]({ commit }) {
    const organBanks = await this.$api.dictionaries.getOrganBanksAsync();

    commit(Mutation.setOrganBanks, organBanks);

    return [...organBanks].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchOrganBank](_context, entryId: string) {
    const organBank = await this.$api.dictionaries.getOrganBankAsync(entryId);
    return organBank;
  },
  async [Action.addOrganBank](
    { state, commit },
    organBank: OrganizationDictionaryItem
  ) {
    const request = new AddOrganizationDictionaryItemRequest(organBank);
    const organBanks = await this.$api.dictionaries.addOrganBankAsync(request);

    commit(Mutation.setOrganBanks, [...(state.tissueBank || []), organBanks]);

    return organBanks;
  },
  async [Action.editOrganBank](
    { state, commit },
    organBank: OrganizationDictionaryItem
  ) {
    const request = new EditOrganizationDictionaryItemRequest(organBank);
    const updatedOrganBank = await this.$api.dictionaries.editOrganBankAsync(
      request
    );

    const organBankIndex = state.tissueBank?.findIndex(
      (ob) => ob.id === organBank.id
    );
    const _organBanks = [...(state.tissueBank || [])];
    organBankIndex !== undefined &&
      _organBanks.splice(organBankIndex, 1, updatedOrganBank);
    commit(Mutation.setOrganBanks, _organBanks);

    return updatedOrganBank;
  },
  async [Action.removeOrganBank](
    { state, commit },
    organBank: OrganizationDictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: organBank.id,
    });
    await this.$api.dictionaries.deleteOrganBankAsync(request);

    const organBankIndex = state.tissueBank?.findIndex(
      (fh) => fh.id === organBank.id
    );
    const _organBanks = [...(state.tissueBank || [])];
    organBankIndex !== undefined && _organBanks.splice(organBankIndex, 1);
    commit(Mutation.setOrganBanks, _organBanks);
  },
  // #endregion Organ Banks
  // #region Medical Examiners
  async [Action.fetchMedicalExaminers]({ commit }) {
    const medicalExaminers = await this.$api.dictionaries.getMedicalExaminersAsync();

    commit(Mutation.setMedicalExaminers, medicalExaminers);

    return [...medicalExaminers].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchMedicalExaminer](_context, entryId: string) {
    const medicalExaminer = await this.$api.dictionaries.getMedicalExaminerAsync(
      entryId
    );
    return medicalExaminer;
  },
  async [Action.addMedicalExaminer](
    { state, commit },
    medicalExaminer: OrganizationDictionaryItem
  ) {
    const request = new AddOrganizationDictionaryItemRequest(medicalExaminer);
    const newMedicalExaminer = await this.$api.dictionaries.addMedicalExaminerAsync(
      request
    );

    commit(Mutation.setMedicalExaminers, [
      ...(state.medicalExaminer || []),
      newMedicalExaminer,
    ]);

    return newMedicalExaminer;
  },
  async [Action.editMedicalExaminer](
    { state, commit },
    medicalExaminer: OrganizationDictionaryItem
  ) {
    const request = new EditOrganizationDictionaryItemRequest(medicalExaminer);
    const updatedMedicalExaminer = await this.$api.dictionaries.editMedicalExaminerAsync(
      request
    );

    const medicalExaminerIndex = state.medicalExaminer?.findIndex(
      (me) => me.id === medicalExaminer.id
    );
    const _medicalExaminers = [...(state.medicalExaminer || [])];
    medicalExaminerIndex !== undefined &&
      _medicalExaminers.splice(medicalExaminerIndex, 1, updatedMedicalExaminer);
    commit(Mutation.setMedicalExaminers, _medicalExaminers);

    return updatedMedicalExaminer;
  },
  async [Action.removeMedicalExaminer](
    { state, commit },
    medicalExaminer: OrganizationDictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: medicalExaminer.id,
    });
    await this.$api.dictionaries.deleteMedicalExaminerAsync(request);

    const medicalExaminerIndex = state.medicalExaminer?.findIndex(
      (me) => me.id === medicalExaminer.id
    );
    const _medicalExaminers = [...(state.medicalExaminer || [])];
    medicalExaminerIndex !== undefined &&
      _medicalExaminers.splice(medicalExaminerIndex, 1);
    commit(Mutation.setOrganBanks, _medicalExaminers);
  },
  // #endregion Medical Examiners
  // #region Gender
  async [Action.fetchGenders]({ commit }) {
    const genders = await this.$api.dictionaries.getGendersAsync();

    commit(Mutation.setGenders, genders);

    return [...genders].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchGender](_context, entryId: string) {
    const gender = await this.$api.dictionaries.getGenderAsync(entryId);
    return gender;
  },
  async [Action.addGender](
    { state, commit },
    gender: DictionaryItem & { externalId: string }
  ) {
    const request = new AddDictionaryItemRequest(gender);
    const newGender = await this.$api.dictionaries.addGenderAsync(request);

    commit(Mutation.setGenders, [...(state.gender || []), newGender]);

    return newGender;
  },
  async [Action.editGender](
    { state, commit },
    gender: DictionaryItem & { externalId: string }
  ) {
    const request = new EditDictionaryItemRequest(gender);
    const editedGender = await this.$api.dictionaries.editGenderAsync(request);

    const genderIndex = state.gender?.findIndex((g) => g.id === gender.id);
    const _genders = [...(state.gender || [])];
    genderIndex !== undefined && _genders.splice(genderIndex, 1, editedGender);
    commit(Mutation.setGenders, _genders);

    return editedGender;
  },
  async [Action.removeGender]({ state, commit }, gender: DictionaryItem) {
    const request = new DeleteDictionaryItemRequest({ entryId: gender.id });
    await this.$api.dictionaries.deleteGenderAsync(request);

    const genderIndex = state.gender?.findIndex((g) => g.id === gender.id);
    const _genders = [...(state.gender || [])];
    genderIndex !== undefined && _genders.splice(genderIndex, 1);
    commit(Mutation.setGenders, _genders);
  },
  // #endregion Gender
  // #region Personal Belonging Locations
  async [Action.fetchPersonalBelongingLocations]({ commit }) {
    const locations = await this.$api.dictionaries.getPersonalBelongingLocationsAsync();

    commit(Mutation.setPersonalBelongingLocations, locations);

    return [...locations].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchPersonalBelongingLocation](_context, entryId: string) {
    const location = await this.$api.dictionaries.getPersonalBelongingLocationAsync(
      entryId
    );
    return location;
  },
  async [Action.addPersonalBelongingLocation]({ state, commit }, name: string) {
    const request = new AddDictionaryItemRequest({ name });
    const location = await this.$api.dictionaries.addPersonalBelongingLocationAsync(
      request
    );

    commit(Mutation.setPersonalBelongingLocations, [
      ...(state.personalBelongingLocation || []),
      location,
    ]);

    return location;
  },
  async [Action.editPersonalBelongingLocation](
    { state, commit },
    location: DictionaryItem
  ) {
    const request = new EditDictionaryItemRequest(location);
    const editedLocation = await this.$api.dictionaries.editPersonalBelongingLocationAsync(
      request
    );

    const locationIndex = state.personalBelongingLocation?.findIndex(
      (l) => l.id === location.id
    );
    const _locations = [...(state.personalBelongingLocation || [])];
    locationIndex !== undefined &&
      _locations.splice(locationIndex, 1, editedLocation);
    commit(Mutation.setPersonalBelongingLocations, _locations);

    return editedLocation;
  },
  async [Action.removePersonalBelongingLocation](
    { state, commit },
    location: DictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: location.id,
    });
    await this.$api.dictionaries.deletePersonalBelongingLocationAsync(request);

    const locationIndex = state.personalBelongingLocation?.findIndex(
      (l) => l.id === location.id
    );
    const _locations = [...(state.personalBelongingLocation || [])];
    locationIndex !== undefined && _locations.splice(locationIndex, 1);
    commit(Mutation.setPersonalBelongingLocations, _locations);
  },
  // #endregion Personal Belonging Locations
  // #region Origin Locations
  async [Action.fetchOriginLocations]({ commit }) {
    const locations = await this.$api.dictionaries.getOriginLocationsAsync();

    commit(Mutation.setOriginLocations, locations);

    return [...locations].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchOriginLocation](_context, entryId: string) {
    const location = await this.$api.dictionaries.getOriginLocationAsync(
      entryId
    );
    return location;
  },
  async [Action.addOriginLocation](
    { state, commit },
    entity: Omit<
      OriginLocationDictionaryItem,
      'id' | 'activated' | 'filterValue'
    >
  ) {
    const request = new AddOriginLocationRequest(entity);
    const location = await this.$api.dictionaries.addOriginLocationAsync(
      request
    );

    commit(Mutation.setOriginLocations, [
      ...(state.originLocation || []),
      location,
    ]);

    return location;
  },
  async [Action.editOriginLocation](
    { state, commit },
    entity: Omit<OriginLocationDictionaryItem, 'activated' | 'filterValue'>
  ) {
    const request = new EditOriginLocationRequest(entity);
    const editedLocation = await this.$api.dictionaries.editOriginLocationAsync(
      request
    );

    const locationIndex = state.originLocation?.findIndex(
      (l) => l.id === request.id
    );
    const _locations = [...(state.originLocation || [])];
    locationIndex !== undefined &&
      _locations.splice(locationIndex, 1, editedLocation);
    commit(Mutation.setOriginLocations, _locations);

    return editedLocation;
  },
  async [Action.removeOriginLocation](
    { state, commit },
    location: DictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: location.id,
    });
    await this.$api.dictionaries.deleteOriginLocationAsync(request);

    const locationIndex = state.originLocation?.findIndex(
      (l) => l.id === location.id
    );
    const _locations = [...(state.originLocation || [])];
    locationIndex !== undefined && _locations.splice(locationIndex, 1);
    commit(Mutation.setOriginLocations, _locations);
  },
  // #endregion Origin Locations
  // #region Tags
  async [Action.fetchSystemTags]({ commit }, types?: TagType[]) {
    const tags = await this.$api.dictionaries.getSystemTagsAsync();

    commit(Mutation.setTags, tags);

    return [...tags]
      .filter((t) => (types ? types?.includes(t.type) : true))
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  },
  async [Action.fetchSystemTag](_context, entryId) {
    const tag = await this.$api.dictionaries.getSystemTagAsync(entryId);
    return tag;
  },
  async [Action.addSystemTag]({ state, commit }, tag: SystemTagDictionaryItem) {
    const request = new AddSystemTagRequest(tag);
    const newTag = await this.$api.dictionaries.addTagAsync(request);

    commit(Mutation.setTags, [...(state.tag || []), newTag]);

    return newTag;
  },
  async [Action.editSystemTag](
    { state, commit },
    tag: SystemTagDictionaryItem
  ) {
    const request = new EditSystemTagRequest(tag);
    const editedTag = await this.$api.dictionaries.editTagAsync(request);

    const tagIndex = state.tag?.findIndex((t) => t.id === tag.id);
    const _tags = [...(state.tag || [])];
    tagIndex !== undefined && _tags.splice(tagIndex, 1, editedTag);
    commit(Mutation.setTags, _tags);

    return editedTag;
  },
  async [Action.removeSystemTag](
    { state, commit },
    tag: SystemTagDictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: tag.id,
    });
    await this.$api.dictionaries.deleteTagAsync(request);

    const tagIndex = state.tag?.findIndex((t) => t.id === tag.id);
    const _tags = [...(state.tag || [])];
    tagIndex !== undefined && _tags.splice(tagIndex, 1);
    commit(Mutation.setTags, _tags);
  },
  async [Action.fetchComputedTags]() {
    const tags = await this.$api.dictionaries.getComputedTagsAsync();
    return tags;
    // return [...tags].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  },
  // #endregion Tags
  // #region Next of Kin Types
  async [Action.fetchNextOfKinTypes]({ commit }) {
    const types = await this.$api.dictionaries.getNextOfKinTypesAsync();

    commit(Mutation.setNextOfKinTypes, types);

    return [...types].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchNextOfKinType](_context, entryId) {
    const type = await this.$api.dictionaries.getNextOfKinTypeAsync(entryId);
    return type;
  },
  async [Action.addNextOfKinType](
    { state, commit },
    type: NextOfKinTypeDictionaryItem
  ) {
    const request = new AddNextOfKinTypeRequest(type);
    const newType = await this.$api.dictionaries.addNextOfKinTypeAsync(request);

    commit(Mutation.setNextOfKinTypes, [
      ...(state.nextOfKinType || []),
      newType,
    ]);

    return newType;
  },
  async [Action.editNextOfKinType](
    { state, commit },
    type: NextOfKinTypeDictionaryItem
  ) {
    const request = new EditNextOfKinTypeRequest(type);
    const editedType = await this.$api.dictionaries.editNextOfKinTypeAsync(
      request
    );

    const typeIndex = state.nextOfKinType?.findIndex(
      (t) => t.id === editedType.id
    );
    const _types = [...(state.nextOfKinType || [])];
    typeIndex !== undefined && _types.splice(typeIndex, 1, editedType);
    commit(Mutation.setNextOfKinTypes, _types);

    return editedType;
  },
  async [Action.removeNextOfKinType](
    { state, commit },
    type: NextOfKinTypeDictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: type.id,
    });
    await this.$api.dictionaries.deleteNextOfKinTypeAsync(request);

    const typeIndex = state.nextOfKinType?.findIndex((t) => t.id === type.id);
    const _type = [...(state.nextOfKinType || [])];
    typeIndex !== undefined && _type.splice(typeIndex, 1);
    commit(Mutation.setNextOfKinTypes, _type);
  },
  // #endregion Next of Kin
  // #region Infectious Disease Types
  async [Action.fetchInfectiousDiseaseTypes]({ commit }) {
    const types = await this.$api.dictionaries.getInfectiousDiseaseTypesAsync();

    commit(Mutation.setInfectiousDiseaseTypes, types);

    return [...types].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchInfectiousDiseaseType](_context, entryId) {
    const type = await this.$api.dictionaries.getInfectiousDiseaseTypeAsync(
      entryId
    );
    return type;
  },
  async [Action.addInfectiousDiseaseType](
    { state, commit },
    type: DictionaryItem
  ) {
    const request = new AddDictionaryItemRequest(type);
    const newType = await this.$api.dictionaries.addInfectiousDiseaseTypeAsync(
      request
    );

    commit(Mutation.setInfectiousDiseaseTypes, [
      ...(state.infectiousDiseaseType || []),
      newType,
    ]);

    return newType;
  },
  async [Action.editInfectiousDiseaseType](
    { state, commit },
    type: DictionaryItem
  ) {
    const request = new EditDictionaryItemRequest(type);
    const editedType = await this.$api.dictionaries.editInfectiousDiseaseTypeAsync(
      request
    );

    const typeIndex = state.infectiousDiseaseType?.findIndex(
      (t) => t.id === editedType.id
    );
    const _types = [...(state.infectiousDiseaseType || [])];
    typeIndex !== undefined && _types.splice(typeIndex, 1, editedType);
    commit(Mutation.setInfectiousDiseaseTypes, _types);

    return editedType;
  },
  async [Action.removeInfectiousDiseaseType](
    { state, commit },
    type: DictionaryItem
  ) {
    const request = new DeleteDictionaryItemRequest({
      entryId: type.id,
    });
    await this.$api.dictionaries.deleteInfectiousDiseaseTypeAsync(request);

    const typeIndex = state.infectiousDiseaseType?.findIndex(
      (t) => t.id === type.id
    );
    const _type = [...(state.infectiousDiseaseType || [])];
    typeIndex !== undefined && _type.splice(typeIndex, 1);
    commit(Mutation.setInfectiousDiseaseTypes, _type);
  },
  // #endregion Infectious Disease Types
  // #region Case Types
  async [Action.fetchCaseTypes]({ commit }) {
    const types = await this.$api.dictionaries.getCaseTypesAsync();

    commit(Mutation.setCaseTypes, types);

    return [...types].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  },
  async [Action.fetchCaseType](_context, entryId) {
    const type = await this.$api.dictionaries.getCaseTypeAsync(entryId);
    return type;
  },
  async [Action.addCaseType]({ state, commit }, type: CaseTypeDictionaryItem) {
    const request = new CreateCaseTypeRequest(type);
    const newType = await this.$api.dictionaries.addCaseTypeAsync(request);

    commit(Mutation.setCaseTypes, [...(state.caseType || []), newType]);

    return newType;
  },
  async [Action.editCaseType]({ state, commit }, type: CaseTypeDictionaryItem) {
    const request = new EditCaseTypeRequest(type);
    const editedType = await this.$api.dictionaries.editCaseTypeAsync(request);

    const typeIndex = state.caseType?.findIndex((t) => t.id === editedType.id);
    const _types = [...(state.caseType || [])];
    typeIndex !== undefined && _types.splice(typeIndex, 1, editedType);
    commit(Mutation.setCaseTypes, _types);

    return editedType;
  },
  async [Action.removeCaseType]({ state, commit }, type: DictionaryItem) {
    const request = new DeleteDictionaryItemRequest({
      entryId: type.id,
    });
    await this.$api.dictionaries.deleteCaseTypeAsync(request);

    const typeIndex = state.caseType?.findIndex((t) => t.id === type.id);
    const _type = [...(state.caseType || [])];
    typeIndex !== undefined && _type.splice(typeIndex, 1);
    commit(Mutation.setCaseTypes, _type);
  },
  // #endregion Case Types
};

export default actions;
