






import { defineComponent, provide, ref } from '@nuxtjs/composition-api';
import {
  SYMBOL_TAB_BAR_MENU_SET_LAST_LEVEL,
  SYMBOL_TAB_BAR_MENU_ACTIVE_LEVEL,
} from './symbols';

export default defineComponent({
  name: 'TabBar',
  setup() {
    const activeLevel = ref(0);
    provide(SYMBOL_TAB_BAR_MENU_ACTIVE_LEVEL, activeLevel);

    const lastLevel = ref(0);
    provide(
      SYMBOL_TAB_BAR_MENU_SET_LAST_LEVEL,
      (level: number) => (lastLevel.value = level)
    );

    return { activeLevel, lastLevel };
  },
});
