import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { IdleTimer } from '~/app/core/services/idle-timer';
import { RootState } from '~/store';
import { moduleName as TenantStoreName, Action } from '~/store/tenant';
import { TickTimer } from '~/app/utils/tick-timer';
import { useInactivityCountdown } from '~mod:shared/composables/use-inactivity-countdown';

// Display UI notification when the idle timeout is within the following range from completion
const notificationDisplaySecondsRemaining = 60;

export default defineNuxtPlugin(({ $auth, store }) => {
  const {
    render: renderCountdown,
    close: closeCountdown,
  } = useInactivityCountdown();

  const unsubscribe = store.subscribeAction({
    after(action, { tenant: { tenant } }: RootState) {
      if (action.type !== `${TenantStoreName}/${Action.fetchTenant}` || !tenant)
        return;

      unsubscribe();

      const { inactivityTimeout } = tenant.configuration.sessions;
      if (!inactivityTimeout.enabled) return;

      const idleTimeoutMs = inactivityTimeout.durationInMinutes
        ? inactivityTimeout.durationInMinutes * 60 * 1000
        : 1000 * 60 * 5; /* default to 5 minutes */
      const tickMeasureMs = 100;
      const tickTimer = new TickTimer(idleTimeoutMs, tickMeasureMs);

      const timer = new IdleTimer({
        idle: idleTimeoutMs,
        tickMeasure: tickMeasureMs,
        onTick(tick) {
          const remainingSeconds = tickTimer.getTimeRemaining(tick);

          // Display the countdown notification when threshold is reached
          if (remainingSeconds <= notificationDisplaySecondsRemaining) {
            const secondsInMs = notificationDisplaySecondsRemaining * 1000;
            const countDownTickTimer = new TickTimer(
              secondsInMs,
              tickMeasureMs
            );
            const tickDiff = tickTimer.ticks - countDownTickTimer.ticks;
            const subTick = tick - tickDiff;
            renderCountdown(subTick, secondsInMs, tickMeasureMs);
          }
        },
        async onIdle() {
          closeCountdown();

          timer.stop();
          await $auth.logoutAsync();
        },
        onActivity() {
          closeCountdown();
        },
      });

      timer.start();
    },
  });
});
