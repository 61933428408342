



























import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  PropType,
  Ref,
  toRefs,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import MenuTitle from './menu-title.vue';

export default defineComponent({
  name: 'MenuItem',
  components: {
    MenuTitle,
  },
  props: {
    to: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    title: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    icon: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    data: {
      type: Object as PropType<Record<string, any>>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    // #region keys/selection state

    const key = getCurrentInstance()?.vnode.key as string;
    if (!key) throw new Error('[MenuItem] A "key" attribute is required.');
    const selectKey = inject<(key: string) => void>('wb-menu.selectKey');
    const selectedKey = inject<Ref<string>>('wb-menu.selectedKey');

    const isSelected = computed(() => selectedKey?.value === key);

    // #endregion

    // #region Menu level + spacing

    const menuLevel = inject('wb-menu.level', 1);
    const calcPadding = inject<(level: number) => number>(
      'wb-menu.calculatePadding'
    );
    const leftPadding = calcPadding?.(menuLevel);

    // #endregion

    // #region handle selected by route navigation

    const route = useRoute();
    const { to } = toRefs(props);
    const isActiveRoute = computed(
      () => to.value && route.value.path.includes(to.value)
    );

    watch(isActiveRoute, (isActive) => isActive && selectKey?.(key), {
      immediate: true,
      deep: true,
    });

    // #endregion

    const addMenuKey = inject<(key: string) => void>('wb-menu.addMenuKey');
    addMenuKey?.(key);

    return {
      isActiveRoute,
      leftPadding,
      selectedKey,
      isSelected,
      selectKey: () => selectKey?.(key),
    };
  },
});
