import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { AccessScopeService } from '~/app/core/services/access-scope-service';
import { AccessScopeMasterList, User } from '~/app/models';
import { moduleName, Action, IAccessState } from '~/store/access';
import { RegistrationRoute } from '~mod:registration/routes';

const registrationRoute = `/${RegistrationRoute.root}`;

export default defineNuxtMiddleware(async ({ $ability, store, route }) => {
  // skip when user has not yet registered
  if (route.path === registrationRoute) return;

  const state: IAccessState = store.state.access;
  if (state.user) return;

  const dispatch = <TReturn>(action: Action): Promise<TReturn> =>
    store.dispatch(`${moduleName}/${action}`);

  const [user, masterScopeList] = await Promise.all([
    dispatch<User>(Action.fetchUser),
    dispatch<AccessScopeMasterList>(Action.fetchAccessScopesMasterList),
  ]);

  if (!user.accessScopes)
    throw new Error('User is missing accessScopes collection.');

  const accessScopeService = new AccessScopeService(masterScopeList);
  const scopes = accessScopeService.parseList(user.accessScopes || []);
  $ability.update(scopes);
});
