import { Type } from 'class-transformer';
import { FormatDate, IsFormatDate } from '../../dates';
import { CaseAction } from '../case-action';
import { CaseNotice } from '../notice';
import { CaseTypeDictionaryItem } from '../../dictionaries';
import { BodyboxStorageLocation } from '../../bodybox';
import { CaseSummaryTags } from './tags';
import { CaseSummaryInsights } from './insights';

export class BodyboxStorageLocationSummary extends BodyboxStorageLocation {
  public readonly storageType!: string;
}

export class BodyboxCaseSummary {
  public readonly id!: string;

  public readonly caseType?: CaseTypeDictionaryItem;

  public readonly decedentName?: string;

  public readonly decedentAlias?: string;

  public readonly decedentMedicalRecordNumber?: string;

  @IsFormatDate
  public readonly decedentDateOfBirth?: FormatDate;

  @IsFormatDate
  public readonly decedentExpirationDate?: FormatDate;

  @Type(() => BodyboxStorageLocationSummary)
  public readonly storageLocation?: BodyboxStorageLocationSummary;

  @Type(() => CaseSummaryTags)
  public readonly tags!: CaseSummaryTags;

  @Type(() => CaseAction)
  public readonly checkOut!: CaseAction;

  @Type(() => CaseNotice)
  public readonly notices!: CaseNotice[];

  @Type(() => CaseSummaryInsights)
  public readonly insights!: CaseSummaryInsights;
}
