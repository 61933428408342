import { useStoreModule } from '~/app/composables/use-store-module';
import {
  Action,
  Mutation,
  Getter,
  moduleName,
  IBodyboxState,
} from '~/app/modules/bodybox/store';

export function useStore() {
  const scope = useStoreModule<IBodyboxState>(moduleName);

  return {
    ...scope,
    Action,
    Mutation,
    Getter,
  };
}
