import { useStore as useRootStore, set } from '@nuxtjs/composition-api';
import {
  Commit,
  CommitOptions,
  Dispatch,
  DispatchOptions,
  Payload,
} from 'vuex';
import { AppStore, ModuleState } from '~/store';

type ModuleKey = keyof ModuleState;

export function useStoreModule<IState = ModuleState[ModuleKey]>(
  moduleName: ModuleKey
) {
  const store = useRootStore<AppStore>();

  const getters = Object.entries(store.getters).reduce((obj, [key, getter]) => {
    if (!key.startsWith(`${moduleName}/`)) return obj;
    const scopedKey = key.split(`${moduleName}/`)[1];
    set(obj, scopedKey, getter);
    return obj;
  }, {} as { [key: string]: any });

  const commit: Commit = <P extends Payload>(
    typeOrPayloadWithType: string | P,
    payloadOrOptions: any | CommitOptions,
    options?: CommitOptions
  ) => {
    if (typeof typeOrPayloadWithType === 'string')
      return store.commit(
        `${moduleName}/${typeOrPayloadWithType}`,
        payloadOrOptions,
        options
      );

    const payloadWithType: P = {
      ...typeOrPayloadWithType,
      type: `${moduleName}/${typeOrPayloadWithType.type}`,
    };
    return store.commit(payloadWithType, options);
  };

  const dispatch: Dispatch = <P extends Payload>(
    typeOrPayloadWithType: string | P,
    payloadOrOptions: any | DispatchOptions,
    options?: DispatchOptions
  ) => {
    if (typeof typeOrPayloadWithType === 'string')
      return store.dispatch(
        `${moduleName}/${typeOrPayloadWithType}`,
        payloadOrOptions,
        options
      );

    const payloadWithType: P = {
      ...typeOrPayloadWithType,
      type: `${moduleName}/${typeOrPayloadWithType.type}`,
    };
    return store.dispatch(payloadWithType, options);
  };

  const toNamespaced = (value: string) => `${moduleName}/${value}`;

  return {
    moduleName,
    rootStore: store,
    state: (store.state[moduleName] as unknown) as IState,
    getters,
    commit,
    dispatch,
    toNamespaced,
  };
}
