import { Type } from 'class-transformer';
import { DateOnly } from './date-only';

export interface DateTimeInfoOptions {
  /** Indicates if the date should always be serialized as a DateOnly or Date. */
  serialize?: 'date' | 'dateTime';
}

export class DateTimeInfo {
  private readonly options?: DateTimeInfoOptions;

  @Type(() => DateOnly)
  public readonly date!: DateOnly;

  @Type(() => Date)
  public readonly time?: Date;

  constructor(
    args: DateOnly | Date | DateTimeInfo,
    options?: DateTimeInfoOptions
  ) {
    if (!args) return;

    this.options = options;

    if (args instanceof DateTimeInfo) {
      this.date = args.date;
      this.time = args.time;
      return;
    }

    if (args instanceof DateOnly) {
      this.date = args;
      return;
    }

    this.date = new DateOnly(args);
    this.time = args;
  }

  public get value(): Date | DateOnly {
    return this.time || this.date;
  }

  public toString() {
    return this.toJSON();
  }

  public toDate(): Date {
    return this.time || this.date.toDate();
  }

  public toJSON(): string {
    if (this.options?.serialize)
      return this.options?.serialize === 'date'
        ? this.date.toJSON()
        : this.toDate().toISOString();

    if (this.time) return this.time.toISOString();
    return this.date.toISOString();
  }
}
