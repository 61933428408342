import ky from 'ky';
import { PartialApiFactory } from '../partial-factory';
import { ITenantDiscoveryApiFactory } from '../../ITenantDiscoveryApiFactory';

export const createTenantDiscoveryApi: PartialApiFactory<
  ITenantDiscoveryApiFactory,
  'discover'
> = (api, config) => {
  return {
    async getForContextAsync() {
      try {
        const { headers } = config.api.tenant;
        const tenant = await api.getAsync<{ organizationId: string }>(
          `TenantDiscovery`,
          {
            options: {
              headers: {
                [headers!.functionKey!.name]: headers!.functionKey!.value!,
              },
            },
          }
        );
        return Object.freeze(tenant);
      } catch (error) {
        if (error instanceof ky.HTTPError && error.response.status === 404)
          return null;
        throw error;
      }
    },
  };
};
