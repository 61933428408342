import { MutationTree } from 'vuex';
import { ITenantState } from './state';
import { Tenant } from '~/app/models';

export enum Mutation {
  setTenant = 'SET_TENANT',
}

export const mutations: MutationTree<ITenantState> = {
  [Mutation.setTenant](state, tenant: Readonly<Tenant>) {
    state.tenant = tenant;
  },
};

export default mutations;
