



















































































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import {
  useAbility,
  AppAbilitySubject,
  BodyboxAbilityAction,
} from '~/app/core/casl';
import { useSWRV } from '~/app/composables/use-swrv';
import { BodyboxCapacity } from '~/app/models';
import { MenuItemProps } from '~/app/components/ui/menu/sub-menu.vue';
import { useStore as useBodyboxStore } from '~mod:bodybox/composables/use-store';
import CapacityBadge from '~mod:shared/components/molecules/capacity-badge.vue';

export default defineComponent({
  name: 'TheAsideMenu',
  components: {
    CapacityBadge,
  },
  setup() {
    const { $language } = useContext();
    const { state } = useBodyboxStore();
    const bodyboxes = computed(() => state.bodyboxes || []);

    const menuKeys = computed(() => [
      'cases',
      'cases.active',
      'cases.reservations',
      'cases.bodybox',
      'cases.bodybox.all',
      ...bodyboxes.value.map((box) => `cases.bodybox.${box.id}`),
      'outbox',
      'settings',
    ]);

    const usePopoverBodyboxMenu = computed(() => bodyboxes.value.length >= 5);
    const bodyboxItems = computed(() => {
      const allItem: MenuItemProps = {
        key: 'cases.bodybox.all',
        title: `All ${$language.get('bodybox').plural}`,
        to: '/bodybox',
        icon: 'arrows-out',
        data: {},
      };

      const boxItems = bodyboxes.value.map((box) => ({
        key: `cases.bodybox.${box.id}`,
        title: box.name,
        to: `/bodybox/${box.id}`,
        icon: 'square-half',
        data: { id: box.id },
      })) as MenuItemProps[];

      return usePopoverBodyboxMenu.value ? [allItem, ...boxItems] : boxItems;
    });

    // #region Bodybox Capacities

    const { data: capacities } = useSWRV<BodyboxCapacity[]>(
      'api.bodyboxes.getCapacitiesAsync',
      null // Fetch capacities from cache
    );

    const getCapacity = (bodyboxId: string) =>
      capacities.value?.find((capacity) => capacity.id === bodyboxId);

    const capacityMap = computed(() =>
      bodyboxes.value.reduce((acc, bodybox) => {
        const capacity = getCapacity(bodybox.id);
        if (!capacity) return acc;
        acc[bodybox.id] = capacity;
        return acc;
      }, {} as Record<string, BodyboxCapacity>)
    );

    // #endregion

    // #region access control

    const ability = useAbility();

    const canViewBodyboxes = computed(() =>
      ability.value.can(BodyboxAbilityAction.read, AppAbilitySubject.bodybox)
    );

    // #endregion

    return {
      menuKeys,
      bodyboxes,
      usePopoverBodyboxMenu,
      bodyboxItems,
      capacityMap,
      canViewBodyboxes,
    };
  },
});
