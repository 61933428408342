












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  name: 'SkeletonCard',
  components: {
    ContentLoader,
  },
  props: {
    height: {
      type: [Number, String] as PropType<number | string>,
      required: false,
      default: 225,
    },
    width: {
      type: [Number, String] as PropType<number | string>,
      required: false,
      default: 400,
    },
  },
});
