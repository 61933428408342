export class AlertSetting {
  public readonly id!: string;
  public readonly name!: string;
  public readonly description!: string;

  constructor(args?: AlertSetting) {
    if (!args) return;
    this.id = args.id;
    this.name = args.name;
    this.description = args.description;
  }
}
