import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { registerAPI } from '~/app/core/api';
import { createApiClient } from '~/app/core/api/client';
import { tenantDiscoveryApiFactory } from '~/app/core/api/factory';

export default defineNuxtPlugin(({ $http, $config }, inject) => {
  if (!$config.api.tenant.baseUrl)
    throw new Error('Nuxt config "api.tenant.baseUrl" does not exist.');

  if (!$config.api.tenant.headers?.functionKey?.name)
    throw new Error(
      'Nuxt config "api.tenant.headers.functionKey.name" does not exist.'
    );

  if (!$config.api.tenant.headers?.functionKey?.value)
    throw new Error(
      'Nuxt config "api.tenant.headers.functionKey.value" does not exist.'
    );

  const http = $http.create({ prefixUrl: $config.api.tenant.baseUrl });
  const client = createApiClient(http);
  client.setHeaders({
    'Content-Type': 'application/json',
    'x-app-client-version': $config.app.version,
    'x-app-client-build': !$config.app.buildNumber,
  });

  const api = tenantDiscoveryApiFactory(client, $config);
  registerAPI(api, 'tenant');

  inject('tenantApi', api);
});
