



















import {
  computed,
  defineComponent,
  PropType,
  toRef,
  useContext,
} from '@nuxtjs/composition-api';
import { NuxtError } from '@nuxt/types';
import DefaultErrorTemplate from '~mod:layout/components/templates/default-error-template.vue';
import ForbiddenErrorTemplate from '~mod:layout/components/templates/403-error-template.vue';
import ForbiddenTenantErrorTemplate from '~mod:layout/components/templates/403-tenant-error-template.vue';
import NotFoundErrorTemplate from '~mod:layout/components/templates/404-error-template.vue';

const FETCH_USER_PATH = '/Users/Self';
// const FETCH_TENANT_PATH = '/Tenants/Self';

export default defineComponent({
  name: 'ErrorLayoutPage',
  components: {
    DefaultErrorTemplate,
    ForbiddenErrorTemplate,
    ForbiddenTenantErrorTemplate,
    NotFoundErrorTemplate,
  },
  layout: ({ app }) => {
    const statusCode = app.nuxt.err?.statusCode;
    const url = (app.nuxt.err?.response as Response | undefined)?.url;

    if (statusCode && url) {
      const urlPath = new URL(url).pathname.toLowerCase();
      const isUsersFetch = urlPath === FETCH_USER_PATH.toLowerCase();
      // const isTenantsFetch = urlPath === FETCH_TENANT_PATH.toLowerCase();
      const is403 = statusCode === 403;
      const isUnauthorizedTenantUser = is403 && isUsersFetch;

      if (isUnauthorizedTenantUser) {
        return 'empty';
      }
    }

    return 'default';
  },
  props: {
    error: {
      type: Object as PropType<NuxtError | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const error = toRef(props, 'error');
    const statusCode = computed(() => error.value?.statusCode);
    const message = computed(
      () => error.value?.message || 'Oops.. Something went wrong.'
    );

    let isUnauthorizedTenantUser = false;

    const { app, route } = useContext();
    const url = (app.nuxt.err?.response as Response | undefined)?.url;

    if (url) {
      const urlPath = new URL(url).pathname.toLowerCase();
      const isUsersFetch = urlPath === FETCH_USER_PATH.toLowerCase();
      const is403 = statusCode.value === 403;
      isUnauthorizedTenantUser = is403 && isUsersFetch;
    }

    const isAuth0Error =
      'error' in route.value.query && 'error_description' in route.value.query;

    return { statusCode, message, isUnauthorizedTenantUser, isAuth0Error };
  },
  head() {
    return {
      title: (this as any).message || 'An error has occurred.',
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
    };
  },
});
