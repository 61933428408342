export class BodyboxStorageLocation {
  public readonly id!: string;
  public readonly name!: string;
  public readonly columnPosition!: number;
  public readonly rowPosition?: number;

  constructor(args?: BodyboxStorageLocation) {
    if (!args) return;
    this.id = args.id;
    this.name = args.name;
    this.columnPosition = args.columnPosition;
    this.rowPosition = args.rowPosition;
  }
}

export class BodyboxStorageConfiguration {
  public readonly storageLabel!: string;
  public readonly locations!: BodyboxStorageLocation[];

  constructor(args?: BodyboxStorageConfiguration) {
    if (!args) return;
    this.storageLabel = args.storageLabel;
    this.locations = args.locations;
  }
}
