import { Type } from 'class-transformer';

export enum CaseInsightType {
  Unknown = 0,
  Autopsy = 1,
  DonationProcurement = 2,
  MedicolegalInvestigation = 3,
  FuneralHome = 4,
}

export class CaseSummaryInsightTask {
  public readonly type!: CaseInsightType;
  public readonly name!: string;
  public readonly isCompleted!: boolean;
}

export class CaseSummaryInsights {
  @Type(() => CaseSummaryInsightTask)
  public readonly tasks!: CaseSummaryInsightTask[];

  constructor(args?: Partial<CaseSummaryInsights>) {
    this.tasks = args?.tasks || [];
  }
}
