/* eslint-disable camelcase */
import {
  DEfAULT_COLOR,
  COLOR_RED,
  COLOR_PINK,
  COLOR_ORANGE,
  COLOR_YELLOW,
  COLOR_GREEN,
  COLOR_BLUE,
  COLOR_PURPLE,
  COLOR_BLACK,
} from '~/app/components/ui/color-picker';

const Palette = {
  default: DEfAULT_COLOR,
  red: COLOR_RED,
  pink: COLOR_PINK,
  orange: COLOR_ORANGE,
  yellow: COLOR_YELLOW,
  green: COLOR_GREEN,
  blue: COLOR_BLUE,
  purple: COLOR_PURPLE,
  black: COLOR_BLACK,
} as const;

export type PaletteColor = keyof typeof Palette;

export type ColorLevel =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

export function useColorPickerColor(
  color: PaletteColor | string,
  level: ColorLevel | number,
  palette:
    | typeof Palette
    | Record<string, { [level: number]: string } | string> = Palette
) {
  const paletteColor = palette[color as keyof typeof palette];

  if (typeof paletteColor === 'string') return paletteColor;

  if (level in paletteColor)
    return (paletteColor as Record<number, string>)[level];

  const nearestLevel = Object.keys(palette[color as keyof typeof palette])
    // .reverse() // higher levels take precedence
    .reduce((prev, cur) => {
      const previous = parseInt(prev.toString(), 10);
      const current = parseInt(cur.toString(), 10);
      return Math.abs(current - level) < Math.abs(previous - level)
        ? current
        : previous;
    }, 0);

  return (paletteColor as Record<number, string>)[nearestLevel];
}

export type ColorToken = { color: string; level: number };
export function useColorPickerToken(
  hexColor: string,
  palette:
    | typeof Palette
    | Record<string, { [level: number]: string } | string> = Palette
): ColorToken | null {
  const paletteColors = Object.entries(palette);

  for (const [colorName, shades] of paletteColors) {
    const shadeEntries = Object.entries(shades);
    const entry = shadeEntries.find(
      ([_shadeLevel, hex]) => hex.toLowerCase() === hexColor.toLowerCase()
    );

    if (entry) return { color: colorName, level: parseInt(entry[0], 10) };
  }

  return null;
}

export function hasColor(
  color: PaletteColor,
  level: ColorLevel,
  palette:
    | typeof Palette
    | Record<string, { [level: number]: string }> = Palette
) {
  const paletteColor = palette[color];

  if (typeof paletteColor === 'string') return true;

  return level in paletteColor;
}
