import Vue, { VueConstructor, PluginObject } from 'vue';
import WbAlert from './alert.vue';
import WbAlertIcon from './alert-icon.vue';

const alert: VueConstructor<Vue> & PluginObject<void> = WbAlert as any;
alert.install = function (vue) {
  vue.component('WbAlert', WbAlert);
  vue.component('WbAlertIcon', WbAlertIcon);
};

export { WbAlert, WbAlertIcon };
export default alert;
