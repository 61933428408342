import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { IApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsLogger, LogProvider } from '~/app/core/logging';

export default defineNuxtPlugin(({ $appInsights, $log, isDev }) => {
  if (isDev) return;

  ($log as LogProvider).addLogger(
    new AppInsightsLogger($appInsights as IApplicationInsights)
  );
});
