/* eslint-disable no-useless-constructor */
import { SubjectRawRule } from '@casl/ability';
import { Action, Subject } from '~/app/core/casl/types';

export class AccessScope<TCondition = undefined>
  implements SubjectRawRule<Action, Subject, TCondition> {
  constructor(
    /** * The scope actions ex. 'create', 'update', 'read' */
    public readonly action: Action,
    /** * The scope subject ex. 'user', 'case' */
    public readonly subject: Subject,
    /** Access Condition ex. { id: 12345 } -> can access if id is 12345 */
    public readonly conditions?: TCondition,
    /** Specific model fields to apply the rule to */
    public readonly fields?: string[],
    /**
     * Inverts the rules action/conditions
     * @default false
     */
    public readonly inverted: boolean = false,
    /** mainly to specify why user can't do something. See forbidden reasons for details */
    public readonly reason?: string
  ) {}

  public toString() {
    return this.action;
  }

  /**
   * @deprecated
   * Creates a new array of access scopes where each subject is a string. If the access scope `action` is an array then the spread array will contain one access scope for each item the `action` array. Otherwise the returned array will contain one item, being the calling access scope instance. */
  public spreadAction() {
    if (Array.isArray(this.action))
      return this.action.map(
        (action) =>
          new AccessScope(
            action,
            this.subject,
            this.conditions,
            this.fields,
            this.inverted,
            this.reason
          )
      );
    return [this];
  }
}
