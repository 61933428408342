import { extend } from 'vee-validate';
import { SocialSecurityNumber } from '~/app/models';

extend('ssn', {
  validate(value) {
    if (typeof value !== 'string') return false;
    try {
      const ssn = new SocialSecurityNumber(value);
      return ssn.isValid;
    } catch (error) {
      return false;
    }
  },
  message: (_field, args) => {
    const value = args._value_;
    try {
      new SocialSecurityNumber(value).validate();
      return ''; // this should never be reached
    } catch (error) {
      return (error as TypeError).message;
    }
  },
});
