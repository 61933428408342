






import {
  defineComponent,
  getCurrentInstance,
  PropType,
  provide,
  toRefs,
} from '@nuxtjs/composition-api';
import {
  SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_NAME,
  SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_VALUE,
  SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP,
} from './symbols';

export default defineComponent({
  name: 'ToolbarButtonRadioGroup',
  props: {
    value: {
      type: [String, Number, Boolean] as PropType<string | number | boolean>,
      required: false,
      default: undefined,
    },
    name: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const { value, name } = toRefs(props);
    provide(SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP, getCurrentInstance()?.proxy);
    provide(SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_VALUE, value);
    provide(SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_NAME, name);
  },
});
