















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ScanCounter',
  props: {
    count: {
      type: Number,
      required: true,
    },
    complete: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props) {
    function isComplete(scan: number) {
      return scan <= props.complete;
    }

    function numToWord(num: number) {
      switch (num) {
        case 1:
          return 'one';
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        case 5:
          return 'five';
        case 6:
          return 'six';
        case 7:
          return 'seven';
        case 8:
          return 'eight';
        case 9:
          return 'nine';
        default:
          return 'one';
      }
    }

    return { isComplete, numToWord };
  },
});
