import Vue from 'vue';
import { defineComponent, PropType } from '@vue/composition-api';
import {
  SubjectType,
  Generics,
  AnyAbility,
  Ability,
  Abilities,
  IfString,
  AbilityTuple,
} from '@casl/ability';
import { injectAbility } from '../inject-ability';

type AbilityCanProps<
  T extends Abilities,
  Else = IfString<T, { do: T } | { I: T }>
> = T extends AbilityTuple
  ?
      | { do: T[0]; on: T[1]; field?: string }
      | { I: T[0]; a: Extract<T[1], SubjectType>; field?: string }
      | { I: T[0]; an: Extract<T[1], SubjectType>; field?: string }
      | { I: T[0]; this: Exclude<T[1], SubjectType>; field?: string }
  : Else;

export type CanProps<T extends AnyAbility> = AbilityCanProps<
  Generics<T>['abilities']
> & {
  not?: boolean;
  passThrough?: boolean;
};

type VueAbility = Vue['$ability'] extends { $ability: AnyAbility }
  ? Vue['$ability']
  : Ability;

function detectSubjectProp(props: Record<string, unknown>) {
  if ('a' in props) {
    return 'a';
  }

  if ('this' in props) {
    return 'this';
  }

  if ('an' in props) {
    return 'an';
  }

  return '';
}

export const Can = defineComponent<CanProps<VueAbility>>({
  name: 'Can',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    I: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    do: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    a: {
      type: [String, Function] as PropType<string | Function>,
      required: false,
      default: undefined,
    },
    an: {
      type: [String, Function] as PropType<string | Function>,
      required: false,
      default: undefined,
    },
    this: {
      type: [String, Function] as PropType<string | Function | Object>,
      required: false,
      default: undefined,
    },
    on: {
      type: [String, Function] as PropType<string | Function | Object>,
      required: false,
      default: undefined,
    },
    not: Boolean as PropType<Boolean>,
    passThrough: Boolean as PropType<Boolean>,
    field: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
  },
  setup(props, { slots }) {
    const $props = props as Record<string, any>;
    let actionProp = 'do';
    let subjectProp = 'on';

    if (!(actionProp in props)) {
      actionProp = 'I';
      subjectProp = detectSubjectProp(props);
    }

    if (!$props[actionProp]) {
      throw new Error('Neither `I` nor `do` prop was passed in <Can>');
    }

    if (!slots.default) {
      throw new Error('Expects to receive default slot');
    }

    const ability = injectAbility<VueAbility>();

    return () => {
      const isAllowed = ability.can(
        $props[actionProp],
        $props[subjectProp],
        $props.field
      );

      const canRender = props.not ? !isAllowed : isAllowed;

      if (!props.passThrough) {
        return canRender ? slots.default!() : null;
      }

      return slots.default!({
        allowed: canRender,
        ability,
      }) as any;
    };
  },
});
