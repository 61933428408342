import Vue, { VueConstructor, PluginObject } from 'vue';
import WbToolbar from './toolbar.vue';
import WbToolbarButton from './toolbar-button.vue';
import WbToolbarButtonRadio from './toolbar-button-radio.vue';
import WbToolbarButtonRadioGroup from './toolbar-button-radio-group.vue';
import WbToolbarDrawer from './toolbar-drawer.vue';
import WbToolbarDropdown from './toolbar-dropdown.vue';
import WbToolbarGroup from './toolbar-group.vue';
import WbToolbarItem from './toolbar-item.vue';
import WbToolbarSpacer from './toolbar-spacer.vue';

const toolbar: VueConstructor<Vue> & PluginObject<void> = WbToolbar as any;
toolbar.install = function (vue) {
  vue.component('WbToolbar', WbToolbar);
  vue.component('WbToolbarButton', WbToolbarButton);
  vue.component('WbToolbarButtonRadio', WbToolbarButtonRadio);
  vue.component('WbToolbarButtonRadioGroup', WbToolbarButtonRadioGroup);
  vue.component('WbToolbarDrawer', WbToolbarDrawer);
  vue.component('WbToolbarDropdown', WbToolbarDropdown);
  vue.component('WbToolbarGroup', WbToolbarGroup);
  vue.component('WbToolbarItem', WbToolbarItem);
  vue.component('WbToolbarSpacer', WbToolbarSpacer);
};

export {
  WbToolbar,
  WbToolbarButton,
  WbToolbarButtonRadio,
  WbToolbarButtonRadioGroup,
  WbToolbarDrawer,
  WbToolbarDropdown,
  WbToolbarGroup,
  WbToolbarItem,
  WbToolbarSpacer,
};
export default toolbar;
