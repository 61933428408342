import { plainToInstance, Transform } from 'class-transformer';
import { FormatDate } from '../format-date';
import { FormatDateTime } from '../format-datetime';
import { parseISODateOnly, parseISODateTime } from '../utils';
import { DateTimeInfo, DateTimeInfoOptions } from '../date-time-info';
import { DateOnly } from '../date-only';

/** Specifies the type of the property as @see FormatDate. Required for use with `class-transformer`. */
export const IsFormatDate: PropertyDecorator = Transform(({ value }) =>
  value ? new FormatDate(value) : undefined
);

/** Specifies the type of the property as @see FormatDateTime. Required for use with `class-transformer`. */
export const IsFormatDateTime: PropertyDecorator = Transform(({ value }) =>
  value ? new FormatDateTime(value) : undefined
);

export const IsDateTimeInfo: (
  serialize?: DateTimeInfoOptions['serialize']
) => PropertyDecorator = (serialize = 'dateTime') =>
  Transform(({ value }) => {
    if (!value) return undefined;

    if (typeof value === 'string') {
      const date = parseISODateOnly(value);
      if (date) return new DateTimeInfo(new DateOnly(date), { serialize });

      const dateTime = parseISODateTime(value);
      if (dateTime) return new DateTimeInfo(dateTime, { serialize });
    }

    if (typeof value === 'object' && ('date' in value || 'time' in value))
      return plainToInstance(DateTimeInfo, value);

    return undefined;
  });
