import type { ActionTree } from 'vuex';
import type { ICaseDetailsState } from './state';
import { Mutation } from './mutations';
import { RootState } from '~/store';
import { Case } from '~/app/models';
import { CreateCaseRequest } from '~/app/core/api/requests/cases/create-case-request';
import { UpdateCaseRequest } from '~/app/core/api/requests/cases/update-case-request';
import {
  CheckInCaseRequest,
  CreateAndCheckInCaseRequest,
  CheckoutCaseRequest,
  CreateAndCheckOutCaseRequest,
} from '~/app/core/api/requests/cases/transport';
import { DeleteCaseRequest } from '~/app/core/api/requests/cases/delete-case-request';
import {
  TransferCaseExternalRequest,
  TransferCaseInternalRequest,
} from '~/app/core/api/requests/cases/transfer-case-request';
import { CreateCheckoutReportRequest } from '~/app/core/api/requests/cases/create-checkout-report-request';

export enum Action {
  fetchCase = 'fetchCase',
  createCase = 'createCase',
  createAndCheckIn = 'createAndCheckIn',
  createAndCheckout = 'createAndCheckout',
  save = 'saveCase',
  delete = 'deleteCase',
  checkIn = 'checkInCase',
  checkout = 'checkoutCase',
  transferInternal = 'transferInternal',
  transferExternal = 'transferExternal',
  createCheckoutPermissionReport = 'createCheckoutPermissionReport',
}

export const actions: ActionTree<ICaseDetailsState, RootState> = {
  async [Action.fetchCase](_context, caseId: string) {
    const details = await this.$api.cases.getAsync(caseId);
    return details;
  },
  /**
   * Creates a new case
   * @param context ActionContext.
   * @param case Case details being created.
   */
  async [Action.createCase]({ commit }, details: Case) {
    const request = new CreateCaseRequest(details);
    const newCase = await this.$api.cases.createAsync(request);

    commit(Mutation.setCase, newCase);

    return newCase;
  },
  /**
   * Creates a new case and immediately checks it in.
   * @param context ActionContext.
   * @param case Case details being created and checked in.
   */
  async [Action.createAndCheckIn](
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    request: CreateAndCheckInCaseRequest
  ) {
    const newCase = await this.$api.cases.createAndCheckInAsync(request);

    // commit(Mutation.setCase, newCase);

    return newCase;
  },
  /**
   * Creates a new case and immediately checks it out.
   * @param context ActionContext.
   * @param case Case details being created and checked out.
   */
  async [Action.createAndCheckout](
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    request: CreateAndCheckOutCaseRequest
  ) {
    const newCase = await this.$api.cases.createAndCheckOutAsync(request);

    // commit(Mutation.setCase, newCase);

    return newCase;
  },
  /**
   * This action is meant to be subscribed to by other modules who are then responsible for saving their own sections of of the case.
   * @param context ActionContext.
   * @param caseDetails Case details being saved.
   */
  async [Action.save](
    { commit },
    { caseDetails, signal }: { caseDetails: Case; signal?: AbortSignal }
  ) {
    // @ts-ignore
    signal?.throwIfAborted();

    const request = new UpdateCaseRequest(caseDetails);
    const updatedCase = await this.$api.cases.updateAsync(request);

    // @ts-ignore
    signal?.throwIfAborted();
    commit(Mutation.setCase, updatedCase);

    return updatedCase;
  },
  /**
   * Permanently deletes a case or reservation.
   * @param context ActionContext.
   * @param deleteRequest The request containing delete details.
   */
  async [Action.delete](_context, deleteRequest: DeleteCaseRequest) {
    await this.$api.cases.deleteAsync(deleteRequest);
  },
  /**
   * Creates a check-in event on the case.
   * @param context ActionContext.
   * @param checkInRequest The request containing check-in details.
   */
  async [Action.checkIn](_context, checkInRequest: CheckInCaseRequest) {
    const updatedCase = await this.$api.cases.transport.checkInAsync(
      checkInRequest
    );
    return updatedCase;
  },
  /**
   * Creates a checkout event on the case.
   * @param context ActionContext.
   * @param checkInRequest The request containing checkout details.
   */
  async [Action.checkout](_context, checkoutRequest: CheckoutCaseRequest) {
    const updatedCase = await this.$api.cases.transport.checkoutAsync(
      checkoutRequest
    );
    return updatedCase;
  },
  /**
   * Creates an internal transfer event on the case.
   * @param context ActionContext.
   * @param transferRequest The request containing internal transfer details.
   */
  async [Action.transferInternal](
    _context,
    transferRequest: TransferCaseInternalRequest
  ) {
    const updatedCase = await this.$api.cases.transport.transferInternalAsync(
      transferRequest
    );
    return updatedCase;
  },
  /**
   * Creates an external transfer event on the case.
   * @param context ActionContext.
   * @param transferRequest The request containing external transfer details.
   */
  async [Action.transferExternal](
    _context,
    transferRequest: TransferCaseExternalRequest
  ) {
    const updatedCase = await this.$api.cases.transport.transferExternalAsync(
      transferRequest
    );
    return updatedCase;
  },
  /**
   * Creates a transport permission report for a case.
   * @param context ActionContext.
   * @param caseDetails The case details for which the report is being created.
   */
  async [Action.createCheckoutPermissionReport](_context, caseDetails: Case) {
    const request = new CreateCheckoutReportRequest(caseDetails);
    const report = await this.$api.cases.transport.createCheckOutPermissionReportAsync(
      request
    );
    return report;
  },
};
