import { state, ISettingsState } from './state';
import { actions, Action } from './actions';
import { createModule } from '~/store';

export { Action };
export type { ISettingsState };

export const moduleName = 'settings';
export const { module } = createModule<ISettingsState>(moduleName, {
  state,
  actions,
});
