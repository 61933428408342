


































/* eslint-disable camelcase */
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  PropType,
} from '@nuxtjs/composition-api';
import { Swatches } from 'vue-color';
import { DEfAULT_COLOR, DEFAULT_SWATCHES } from './constants';

export default defineComponent({
  name: 'ColorSwatchPicker',
  components: {
    ColorPicker: Swatches,
  },
  props: {
    value: {
      type: [String, Object] as PropType<string | object>,
      required: true,
    },
    palette: {
      type: Array as PropType<Array<(string | object)[]>>,
      required: false,
      default: () => DEFAULT_SWATCHES,
    },
    defaultColor: {
      type: String as PropType<string>,
      required: false,
      default: () => DEfAULT_COLOR,
    },
  },
  emits: {
    input: null, // (value: unknown) => typeof value === 'string',
  },
  setup(_, { emit }) {
    onMounted(() => {
      const instance = getCurrentInstance();
      const optionEls = instance?.proxy.$el.querySelectorAll('[role="option"]');
      optionEls?.forEach((el) => ((el as HTMLElement).tabIndex = 0));
    });

    const select = (color: string | { hex: string }) => {
      emit('input', typeof color === 'object' ? color.hex : color);
    };

    return { select };
  },
});
