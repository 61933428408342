export class Location {
  public readonly id?: string;
  public name?: string;
  public streetAddress?: string;
  public city?: string;
  public state?: string;
  public zipCode?: string;
  public phoneNumber?: string;
  public emailAddress?: string;

  constructor(args?: Location) {
    this.id = args?.id;
    this.name = args?.name;
    this.streetAddress = args?.streetAddress;
    this.city = args?.city;
    this.state = args?.state;
    this.zipCode = args?.zipCode;
    this.phoneNumber = args?.phoneNumber;
    this.emailAddress = args?.emailAddress;
  }
}
