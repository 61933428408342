import Vue, { VueConstructor, PluginObject } from 'vue';
import WbFolder from './folder.vue';
import WbFolderTab from './folder-tab.vue';

const folder: VueConstructor<Vue> & PluginObject<void> = WbFolder as any;
folder.install = function (vue) {
  vue.component('WbFolder', WbFolder);
  vue.component('WbFolderTab', WbFolderTab);
};

export { WbFolder, WbFolderTab };
export default folder;
