import { MutationTree } from 'vuex';
import { IAccessState } from './state';
import { AccessScopeMasterList, User } from '~/app/models';

export enum Mutation {
  /** @deprecated use Action.setUser instead. */
  setUser = 'SET_USER',
  setAccessScopeMasterList = 'SET_ACCESS_SCOPE_MASTER_LIST',
}

export const mutations: MutationTree<IAccessState> = {
  [Mutation.setUser](state, user: Readonly<User>) {
    state.user = user;
  },
  [Mutation.setAccessScopeMasterList](
    state,
    masterList: Readonly<AccessScopeMasterList>
  ) {
    state.accessScopeMasterList = masterList;
  },
};

export default mutations;
