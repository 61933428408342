const middleware = {}

middleware['access'] = require('../middleware/access.ts')
middleware['access'] = middleware['access'].default || middleware['access']

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['module-activator'] = require('../middleware/module-activator.ts')
middleware['module-activator'] = middleware['module-activator'].default || middleware['module-activator']

middleware['support'] = require('../middleware/support.ts')
middleware['support'] = middleware['support'].default || middleware['support']

middleware['tenant'] = require('../middleware/tenant.ts')
middleware['tenant'] = middleware['tenant'].default || middleware['tenant']

export default middleware
