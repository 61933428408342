import { ILogger, LoggerArg } from '../ILogger';

// const log = console.log.bind(window.console);
// const info = console.info.bind(window.console);
// const warn = console.warn.bind(window.console);
// const debug = console.debug.bind(window.console);
// const error = console.error.bind(window.console);
// const track = console.log.bind(window.console, 'Tracked:');

///
/// Implementations for printing with correct file names/line numbers
/// https://github.com/akoidan/lines-logger
///

export class ConsoleLogger implements ILogger {
  public log(message: string, data?: LoggerArg) {
    const args = ([message] as any[]).concat(data ? [data] : []);
    console.log.call(window.console, ...args);
  }

  public info(message: string, data?: LoggerArg) {
    const args = ([message] as any[]).concat(data ? [data] : []);
    console.info.call(window.console, ...args);
  }

  public warn(message: string, data?: LoggerArg) {
    const args = ([message] as any[]).concat(data ? [data] : []);
    console.warn.call(window.console, ...args);
  }

  public debug(message: string, data?: LoggerArg) {
    const args = ([message] as any[]).concat(data ? [data] : []);
    console.debug.call(window.console, ...args);
  }

  public error(message: string | Error, data?: LoggerArg) {
    const errorInstance =
      message instanceof Error ? message : new Error(message);

    const args = ([errorInstance] as any[]).concat(data ? [data] : []);
    console.error.call(window.console, ...args);
  }

  public track(event: string, data?: LoggerArg) {
    const args = (['Tracked:', event] as any[]).concat(data ? [data] : []);
    console.log.call(window.console, ...args);
  }
}
