import { TagDisplayOptions, TagType } from '../tag';
import { DictionaryItem } from './dictionary-item';

export class SystemTagDictionaryItem extends DictionaryItem {
  public readonly type: TagType;
  public readonly triggersCaseHold: boolean;
  public readonly displayOptions?: TagDisplayOptions;

  constructor(args?: Omit<SystemTagDictionaryItem, 'triggersCaseHold'>) {
    super(args);
    this.type = args?.type ?? TagType.Case;
    this.triggersCaseHold = args?.type === TagType.Hold;
    this.displayOptions = args?.displayOptions;
  }
}

export class ComputedTagDictionaryItem {
  public readonly name!: string;
  public readonly triggersCaseHold!: boolean;
  public readonly description!: string;

  constructor(args?: ComputedTagDictionaryItem) {
    if (!args) return;
    this.name = args.name;
    this.triggersCaseHold = args.triggersCaseHold;
    this.description = args.description;
  }
}
