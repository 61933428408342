import { Type } from 'class-transformer';
import { CaseService } from './case-service';
import { CaseServiceCollection } from './case-services';

export class Autopsy extends CaseService {}

export class AutopsyServiceCollection extends CaseServiceCollection<Autopsy> {
  @Type(() => Autopsy)
  public readonly instances!: Autopsy[];

  constructor(args?: Partial<AutopsyServiceCollection>) {
    super(args);
    this.instances = args?.instances
      ? args.instances.map((i) => new Autopsy(i))
      : [];
  }
}
