









import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useStoreModule } from '~/app/composables/use-store-module';
import { Tenant } from '~/app/models';
import { ITenantState, moduleName as TenantModuleName } from '~/store/tenant';

export default defineComponent({
  name: 'TheTenantLogo',
  setup() {
    const { state: tenantState } = useStoreModule<ITenantState>(
      TenantModuleName
    );

    const logoUrl = computed(
      () => tenantState.tenant?.configuration.theme.logoUrl
    );

    const tenantName = computed(() => {
      const tenant = tenantState.tenant as Tenant;
      return tenant.name;
    });

    return { logoUrl, tenantName };
  },
});
