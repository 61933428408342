import { NuxtHTTPInstance } from '@nuxt/http';
// eslint-disable-next-line import/named
import ky, { BeforeRequestHook, BeforeRetryHook, Options } from 'ky';

export interface IApiClientRequest {
  params?: { [key: string]: string };
  options?: Omit<Options, 'body'>;
}

export type HTTPError = ky.HTTPError;

type RequestMethod = Uppercase<
  keyof Pick<
    NuxtHTTPInstance,
    'get' | 'post' | 'put' | 'patch' | 'head' | 'delete'
  >
>;

export class ApiClient {
  constructor(private readonly client: NuxtHTTPInstance) {
    client.setHeader('Content-Type', 'application/json');
  }

  public async requestAsync(
    endpoint: string,
    request: { method: RequestMethod } & IApiClientRequest
  ): Promise<Response> {
    const url = this.buildURLWithQueryParameters(endpoint, request?.params);
    const method = request.method.toLowerCase() as Lowercase<RequestMethod>;
    const response = await this.client[method](url);
    return response;
  }

  public async getAsync<TReturn = any>(
    endpoint: string,
    request?: IApiClientRequest
  ): Promise<TReturn> {
    const url = this.buildURLWithQueryParameters(endpoint, request?.params);
    const data = await this.client.$get<TReturn>(url, request?.options);
    return data;
  }

  public async deleteAsync<TReturn = any, TBody = any>(
    endpoint: string,
    body?: TBody,
    request?: IApiClientRequest
  ): Promise<TReturn> {
    const url = this.buildURLWithQueryParameters(endpoint, request?.params);
    const response = await this.client.delete(url, {
      ...(request?.options || {}),
      json: body,
    });

    const text = await response.text();
    return (text ? JSON.parse(text) : null) as TReturn;
  }

  public async postAsync<TReturn = any, TBody = any>(
    endpoint: string,
    body?: TBody,
    request?: IApiClientRequest
  ): Promise<TReturn> {
    const url = this.buildURLWithQueryParameters(endpoint, request?.params);

    const data = await this.client.$post<TReturn>(
      url,
      this.serializeRequestBody(body),
      request?.options
    );
    return data;
  }

  public async putAsync<TReturn = any, TBody = any>(
    endpoint: string,
    body?: TBody,
    request?: IApiClientRequest
  ): Promise<TReturn> {
    const url = this.buildURLWithQueryParameters(endpoint, request?.params);
    const data = await this.client.$put<TReturn>(
      url,
      this.serializeRequestBody(body),
      request?.options
    );
    return data;
  }

  public setHeaders(headers: {
    [key: string]: string | number | Date | Boolean;
  }) {
    Object.entries(headers).forEach(([name, value]) => {
      this.client.setHeader(
        name,
        [null, undefined].includes(value as any) ? false : value.toString()
      );
    });
  }

  public getHeaders() {
    return this.getDefaults().headers;
  }

  private getDefaults() {
    return (this.client as any)._defaults as {
      headers: Record<string, any>;
      hooks: {
        beforeRequest?: BeforeRequestHook[];
        beforeRetry?: BeforeRetryHook[];
      };
      prefixUrl: string;
      retry: number;
      timeout: boolean;
    };
  }

  private serializeRequestBody(body: any) {
    if (typeof body === 'string') {
      return body;
    }

    if (body instanceof FormData) {
      return body;
    }

    if (typeof body === 'object') {
      return JSON.stringify(body);
    }

    return body?.toString();
  }

  private buildURLWithQueryParameters(
    endpoint: string,
    params?: { [key: string]: string }
  ): string {
    let url = this.trimURLBackSlashes(endpoint);

    const isNil = (value: any) => [undefined, null].includes(value);

    if (params) {
      url += '?';
      Object.entries(params).forEach(([key, value], index, arr) => {
        const isLastElement = index === arr.length - 1;

        if (isNil(value)) return;

        url += encodeURIComponent(key) + '=' + encodeURIComponent(value);
        !isLastElement && (url += '&');
      });
    }

    return url.replace(/&\s*$/, '').trim(); // trim any trailing '&'
  }

  private trimURLBackSlashes(url: string) {
    return url.replace(/^\/|\/$/g, '').trim();
  }
}

export type GetTokenFunc = () =>
  | string
  | undefined
  | Promise<string | undefined>;

export function createApiClient(
  api: NuxtHTTPInstance,
  apiOptions?: {
    getToken?: GetTokenFunc;
    refreshOnUnauthorized?: boolean;
  }
) {
  const appendAuthToken: BeforeRequestHook = async (request, _options) => {
    if (!apiOptions?.getToken) return request;

    const token = await Promise.resolve(apiOptions.getToken());

    if (token) request.headers.set('Authorization', `Bearer ${token}`);

    return request;
  };

  const tryRefreshToken: BeforeRetryHook = async (options) => {
    if (
      apiOptions?.getToken &&
      options.retryCount === 1 &&
      options.response.status === 401 &&
      apiOptions?.refreshOnUnauthorized
    ) {
      // Attempt to refresh auth token
      const token = await Promise.resolve(apiOptions.getToken());

      if (token)
        options.request.headers.set('Authorization', `Bearer ${token}`);
    }
  };

  if (apiOptions?.getToken) {
    api.onRequest(appendAuthToken);
    apiOptions?.refreshOnUnauthorized && api.onRetry(tryRefreshToken);
  }

  return new ApiClient(api);
}
