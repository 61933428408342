import { AddDictionaryItemRequest, EditDictionaryItemRequest } from './base';

export class AddNextOfKinTypeRequest extends AddDictionaryItemRequest {
  public readonly triggersCaseHold: boolean;

  constructor(args: AddNextOfKinTypeRequest) {
    super(args);
    this.triggersCaseHold = args.triggersCaseHold;
  }
}

export class EditNextOfKinTypeRequest extends EditDictionaryItemRequest {
  public readonly triggersCaseHold: boolean;

  constructor(args: EditNextOfKinTypeRequest) {
    super(args);
    this.triggersCaseHold = args.triggersCaseHold;
  }
}
