












import {
  computed,
  defineComponent,
  inject,
  PropType,
  toRefs,
} from '@nuxtjs/composition-api';
import { SYMBOL_TAB_BAR_MENU_IS_CHILD } from './symbols';

export default defineComponent({
  name: 'TabBarMenuTrigger',
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
      required: true,
    },
    isActive: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    orientation: {
      type: String as PropType<'horizontal' | 'vertical' | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const { orientation } = toRefs(props);

    const isInMenu = inject(SYMBOL_TAB_BAR_MENU_IS_CHILD, false);
    const orien = computed(
      () => orientation.value || (isInMenu ? 'horizontal' : 'vertical')
    );

    return { orien };
  },
});
