

























import {
  ComponentInstance,
  computed,
  defineComponent,
  inject,
  PropType,
  ref,
  toRefs,
  unref,
} from '@nuxtjs/composition-api';
import { templateRef } from '@vueuse/core';
import {
  SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_NAME,
  SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_VALUE,
  SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP,
} from './symbols';
import ToolbarButton from './toolbar-button.vue';

export default defineComponent({
  name: 'ToolbarButtonRadio',
  components: { ToolbarButton },
  props: {
    value: {
      type: [String, Number, Boolean] as PropType<string | number | boolean>,
      required: true,
    },
    name: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const { value } = toRefs(props);
    const radioGroup = inject<ComponentInstance>(
      SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP
    );
    const groupValue = inject<any>(SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_VALUE);
    const groupName = inject<any>(SYMBOL_TOOLBAR_BUTTON_RADIO_GROUP_NAME);

    const isChecked = computed(() => unref(value) === unref(groupValue));
    const isFocused = ref(false);

    const radio = templateRef<HTMLInputElement>('radio');

    const onClick = () => {
      radio.value.click();
    };

    const onFocus = () => {
      isFocused.value = true;
    };

    const onBlur = () => {
      isFocused.value = false;
    };

    const onChange = (event: InputEvent) => {
      radioGroup?.$emit('input', unref(value), event);
    };

    return {
      groupName,
      isChecked,
      isFocused,
      onClick,
      onFocus,
      onBlur,
      onChange,
    };
  },
});
