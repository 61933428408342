import { Middleware } from '@nuxt/types';
import { AppModule } from '../module';
import {
  getActiveModules,
  deactivateModule,
  getRegisteredModules,
  useModule,
} from '~/app/core/module-system/use-module';

/**
 * Automates the activation and deactivation of route dependant modules.
 * @param context NuxtContext
 */
export const middleware: Middleware = ({ from, route }) => {
  const isWithinRoute = route.matched.some(({ name }) =>
    from.matched.map((r) => r.name).includes(name)
  );

  // Deactivate any route modules not associated with the new route
  if (!isWithinRoute) {
    // get all active modules tied to a route
    const activeModules = getActiveModules();
    const routeModules = activeModules.filter(
      (module) => !!module.options?.route
    );

    // get all route modules not tied to the new route
    const invalidatedRouteModules = routeModules.filter(
      (module) => module.options?.route !== route.name
    );

    // deactivate those modules
    for (const module of invalidatedRouteModules) {
      deactivateModule(module.name);

      if (AppModule.enableDebugLogging)
        console.debug(
          `[AppModule:Middleware:Activator] Deactivated module "${module.name}".`
        );
    }
  }

  // Get all inactive route modules that are linked to the new route
  const modules = getRegisteredModules();
  const routeModules = modules.filter((module) => !!module.options?.route);
  const modulesToActive = routeModules.filter(
    (module) =>
      !module.isActive &&
      route.matched.some(({ name }) => module.options?.route === name)
  );

  // Activate dependant modules
  for (const module of modulesToActive) {
    useModule(module.name);

    if (AppModule.enableDebugLogging)
      console.debug(
        `[AppModule:Middleware:Activator] Activated module "${module.name}".`
      );
  }
};
