import { FormatDateTime, IsFormatDateTime } from '../dates';
import { RequirementStatus } from './requirement-status';

export class MedicalExaminer {
  public requirementStatus?: RequirementStatus;

  public medicalExaminerId?: string;

  @IsFormatDateTime
  public dateNotified?: FormatDateTime;

  @IsFormatDateTime
  public dateCompleted?: FormatDateTime;

  constructor(args?: MedicalExaminer) {
    this.requirementStatus = args?.requirementStatus;
    this.medicalExaminerId = args?.medicalExaminerId;
    this.dateNotified = args?.dateNotified
      ? new FormatDateTime(args?.dateNotified)
      : undefined;
    this.dateCompleted = args?.dateCompleted
      ? new FormatDateTime(args?.dateCompleted)
      : undefined;
  }
}
