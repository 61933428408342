var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('calender-menu',{ref:"menu",attrs:{"placement":"bottom-start"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var triggerId = ref.triggerId;
var menuId = ref.menuId;
var isOpen = ref.isOpen;
return [_c('date-time-picker',_vm._g(_vm._b({ref:"picker",staticClass:"w-full",attrs:{"id":triggerId,"value":_vm.value,"include-time":_vm.includeTime,"is24-hour":_vm.is24Hour,"type":_vm.type,"size":_vm.size,"readonly":_vm.readonly,"disabled":_vm.disabled,"aria-haspopup":"menu","aria-controls":menuId,"aria-expanded":isOpen},on:{"input":_vm.onPickerInput,"focus":_vm.onPickerFocus,"blur":_vm.onPickerBlur},scopedSlots:_vm._u([{key:"prefix",fn:function(scope){return [_vm._t("prefix",null,null,scope)]}},{key:"suffix",fn:function(scope){return [_vm._t("suffix",null,null,scope)]}}],null,true)},'date-time-picker',_vm.$attrs,false),_vm.$listeners))]}},{key:"default",fn:function(ref){
var show = ref.show;
var hide = ref.hide;
var shown = ref.shown;
return [_vm._t("calendar:header",null,null,{ value: _vm.value, show: show, hide: hide, shown: shown }),_vm._v(" "),_c('v-calendar',{ref:"calendar",attrs:{"value":_vm.calendarDate,"mode":'date',"is24hr":_vm.is24Hour,"model-config":{ transition: 'slide-v' }},on:{"input":_vm.onCalendarInput}}),_vm._v(" "),(_vm.$scopedSlots['calendar:footer'])?_c('div',{staticClass:"border-t border-gray-300 pt-2"},[_vm._t("calendar:footer",null,null,{ value: _vm.value, show: show, hide: hide, shown: shown })],2):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }