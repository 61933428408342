import Vue, { VueConstructor, PluginObject } from 'vue';
import WbData from './data.vue';

const data: VueConstructor<Vue> & PluginObject<void> = WbData as any;
data.install = function (vue) {
  vue.component('WbData', WbData);
};

export { WbData };
export default data;
