export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

type RetryIntervalMultiplier = (interval: number) => number;

/**
 * Multiplies the interval by 2 on each retry.
 * @param i The interval to multiply
 * @returns The interval multiplied by 2
 */
export const DoublingRetryIntervalMultiplier: RetryIntervalMultiplier = (i) =>
  i * 2;

/**
 * Will keep the interval the same on each retry.
 * @param i The interval
 * @returns The interval
 */
export const LinearRetryIntervalMultiplier: RetryIntervalMultiplier = (i) => i;

/**
 * Runs the function `fn` and retries automatically if it fails.
 * @param fn The function to run
 * @param retriesLeft The number of retries left
 * @param interval The interval between retries
 * @param intervalMultiplier The function to calculate the next interval
 * @returns The result of the function
 */
export async function retry<TReturn>(
  fn: (...args: any[]) => Promise<TReturn> | TReturn,
  retriesLeft = 3,
  interval = 1000,
  intervalMultiplier: RetryIntervalMultiplier = DoublingRetryIntervalMultiplier
): Promise<TReturn> {
  try {
    return await fn();
  } catch (error) {
    if (retriesLeft === 0) {
      throw error;
    }

    await sleep(interval);

    return retry(
      fn,
      retriesLeft - 1,
      intervalMultiplier(interval),
      intervalMultiplier
    );
  }
}
