import Vue from 'vue';
import { defineNuxtPlugin, defineComponent } from '@nuxtjs/composition-api';
import { Ability, AnyAbility, PureAbility } from '@casl/ability';
import { reactiveAbility } from './reactive-ability';
import { provideAbility } from './inject-ability';

export interface AbilityPluginOptions {
  /** @default true */
  useGlobalProperties?: boolean;
}

export const createAbilitiesPlugin = <TAbility extends AnyAbility = Ability>(
  ability: TAbility,
  options: AbilityPluginOptions = { useGlobalProperties: true }
) => {
  if (!ability || !(ability instanceof PureAbility)) {
    throw new Error(
      'Please provide an Ability instance to abilitiesPlugin plugin'
    );
  }

  return defineNuxtPlugin((_context, inject) => {
    const $ability = reactiveAbility(ability);

    Vue.mixin(
      defineComponent({
        setup() {
          provideAbility($ability);
        },
      })
    );

    Vue.prototype.$ability = $ability;
    Vue.prototype.$can = $ability.can;

    if (options?.useGlobalProperties) {
      inject('ability', $ability);
    }
  });
};
