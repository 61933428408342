import { AppModule } from '~/app/core/module-system/module';

export class ReservationsModule extends AppModule {
  public readonly name = ReservationsModule.name;

  public activate() {
    super.activate();
    // call activation steps here
  }
}

export const ModuleName = ReservationsModule.name;
