export const progressConfig = {
  wrapper: 'w-full flex flex-col gap-2',
  indicator: {
    container: {
      base: 'flex flex-row justify-end',
      width: 'min-w-fit',
      transition: 'transition-all',
    },
    align: 'text-end',
    width: 'w-fit',
    color: 'text-gray-400',
    size: {
      '2xs': 'text-xs',
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-sm',
      lg: 'text-sm',
      xl: 'text-base',
      '2xl': 'text-base',
    },
  },
  progress: {
    size: {
      '2xs': 'h-px',
      xs: 'h-0.5',
      sm: 'h-1',
      md: 'h-2',
      lg: 'h-3',
      xl: 'h-4',
      '2xl': 'h-5',
    },
  },
  steps: {
    base: 'grid grid-cols-1',
    color: 'text-brand-500',
    size: {
      '2xs': 'text-xs',
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-sm',
      lg: 'text-sm',
      xl: 'text-base',
      '2xl': 'text-base',
    },
  },
  step: {
    base: 'transition-all opacity-0 truncate row-start-1 col-start-1',
    align: 'text-right',
    active: 'opacity-100',
    first: 'text-gray-500',
  },
  animation: {
    carousel: 'bar-animation-carousel',
    'carousel-inverse': 'bar-animation-carousel-inverse',
    swing: 'bar-animation-swing',
    elastic: 'bar-animation-elastic',
  },
  default: {
    size: 'md',
    animation: 'carousel',
  },
};
