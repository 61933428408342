





























































































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  onMounted,
  PropType,
  provide,
  Ref,
  ref,
  toRefs,
  watch,
} from '@nuxtjs/composition-api';
import MenuItem from './menu-item.vue';
import MenuTitle from './menu-title.vue';
import { useCollapsible } from '~/app/composables/use-collapsible';

export interface MenuItemProps {
  key: string;
  title: string;
  to?: string;
  icon?: string;
  disabled?: boolean;
  data?: Record<string, any>;
}

export default defineComponent({
  name: 'SubMenu',
  components: {
    MenuItem,
    MenuTitle,
  },
  props: {
    title: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    to: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    icon: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    ordered: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    type: {
      type: String as PropType<'fixed' | 'collapsible' | 'popover'>,
      default: 'fixed',
    },
    items: {
      type: Array as PropType<MenuItemProps[]>,
      required: false,
      default: undefined,
    },
  },
  setup(props, { slots }) {
    // #region keys/ids
    const key = getCurrentInstance()?.vnode.key;
    if (!key) throw new Error('[SubMenu] A "key" attribute is required.');

    const prefix = 'wb-submenu';
    const titleId = `${prefix}-${key}-title`;
    const menuId = `${prefix}-${key}-menu`;

    // #endregion

    // #region Menu level + spacing

    const menuLevel = inject('wb-menu.level', 1);
    const calcPadding = inject<(level: number) => number>(
      'wb-menu.calculatePadding'
    );

    provide('wb-menu.level', menuLevel + 1);
    const leftPadding = calcPadding?.(menuLevel) || 0;

    // #endregion

    // #region collapsible state

    const isCollapsed = ref(false);
    const { type } = toRefs(props);
    const isMenuCollapsible = inject<Ref<boolean>>('wb-menu.collapsible');
    const canCollapse = computed(
      () => type.value === 'collapsible' ?? isMenuCollapsible?.value ?? true
    );

    const toggleCollapsed = () =>
      canCollapse.value && (isCollapsed.value = !isCollapsed.value);

    const listRef = ref<HTMLDivElement>();
    onMounted(() => {
      const listEl = listRef.value as HTMLDivElement;
      const { collapseSection, expandSection } = useCollapsible(listEl);
      watch(
        () => isCollapsed.value,
        (collapsed) => (collapsed ? collapseSection() : expandSection())
      );
    });

    // #endregion

    // #region selection state

    const menuKeys = ref<string[]>([]);
    const rootAddMenuKey = inject<(key: string) => void>('wb-menu.addMenuKey');
    const addMenuKey = (key: string) => {
      menuKeys.value.push(key);
      rootAddMenuKey?.(key);
    };
    provide('wb-menu.addMenuKey', addMenuKey);

    const selectedKey = inject<Ref<string>>('wb-menu.selectedKey');
    const isActive = computed(
      () => selectedKey?.value && menuKeys.value.includes(selectedKey?.value)
    );

    // #endregion

    // #region Popover Menu

    const popoverItems = computed(() => {
      if (props.type !== 'popover') return [];
      if (props.items) return props.items;
      // Fallback to slot context if no item nodes are provided
      const menuItems = slots.default?.() || [];
      return menuItems
        .filter((item) => !!item?.componentOptions?.propsData)
        .map((item) => item!.componentOptions!.propsData! as MenuItemProps);
    });

    const search = ref('');
    const filteredPopoverItems = computed(() => {
      if (!popoverItems.value) return popoverItems.value;
      return popoverItems.value.filter(({ title }) =>
        title?.toLowerCase().includes(search.value.trim().toLowerCase())
      );
    });

    // #endregion

    return {
      key,
      titleId,
      menuId,
      leftPadding,
      isCollapsed,
      canCollapse,
      toggleCollapsed,
      listRef,
      menuKeys,
      isActive,
      popoverItems,
      filteredPopoverItems,
      search,
    };
  },
});
