import Vue, { VueConstructor, PluginObject } from 'vue';
import WbTag from './tag.vue';

const tag: VueConstructor<Vue> & PluginObject<void> = WbTag as any;
tag.install = function (vue) {
  vue.component('WbTag', WbTag);
};

export { WbTag };
export default tag;
