import { PartialApiFactory } from '../partial-factory';
import { IApiFactory } from '../../IApiFactory';

export const createReportingApi: PartialApiFactory<IApiFactory, 'reporting'> = (
  api
) => {
  return {
    async createAsync(request) {
      const response = await api.requestAsync('/outbox/report', {
        method: 'GET',
        params: request as Indexable,
      });

      return response;
    },
    async createCustomAsync(id, request) {
      const query = request
        ? `?${new URLSearchParams(request as Indexable).toString()}`
        : '';

      const response = await api.requestAsync(`/reports/${id}${query}`, {
        method: 'GET',
      });

      return response;
    },
  };
};
