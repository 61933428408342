






import { defineComponent, PropType, toRefs } from '@nuxtjs/composition-api';
import { useConfirmModal } from '~/app/components/ui/modal';

export default defineComponent({
  name: 'DeleteConfirmButton',
  props: {
    title: {
      type: String as PropType<string>,
      required: false,
      default: 'Are you sure delete this?',
    },
    description: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    confirmText: {
      type: String as PropType<string>,
      required: false,
      default: 'Yes',
    },
    cancelText: {
      type: String as PropType<string>,
      required: false,
      default: 'No',
    },
    placement: {
      type: String as PropType<string>,
      required: false,
      default: 'top',
    },
  },
  emits: ['confirm', 'cancel'],
  setup(props, { emit }) {
    const { title, description, confirmText, cancelText } = toRefs(props);
    const confirm = useConfirmModal();
    const showConfirm = () => {
      confirm({
        title: title.value,
        content: description.value,
        okText: confirmText.value,
        cancelText: cancelText.value,
        icon: () => (
          <wb-icon
            name="question"
            type="fill"
            class="float-left mr-2 inline-block text-3xl text-red-500"
          />
        ),
        onOk: () => emit('confirm'),
        onCancel: () => emit('cancel'),
      });
    };

    return { showConfirm };
  },
});
