import { DictionaryItem } from './dictionary-item';

export class CaseTypeDictionaryItem extends DictionaryItem {
  public readonly critical: boolean;

  constructor(args?: CaseTypeDictionaryItem) {
    super(args);
    this.critical = args?.critical ?? false;
  }
}
