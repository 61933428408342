import {
  module as StoreModule,
  moduleName as StoreModuleName,
  Action,
} from './store';
import { RootState } from '~/store';
import { AuthEvent } from '~/app/core/auth/events';
import { AppModule } from '~/app/core/module-system/module';
import { registerModule } from '~/app/core/module-system/use-module';
import {
  moduleName as caseModuleName,
  Action as CaseAction,
} from '~mod:case-details/store';

export class BodyboxModule extends AppModule {
  public readonly name = BodyboxModule.name;
  private unsubscribeStoreActions?: Function;

  public activate() {
    super.activate();

    const { $auth, $log, store } = this.context;

    $auth.events.once(AuthEvent.Login, async () => {
      try {
        await store?.dispatch(`${StoreModuleName}/${Action.fetchBodyboxes}`);
      } catch (error) {
        $log.error(error as Error, undefined, { ui: false });
      }
    });

    this.unsubscribeStoreActions = store?.subscribeAction({
      after: (action, _state: RootState) => {
        const caseCheckInAction = `${caseModuleName}/${CaseAction.checkIn}`;
        const caseCheckoutAction = `${caseModuleName}/${CaseAction.checkout}`;
        const caseDeleteAction = `${caseModuleName}/${CaseAction.delete}`;
        const refreshActions = [
          caseCheckInAction,
          caseCheckoutAction,
          caseDeleteAction,
        ];

        if (refreshActions.includes(action.type)) {
          // this.store?.dispatch(
          //   `${StoreModuleName}/${Action.fetchCaseSummaries}`
          // );
        }
      },
    });
  }

  public deactivate() {
    super.deactivate();
    this.unsubscribeStoreActions?.();
  }
}

export const ModuleName = BodyboxModule.name;
export { StoreModuleName };

export const registerBodyboxModule = () =>
  registerModule(BodyboxModule, {
    route: 'bodybox',
    store: {
      moduleName: StoreModuleName,
      module: StoreModule,
    },
  });
