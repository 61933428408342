export class Physician {
  public readonly name!: string;
  public readonly typeId!: string;
  public readonly nationalProviderIdentifier?: string;
  public readonly phoneNumber?: string;
  public readonly emailAddress?: string;

  constructor(args?: Physician) {
    if (!args) return;
    this.name = args?.name;
    this.typeId = args?.typeId;
    this.nationalProviderIdentifier = args?.nationalProviderIdentifier;
    this.phoneNumber = args?.phoneNumber;
    this.emailAddress = args?.emailAddress;
  }
}

export class DeathCertificate {
  public readonly causeOfDeathId?: string;
  public readonly causeOfDeathOther?: string;
  public readonly signingPhysician?: Physician;

  constructor(args?: DeathCertificate) {
    if (!args) return;
    this.causeOfDeathId = args?.causeOfDeathId;
    this.causeOfDeathOther = args?.causeOfDeathOther;
    this.signingPhysician = args?.signingPhysician;
  }
}
