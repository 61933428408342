

























import Vue from 'vue';
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@nuxtjs/composition-api';
import { onKeyStroke, templateRef } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import tippy, { roundArrow } from 'tippy.js';
import type { Instance, Placement } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

import ToolbarItem from './toolbar-item.vue';
import ToolbarButton from './toolbar-button.vue';
import { applyEnterTransition, applyLeaveTransition } from './drawer-animation';
import { genId } from '~/app/utils/id';

export default defineComponent({
  name: 'ToolbarDrawer',
  components: {
    ToolbarButton,
    ToolbarItem,
  },
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    placement: {
      type: String as PropType<Placement>,
      required: false,
      default: 'bottom',
    },
    trigger: {
      type: Function as PropType<undefined | (() => Vue | HTMLElement)>,
      required: false,
      default: () => undefined,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const isOpen = ref(false);

    const triggerItem = ref<Vue>();
    const drawer = templateRef<HTMLDivElement>('drawer');
    const instance = ref<Instance>();

    const menuId = `toolbar-drawer-menu-${genId()}`;

    const focusTrap = useFocusTrap(drawer, { immediate: false });

    onMounted(async () => {
      // await a render tick so that we can get a reference from the parent component after its rendered
      if (props.trigger && !props.trigger()) {
        await nextTick();
      }

      const trigger = props.trigger?.() || triggerItem.value;

      if (!trigger) {
        throw new Error('Unable to resolve trigger element');
      }

      if (!drawer.value) {
        throw new Error('Unable to resolve drawer element');
      }

      drawer.value.classList.remove('hidden');

      let awaitingHideTransition = false;

      const el = trigger instanceof HTMLElement ? trigger : trigger.$el;

      instance.value = tippy(el, {
        content: drawer.value,
        trigger: 'click',
        animation: false,
        placement: props.placement,
        offset: [0, 8],
        arrow: roundArrow,
        interactive: true,
        theme: 'drawer',
        onShow({ popper }) {
          const box = popper.firstElementChild as HTMLElement;

          box.style.width = props.width || box.style.width;
          box.style.maxWidth = props.width || box.style.maxWidth;

          applyEnterTransition(drawer.value, () => {
            // Required to invoke the "onShown" hook, needs `animation: true`
            box?.dispatchEvent(new TransitionEvent('transitionend'));
          });

          isOpen.value = true;
        },
        // onShown() {
        //   if (!focusTrap.hasFocus.value) {
        //     // TODO: Figure out why focus trap does not deactivate on close. then re-enable
        //     // nextTick(focusTrap.activate);
        //   }

        //   if (focusTrap.isPaused.value) {
        //     nextTick(focusTrap.unpause);
        //   }
        // },
        onHide(instance) {
          if (!awaitingHideTransition) {
            awaitingHideTransition = true;

            applyLeaveTransition(drawer.value, () => {
              instance.hide();
              awaitingHideTransition = false;
            });

            return false;
          }

          isOpen.value = false;
          nextTick(focusTrap.pause);
        },
      });
    });

    onKeyStroke('Escape', () => instance.value?.hide());
    onUnmounted(() => instance.value?.destroy());

    return { menuId, triggerItem, isOpen };
  },
});
