import type { ActionTree } from 'vuex';
import type { IBodyboxState } from './state';
import { Mutation } from './mutations';
import { RootState } from '~/store';
import { Bodybox } from '~/app/models';

export enum Action {
  fetchCaseSummaries = 'bodybox.fetchCaseSummaries',
  fetchBodyboxes = 'bodybox.fetchBodyboxes',
  fetchCapacities = 'bodybox.fetchCapacities',
}

export const actions: ActionTree<IBodyboxState, RootState> = {
  async [Action.fetchCaseSummaries](_context, bodyboxId: string) {
    if (!bodyboxId) throw new Error('A bodybox id is required.');

    const summaries = await this.$api.bodyboxes.getCaseSummariesAsync(
      bodyboxId
    );

    return summaries;
  },
  async [Action.fetchBodyboxes](
    { state, commit },
    options?: { ignoreCache?: boolean }
  ) {
    let boxes = state.bodyboxes;

    if (options?.ignoreCache || !boxes) {
      boxes = (await this.$api.bodyboxes.getAsync()) as Bodybox[];
      commit(Mutation.setBodyboxes, boxes);
    }

    return boxes;
  },
  async [Action.fetchCapacities]({ commit }) {
    const boxes = await this.$api.bodyboxes.getCapacitiesAsync();

    commit(Mutation.setBodyboxes, boxes);
    return boxes;
  },
};
