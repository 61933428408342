










import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { styles } from './styles';
import type { AlertType } from './alert-type';

const icons = {
  default: 'info',
  info: 'info',
  success: 'check-circle',
  warning: 'warning',
  danger: 'x-circle',
  multi: 'warning-diamond',
} as const;

export default defineComponent({
  name: 'AlertIcon',
  props: {
    type: {
      type: String as PropType<AlertType>,
      required: false,
      default: 'info',
    },
    icon: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
    ping: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  setup() {
    return { icons, styles };
  },
});
