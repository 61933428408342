








































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import type { RawLocation } from 'vue-router';
import { defu } from 'defu';
import { buttonConfig, paginatorConfig, PaginatorButtonConfig } from './config';

export default defineComponent({
  name: 'Paginator',
  // inheritAttrs: false,
  props: {
    value: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 7,
      validator: (value: any) =>
        typeof value === 'number' && value >= 5 && value < Number.MAX_VALUE,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: () => paginatorConfig.default.size,
      validator: (value: string) =>
        Object.keys(buttonConfig.size).includes(value),
    },
    to: {
      type: Function as PropType<(page: number) => RawLocation>,
      default: null,
    },
    activeButton: {
      type: Object as PropType<PaginatorButtonConfig>,
      default: () => paginatorConfig.default.activeButton,
    },
    inactiveButton: {
      type: Object as PropType<PaginatorButtonConfig>,
      default: () => paginatorConfig.default.inactiveButton,
    },
    showFirst: {
      type: Boolean,
      default: true,
    },
    showLast: {
      type: Boolean,
      default: true,
    },
    firstButton: {
      type: Object as PropType<PaginatorButtonConfig>,
      default: () => paginatorConfig.default.firstButton,
    },
    lastButton: {
      type: Object as PropType<PaginatorButtonConfig>,
      default: () => paginatorConfig.default.lastButton,
    },
    prevButton: {
      type: Object as PropType<PaginatorButtonConfig>,
      default: () => paginatorConfig.default.prevButton,
    },
    nextButton: {
      type: Object as PropType<PaginatorButtonConfig>,
      default: () => paginatorConfig.default.nextButton,
    },
    divider: {
      type: String,
      default: '…',
    },
    config: {
      type: Object as PropType<
        Partial<typeof paginatorConfig>
        // Partial<typeof paginatorConfig> & { strategy?: Strategy }
      >,
      default: () => ({}),
    },
  },
  emits: {
    input: (value: number) => typeof value === 'number',
  },
  setup(props, { emit }) {
    const ui = computed<typeof paginatorConfig>(() =>
      defu({}, props.config, paginatorConfig)
    );

    function getButtonClass(color: keyof typeof buttonConfig.color) {
      const size = props.size as keyof typeof buttonConfig.size;
      const classes = [
        ui.value.base,
        ui.value.rounded,
        buttonConfig.base,
        buttonConfig.font,
        buttonConfig.inline,
        buttonConfig.rounded,
        buttonConfig.size[size],
        buttonConfig.gap[size],
        buttonConfig.padding[size],
        buttonConfig.variant.solid,
        buttonConfig.color[color].solid,
      ];
      return classes;
    }

    const firstButtonClasses = computed(() => {
      const config: PaginatorButtonConfig = {
        ...ui.value.default.firstButton,
        ...props.firstButton,
      };

      return getButtonClass(config.color);
    });

    const lastButtonClasses = computed(() => {
      const config: PaginatorButtonConfig = {
        ...ui.value.default.lastButton,
        ...props.lastButton,
      };

      return getButtonClass(config.color);
    });

    const prevButtonClasses = computed(() => {
      const config: PaginatorButtonConfig = {
        ...ui.value.default.prevButton,
        ...props.prevButton,
      };

      return getButtonClass(config.color);
    });

    const nextButtonClasses = computed(() => {
      const config: PaginatorButtonConfig = {
        ...ui.value.default.nextButton,
        ...props.nextButton,
      };

      return getButtonClass(config.color);
    });

    const activeButtonClasses = computed(() => {
      const config: PaginatorButtonConfig = {
        ...ui.value.default.activeButton,
        ...props.activeButton,
      };

      return getButtonClass(config.color);
    });

    const inactiveButtonClasses = computed(() => {
      const config: PaginatorButtonConfig = {
        ...ui.value.default.inactiveButton,
        ...props.inactiveButton,
      };

      return getButtonClass(config.color);
    });

    const currentPage = computed<number>({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      },
    });

    const pages = computed(() =>
      Array.from(
        { length: Math.ceil(props.total / props.pageCount) },
        (_, i) => i + 1
      )
    );

    const displayedPages = computed(() => {
      const totalPages = pages.value.length;
      const current = currentPage.value;
      const maxDisplayedPages = Math.max(props.max, 5);
      const r = Math.floor((Math.min(maxDisplayedPages, totalPages) - 5) / 2);
      const r1 = current - r;
      const r2 = current + r;
      const beforeWrapped = r1 - 1 > 1;
      const afterWrapped = r2 + 1 < totalPages;
      const items: Array<number | string> = [];
      if (totalPages <= maxDisplayedPages) {
        for (let i = 1; i <= totalPages; i++) {
          items.push(i);
        }
        return items;
      }
      items.push(1);
      if (beforeWrapped) items.push(props.divider);
      if (!afterWrapped) {
        const addedItems = current + r + 2 - totalPages;
        for (let i = current - r - addedItems; i <= current - r - 1; i++) {
          items.push(i);
        }
      }
      for (let i = Math.max(2, r1); i <= Math.min(totalPages, r2); i++) {
        items.push(i);
      }
      if (!beforeWrapped) {
        const addedItems = 1 - (current - r - 2);
        for (let i = current + r + 1; i <= current + r + addedItems; i++) {
          items.push(i);
        }
      }
      if (afterWrapped) items.push(props.divider);
      if (r2 < totalPages) {
        items.push(totalPages);
      }
      // Replace divider by number on start edge case [1, '…', 3, ...]
      if (items.length >= 3 && items[1] === props.divider && items[2] === 3) {
        items[1] = 2;
      }
      // Replace divider by number on end edge case [..., 48, '…', 50]
      if (
        items.length >= 3 &&
        items[items.length - 2] === props.divider &&
        items[items.length - 1] === items.length
      ) {
        items[items.length - 2] = items.length - 1;
      }
      return items;
    });

    const canGoFirstOrPrev = computed(() => currentPage.value > 1);
    const canGoLastOrNext = computed(
      () => currentPage.value < pages.value.length
    );

    function onClickFirst() {
      if (!canGoFirstOrPrev.value) return;
      currentPage.value = 1;
    }

    function onClickLast() {
      if (!canGoLastOrNext.value) return;
      currentPage.value = pages.value.length;
    }

    function onClickPage(page: number | string) {
      if (typeof page === 'string') return;
      currentPage.value = page;
    }

    function onClickPrev() {
      if (!canGoFirstOrPrev.value) return;
      currentPage.value--;
    }

    function onClickNext() {
      if (!canGoLastOrNext.value) return;
      currentPage.value++;
    }

    return {
      ui,
      firstButtonClasses,
      lastButtonClasses,
      prevButtonClasses,
      nextButtonClasses,
      activeButtonClasses,
      inactiveButtonClasses,
      currentPage,
      pages,
      displayedPages,
      canGoLastOrNext,
      canGoFirstOrPrev,
      onClickPrev,
      onClickNext,
      onClickPage,
      onClickFirst,
      onClickLast,
    };
  },
});
