






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'FolderTab',
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
